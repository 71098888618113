export class CartModel {

  CartId: number;
  ApprovalStatus: string;
  TotalItemPrice: number;
  ModifiedDate: Date;
  IsDeleted: boolean;
  TotalQTY: number;
  DeliveryType: string;
  PaymentType: string;
  TimeSpan: string;
  OrderNo: string;
  CreatedDate: Date;
  PaymentStatus: number;
  ModifiedBy: number;
  UserId: number;
  DeliveryCharges: number;
  Address: string;
  CartDetailsId: number;
  ProductName: string;
  ProductStatus: string;
  ProductPrice: number;
  TotalProductPrice: number;
  AddedInCartDate: Date;
  ItemId: number;
  QTY: number;
  CartDetailSize: string;
  //Image: number;
  ProductPackId: number;
  Grade: string;
  BatchId: number;
  Size: string;
  SizeParentId: number;
  SizeDetailsId: number;
  Image: any;

  PaymentGatwayId: number = 0;
}
