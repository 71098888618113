import { Component, OnInit, ViewChild, DoCheck, NgZone, ElementRef } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Cart } from '../Models/cart';
import { CartModel } from '../Models/cart-model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { ModalDirective } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomershippingInfoModel } from '../Models/customershipping-info-model';
import { ProductInfoModal } from '../Models/product-info-modal';
import { WindowRefService } from 'src/app/window-ref.service';
import { MapsAPILoader } from '@agm/core';

import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';
//import { Razorpay } from 'razorpay';
import PlaceResult = google.maps.places.PlaceResult;
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css']
})
export class CheckOutComponent implements OnInit {
  @ViewChild('MyOrderStatus', { static: false }) MyOrderStatus: ModalDirective;
  @ViewChild('PaymentForm', { static: false }) PaymentForm: ModalDirective; showFifthList: boolean;
  @ViewChild('ConfirmationPage', { static: false }) ConfirmationPage: ModalDirective;
  @ViewChild('Loader', { static: false }) Loader: ModalDirective;
  @ViewChild('searchel', { static: false }) public searchElement: ElementRef;
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  @ViewChild('AddressForm', { static: false }) AddressForm: ModalDirective;
  @ViewChild('AddressDeleteModel', { static: false }) AddressDeleteModel: ModalDirective;
  @ViewChild('ConfirmationPageOfAddress', { static: false }) ConfirmationPageOfAddress: ModalDirective;
  @ViewChild('ResetFinalPassword', { static: false }) ResetFinalPassword: ModalDirective;
  @ViewChild('ShippingAddress', { static: false }) ShippingAddress: ModalDirective;
  CustomerInfo: any; CustomerAddres: any; OrderNo: string; Time: Date; showForthList: boolean; NoSelectedAddresError: string;
  cartItems: any[]; TotalItem: number; CustomerState: any; Address: string; PayableAmount: number;
  TotalAmount: number; cartItemsCal: any; CustomerPhoneNo: any;  unamePattern = "^[a-zA-Z\\s]*$";
  showSecondList = false; CustomerLastName: string; Customer_Pincode: any; selectedAddressId: number = 0;
  CustomerName: string; CustomerEmailId: any; CustomerlandMark: any; showThirdList: boolean;
  public AppComponents: AppComponent; CustomershippingCustomerId: number = 0; selectedCategory: number; CustomerAddres5: string;
  title = 'My first AGM project'; NoItemFoundError: string;
  //CustomerAddres: string = 'Grohitam Complex ,Building Office No.655,Sector 19 Navi Mumbai Vashi ';
  constructor(private apiIntergnService: ApiIntergnService, private formBuilder: FormBuilder, private router: Router,
    private appComp: AppComponent, private winRef: WindowRefService, private _ngZone: NgZone, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, public datepipe: DatePipe) {
   // Window.angularComponentRef = { runThisFunctionFromOutside: this.runThisFunctionFromOutside, zone: _ngZone };
    
  }
  CustomerCity: any; PaymentTypeName: string; PickUpPointName: string;
  CustomerSelfPickAddres: string; addForm: FormGroup;
  addFormNumber: FormGroup;
  ProductlistData: any; oTPsubmitted = false;
  CustomerHomePickAddres: any; StorageData: string;
  NumberPattern = "^[0-9]*$";
  public searchControl: FormControl;
  TheDayAfterTomarrow = new Date;

  Slots: any[] = [];
  SlotDate = new Date;
  SlotHour: number;
  TotalPayableAmount: number = 0; Mode: string; cartItemLength: number = 0;
  showFirstList: boolean = false;

  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
 
    

  //latitude: number;
  //longitude: number;
  //zoom: number;
  address: string;
  private geoCoder;

  CheckOutItem() {
    var id = Number(localStorage.getItem('CustId'));
    this.StorageData = localStorage.getItem('Cart');
    
    this.cartItems = JSON.parse(this.StorageData);
    this.cartItemLength = this.cartItems.length;
   
    if (this.cartItems) {
      this.cartItemsCal = this.cartItems;

      this.TotalAmount = 0;
      var t = 0;
      for (let i = 0; i < this.cartItemsCal.length; i++) {
        t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;
        this.TotalPayableAmount = this.TotalAmount;
      }
      this.TotalAmount = t;
      
      this.TotalItem = 0;
      var sum = 0;
      
      if (this.cartItemsCal) {
        for (let i = 0; i < this.cartItemsCal.length; i++) {
        }
      }
      sum = this.cartItemsCal.length;

      this.TotalItem = sum;
      console.log(this.TotalPayableAmount);
      this.TotalAmount;

    }

    this.LoadCustomerAddress();

  }
  

  DeliveryType: string = 'Home Delivery';
  modelChanged(Value, type: string) {
    
    //this.CustomerAddres = Value;

    if (type === 'HomePick') {
      this.CustomerAddres = Value;
      this.DeliveryType = 'Home Delivery';

    }
    else if (type == 'SelfPick') {
      this.CustomerAddres = Value;
      this.DeliveryType = 'Self PickUp';

    }
  }

  watchers:any;
  ngOnInit() {
    debugger;
    this.appComp.Height = 60;
    
  
    //if (this.apiIntergnService.subsRefreshCartVar == undefined) {
    //  this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {
    //    this.ngOnInit();
    //  });
    //}



    $('body').removeClass('open');
   
    this.addForm = this.formBuilder.group({
    
      AreaName: ['', Validators.required],
      
      Customershipping_State: [''],
      Customershipping_Pincode: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.NumberPattern)]],
      Customershipping_Name: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
      Customershipping_Address1: ['', Validators.required],
      Customershipping_Address2: ['', Validators.required],

      Customershipping_FSSAINo: [''],

    },
    );

    this.addFormNumber = this.formBuilder.group({
      Customer_Mobile:['']
    },
    );
    
    

    $('body').removeClass('open');
   // this.LoadCategory();
    
    //if (this.apiIntergnService.subsVar == undefined) {
    //  this.apiIntergnService.invokeCheckOutProductEvent.subscribe(() => {

    //    this.CheckOutItem();

    //  });
    //}
    this.NoItemFoundError = "";
    this.NoSelectedAddresError = "";
 //   this.CustomerAddress();
    this.CheckOutItem();
    window['angularComponentReference'] = { component: this, zone: this._ngZone, loadAngularFunction: (paymentid) => this.GetPaymentStatus(paymentid,""), };
    

    

    this.searchControl = new FormControl();

    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;

     // this.setCurrentLocation();



      let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {});
      
      let place: google.maps.places.PlaceResult = autocomplete.getPlace();

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.address = place.formatted_address;
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });


    this.TheDayAfterTomarrow.setDate(this.TheDayAfterTomarrow.getDate() + 2);
    this.DeliverySlots("Today");
    this.SelectSlotTime("0");
  }
    GotoCheckoutProcess()
    {
      
        this.router.navigateByUrl('/Payment');
        window.scrollTo(0, 0)
      
    }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

    gotoHomePage()
    {
        this.router.navigateByUrl('/Home');
        window.scrollTo(0, 0)
    }

  // Get Current Location Coordinates

  ChangeOrAddAddress() {
    debugger;
    this.ShippingAddress.show();
  }
  private setCurrentLocation() {
    debugger;

      this.latitude = 18.51957;
    this.longitude = 73.85535;
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);


    //if ('geolocation' in navigator) {
    //  navigator.geolocation.getCurrentPosition((position) => {
    //    this.latitude = position.coords.latitude;
    //    this.longitude = position.coords.longitude;
    //    this.zoom = 8;
    //    this.getAddress(this.latitude, this.longitude);
    //  });
    //}
  }


  getAddress(latitude, longitude) {
    debugger;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.addForm.controls.AreaName.setValue(this.address);
        } else {
          window.alert('No results found');
        }
      }
      else {
          debugger;
       // window.alert('Geocoder failed due to: ' + status);
      }

    });

  }

 

    public quantityChanged(index, type, item, objectName) {

      debugger;

        var prod = new ProductInfoModal();

      prod.CustomerID = Number(localStorage.getItem('CustId'));
      prod.UserId = Number(localStorage.getItem('CustId'));

      //if (prod.CustomerID == 0) {
      //  this.LoginModalOpen();
      //  return;
      //}

        if (type == 'Increament') {
            this.cartItems[index].qty += 1;
        }
        else {
            this.cartItems[index].qty -= 1;
            prod.isDecreaseQty = true;
        }

      if (item.imagePath == undefined) {
        item.imagePath = item.path
      }
        prod.productName = item.productName;
        prod.productPrice = item.productPrice;
        prod.batch_id = item.batch_id;
        prod.qty = this.cartItems[index].qty;
        prod.batchId = item.batch_id;
      prod.itemId = item.item_Id;
      prod.uomId = item.uomId;
      prod.unit = item.unit_Name;
      prod.imagePath = item.imagePath;
      prod.weight = item.weight;

        this.cartItems.forEach((element, index) => {
            if (element.item_Id == prod.itemId) {
                element.qty = prod.qty;
                if (prod.qty == 0)
                {
                  prod.isDecreaseQty = true;
                    const index = this.cartItems.findIndex(list => list.item_Id == prod.itemId);//Find the index of stored id
                    this.cartItems.splice(index, 1)
                }
            }

        });

        if (prod.CustomerID > 0) {


            var PreCartData = prod;
            PreCartData.qty = 1;
            this.apiIntergnService.AddPreSingleCart(PreCartData)
                .subscribe(Val => {
                    if (!Val.isError) {

                    }
                });
        }
        
        this.cartItemsCal = this.cartItems;
        //this.LocalStrogedata = this.cartItemsCal;
        this.TotalAmount = 0;
        var t = 0;
        for (let i = 0; i < this.cartItemsCal.length; i++) {
            t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

        }
        var sum = 0;
        this.TotalAmount = t;
        this.TotalItem = 0;
        t = 0;
        if (this.cartItemsCal) {
            for (let i = 0; i < this.cartItemsCal.length; i++) {
                t += this.cartItemsCal[i].qty;
                //t += length++;
            }
        }
        sum = this.cartItemsCal.length;
        this.TotalItem = sum;

        var obj = {
            Index: index, Type: type, Item: item, ObjectName: objectName
        };
       // this.homePageChanged = true;
        //this.apiIntergnService.QtyChangeEvent(obj);
        this.cartItemslength = this.cartItems.length;

    }


  markerDragEnd($event: any) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }




  OpenLocationMap() {
    this.ResetFinalPassword.show();
  }

  cartItemslength: number = 0;
  clearCartItem(item, index) {
    debugger;

    this.cartItems.splice(index, 1)

    localStorage.setItem('Cart', (JSON.stringify(this.cartItems)));
    this.cartItemslength = this.cartItems.length;
    this.TotalItem = this.cartItems.length;
    var t = 0;
    for (let i = 0; i < this.cartItemsCal.length; i++) {
      t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;
    }
    var sum = 0;
    this.TotalAmount = t;
    this.appComp.TotalItem = this.cartItemslength;
    //this.ngOnInit();
   
    var prod = new ProductInfoModal();
    if (item.imagePath == undefined) {
      item.imagePath = item.path
    }
    prod.itemId = item.item_Id;
    prod.productName = item.productName;
    prod.qty = item.qty;
    prod.isDecreaseQty = true
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.uomId = item.uomId;
    prod.imagePath = item.imagePath;
    prod.weight = item.weight;

    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));

    if (prod.UserId > 0) {
   
      var PreCartData = prod;
      console.log(PreCartData);
      this.apiIntergnService.DeleteCartItems(PreCartData)
        .subscribe(Val => {
       
          if (!Val.isError) {

          }
        });
    }

  };




  Pincode() {
    if (this.addForm.controls.Customershipping_Pincode.errors == null) {
      this.apiIntergnService.GetDataThroughPincode(this.addForm.controls.Customershipping_Pincode.value).subscribe(response => {
        if (response) {
          if (!response.isError) {
            this.addForm.controls.Customershipping_State.setValue(response.data.stateName);
          }
          else {
            this.addForm.controls.Customershipping_State.setValue("");
          }
        }
      });
    }
    else {
      this.addForm.controls.Customershipping_State.setValue("");
    }
    //if(this.addForm.value.Customershipping_Pincode);
  }
  AddressLength: number = 0;
  CustomerShipping_Address: any;
  CustomerShipping_Id: any;
  CateData: any;
  //public LoadCategory() {
  //  var ids = localStorage.getItem('CustId');
  //  this.apiIntergnService.getAllCustomerAddress(ids).subscribe((data) => {
  //    if (!data.isError) {
  //      this.CateData = data.data;
  //      this.CustomerShipping_Address = data[0].customershipping_Address1 + data[0].customershipping_Address2 + data[0].areaName;
  //      this.CustomerShipping_Id = data[0].customershipping_Id;
  //      this.AddressLength = this.CateData.length;
  //      console.log(this.CateData);
  //    }
  //  });
  //}
  public LoadCustomerAddress() {
    var ids = localStorage.getItem('CustId');
    this.apiIntergnService.getAllCustomerAddress(ids).subscribe((data) => {
      if (!data.isError) {
        debugger;
        this.CateData = data.data;
        this.CustomerShipping_Address = data.data[0].customershipping_Address1 + " " + data.data[0].customershipping_Address2 + " " + data.data[0].areaName;
        this.CustomerShipping_Id = data.data[0].customershipping_Id;
        this.AddressLength = this.CateData.length;
        localStorage.setItem('ShippingId', this.CustomerShipping_Id);
       
        console.log(this.CateData);
      }
    });
  }
  UpDown(listType) {
    
  
    switch (listType) {


      case 'DeliveryAddress':
        {
          this.showThirdList = false;
          

          if (this.CustomerPhoneNo != "") {
            if (this.showSecondList)
            {
              this.showSecondList = false;
            }
            else
            {
              this.showFirstList = false;
              this.showSecondList = true;
              window.scrollTo(0, 0)
            }
            break;
          }
          else
          {
            this.showSecondList = false;
            this.showFirstList = true;
          }
         
        }
      
    }

  }




  currentLat: number = 0;
  currentLong: number = 0;
  findMe() {
   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  map: any;
  marker: any;

  showPosition(position) {
   
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;



  }
  getTotalAmounts() {
  
    this.GetAddToCardDetails((data) => {
      this.cartItems = data;
      this.cartItemsCal = this.cartItems;

      this.TotalAmount = 0;
      var t = 0;
      for (let i = 0; i < this.cartItemsCal.length; i++) {
        t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

      }
      this.TotalAmount = t;
      this.TotalItem = 0;
      t = 0;
      if (this.cartItemsCal) {
        for (let i = 0; i < this.cartItemsCal.length; i++) {
          t += this.cartItemsCal[i].qty;
        }
      }
      this.TotalItem = t;
    });

    console.log(this.TotalAmount);
    return this.TotalAmount;

  }

  GetAddToCardDetails(callback) {
    
    if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

      var id = Number(localStorage.getItem('CustId'));
      this.apiIntergnService.AddCartDetails(id)
        .subscribe(data => {
          if (data) {
            if (callback) {

              callback(data.data);

            }


          }

          this.cartItems = data.data;


        }
        );
    }
    else {
      alert("You are logout please login again to continue.");
      this.router.navigateByUrl('/Home');
      window.scrollTo(0, 0)
    }
  }

  CustomerAddress() {
    debugger;
   
    var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
    this.CustomerInfo = JSON.parse(CustomerDetails);
    this.CustomerName = this.CustomerInfo.data.customer_Name;
    this.CustomerEmailId = this.CustomerInfo.data.customer_EmailID;
    //this.CustomerAddres = this.CustomerInfo.Data.Customer_EmailID;
    this.CustomerlandMark = this.CustomerInfo.data.customer_Landmark;
    //this.CustomerInfo.Data.Customer_Mobile = "";
    this.CustomerPhoneNo = this.CustomerInfo.data.customer_Mobile;
    
    if (this.CustomerPhoneNo != "")
    {
      this.showFirstList = false;
      this.showSecondList = true;
    }
    else
    {
      this.showFirstList = true;
      this.showSecondList = false;
    }

    //return this.Address;
  }
  CustomerId: number = 0;

  PhonNumberVerification()
  {
   
    if (this.CustomerPhoneNo == "")
    {
      alert("Please Enter Mobile Number");
    }
    else
    {
      var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
      
      //var CustomerDetails = (localStorage.setItem('CustomerAllDetails'));
      this.CustomerInfo = JSON.parse(CustomerDetails);
      this.CustomerId = this.CustomerInfo.data.customer_ID;
      this.addFormNumber.value.Customer_ID = this.CustomerId;
      this.addFormNumber.value.Customer_Mobile = this.CustomerPhoneNo;

     this.apiIntergnService.UpdateCustomerNumber(this.addFormNumber.value).subscribe(response => {
          if (response) {
            if (!response.isError) {
              var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
              this.CustomerInfo = JSON.parse(CustomerDetails);
              this.CustomerInfo.data.customer_Mobile = this.CustomerPhoneNo;
              localStorage.setItem('CustomerAllDetails', (JSON.stringify(this.CustomerInfo)));

              this.AddressForm.hide();
              this.ngOnInit();
              this.addForm.reset();
              this.oTPsubmitted = false;
              this.showFirstList = false;
              this.showSecondList = true;
            }
            else {
              var msg = "";
              response.Message.forEach(value =>
              {
                msg += value + "\n";
              });
              alert(msg.toUpperCase());
              this.oTPsubmitted = false;
            }
          }

          else
          {


          }

        });
      

    


    }
    
  }


  GetDataThroughPincode(Customer_Pincode: number) {

    this.apiIntergnService.GetDataThroughPincode(Customer_Pincode).subscribe(data => {
      console.log(data);
      var PinCodeInfo = JSON.parse(JSON.stringify(data));
      this.CustomerCity = PinCodeInfo.data.taluka
      this.CustomerState = PinCodeInfo.data.stateName


    })
  }


  OpenSecondDiv() {
    
    if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
      this.NoItemFoundError = "Your cart is empty";
      return
    }
      if (this.selectedAddressId != 0) {
        if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

          this.showThirdList = true;
          this.showSecondList = false;
          this.showForthList = false;
          this.showFifthList = false;
          window.scrollTo(0, 0)
        }
        else {
          alert("You are logout please login again to continue.");
          this.router.navigateByUrl('/Home');
          window.scrollTo(0, 0)
        }
      }

      else {
        this.NoSelectedAddresError = "please add address or select the address."
        //alert("please add address or select the address.");
      }
   




  }
  rzp1;


    GetPaymentStatus(PaymentID, PaymentGateway_Id) {
        if (PaymentGateway_Id == "cancel") {
            var rsobj = {
                PaymentId: PaymentID,
                PaymentGatwayId: this.PaymentGatewayId,
            }
            //console.log(rsobj);
            this.Loader.config.backdrop = "static"
            this.Loader.config.keyboard = false;
            this.Loader.show();

            this.apiIntergnService.CheckPaymentStatus(rsobj).subscribe(data1 => {
                console.log(data1);
                if (data1.data.paymentGateway_PaymentStatus == "authorized" || data1.data.paymentGateway_PaymentStatus == "captured") {
                    this.ForWordToCheckOut();
                }
                else if (data1.data.paymentGateway_PaymentStatus == "created") {

                    this.ForWordToCheckOut('Pending');
                }
                else {
                    alert("Payment Failed Please Try Again.")
                }


            });
        }
    }

  PaymentGatewayId: number = 0;

  isPayOnlineUp() {
    
    //alert("Soon we will available for online payment option")
    if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
      this.NoItemFoundError = "Your cart is empty";
      return
      //alert("You have no selected  item ")
    }
    
    this.PayOnlineM = "Online Payment";
    this.PaymentTypeName = this.PayOnlineM;
    var Id = localStorage.getItem('CustId');
    this.TotalAmount
    var obj =
    {
      CutomerId: Id,
      Amount: this.TotalAmount
    }
    
    this.apiIntergnService.CreateOrder(obj)
      .subscribe(data => {
      
        var LCustData = localStorage.getItem('CustomerAllDetails');
        //console.log(this.StorageData);
        var CustData = JSON.parse(LCustData);
        
        if (data != null && !data.isError) {
          //this.GatwayBtn = data.Data.PaymentForm;
          this.PaymentGatewayId = data.data.paymentGateway_Id;
          var options = {
            description: 'Pay your amount',
            order_id: data.data.paymentGateway_OrderId,
            //image: 'https://i.imgur.com/3g7nmJC.png',
            currency: 'INR',
            modal: {
                escape: false,
                ondismiss: function () {
                    //function method() {
                    // @ts-ignore
                    window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction("cancel"); });
                    //}
                }
                    //method();
            }, 
            receipt: data.data.paymentGateway_ReffNo,
            key: 'rzp_test_Q8cEU4dvWnK3VI',
            amount: data.data.paymentGateway_PaisaAmount,
            name: 'Grocery World Payment',
            prefill: {
              email: CustData.data.customer_EmailID,
              contact: CustData.data.customer_Mobile,
              //name: this.appComp.CustomerName
            },

            
            handler: function (response) {
              //alert(response.razorpay_payment_id);
              if (response != undefined && response != null && response.razorpay_payment_id != undefined && response.razorpay_payment_id != null) {
                this.id = response.razorpay_payment_id;
                this.fullName = response.razorpay_payment_id;
                //this.GetPaymentStatus(response.razorpay_payment_id, data.Data.PaymentGateway_Id);
                //alert(this.id);

                function method() {
                  // @ts-ignore
                  window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(response.razorpay_payment_id); });
                }
                method();
              }
              },
             
            customer: {
              //name: this.appComp.CustomerName,
              email: CustData.data.customer_EmailID,
              contact: CustData.data.customer_Mobile,
              //"billing_address": {
              //  "line1": "318 C-Wing, Suyog Co. Housing Society Ltd., T.P.S Road, Vazira, Borivali",
              //  "city": "West Mumbai",
              //  "state": "Maharashtra",
              //  "zipcode": "400092",
              //  "country": "India"
              //}
            },
            theme: { color: '#F37254' }
          }
         
          let rzp = new this.winRef.nativeWindow.Razorpay(options);
          rzp.open();

          
        }
      });
  }

  isAllDeatilsSelected(addresdetails) {
    debugger;
    this.CustomerShipping_Address = addresdetails.customershipping_Address1;
    this.CustomerShipping_Id = addresdetails.customershipping_Id;
    this.CustomerAddres5 = addresdetails.customershipping_Address1 + " " + addresdetails.customershipping_Address2;
    this.NoSelectedAddresError = "";
    localStorage.setItem('ShippingId', this.CustomerShipping_Id);
    
  }
  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }


  data: any;
  ForWordToCheckOut(PaymentStatus = 'Approved') {
   
    if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
      this.NoItemFoundError = "Your cart is empty";
      return
      //alert("You have no selected  item ")
    }
    if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {
      this.showThirdList = true;
      this.showSecondList = false;
      this.showForthList = false;
      this.showFifthList = true;

      this.ConfirmationPage.hide();
      var CartBuyData = this.cartItems;
      CartBuyData.forEach((CardDetailsInfo, index) => {

       
        let CartInfoModel: CartModel = new CartModel();
        CardDetailsInfo.paymentGatwayId = this.PaymentGatewayId;
        CardDetailsInfo.addedInCartDate = new Date;
        CardDetailsInfo.cartId = CardDetailsInfo.CartId;
        CardDetailsInfo.modifiedDate = new Date;//CardDetailsInfo.ModifiedDate;
        CardDetailsInfo.image = null;
        CardDetailsInfo.IsDeleted = CardDetailsInfo.IsDeleted;
        CardDetailsInfo.TotalQTY = this.TotalItem;//CardDetailsInfo.TotalQTY;
        CardDetailsInfo.QTY = CardDetailsInfo.QTY;
        CardDetailsInfo.DeliveryType = this.DeliveryType;
        CardDetailsInfo.PaymentType = this.PaymentTypeName;
        CardDetailsInfo.TimeSpan = CardDetailsInfo.TimeSpan;
        CardDetailsInfo.OrderNo = CardDetailsInfo.OrderNo;

        CardDetailsInfo.CreatedDate = new Date;
        CardDetailsInfo.PaymentStatus = PaymentStatus;  //CardDetailsInfo.PaymentStatus;
        CardDetailsInfo.ModifiedBy = CardDetailsInfo.ModifiedBy;
        CardDetailsInfo.DeliveryCharges = 0;//this.ChargeAmount;
        var id = localStorage.getItem('CustId');

        CardDetailsInfo.UserId = Number(id); //CardDetailsInfo.UserId;
        CardDetailsInfo.Address = this.CustomerAddres5;
        CardDetailsInfo.CartDetailsId = CardDetailsInfo.CartDetailsId;
        CardDetailsInfo.ProductName = CardDetailsInfo.ProductName;
        CardDetailsInfo.ProductStatus = 'Active';
        CardDetailsInfo.ApprovalStatus = PaymentStatus;
        CardDetailsInfo.ProductPrice = CardDetailsInfo.ProductPrice;
        CardDetailsInfo.TotalProductPrice = this.TotalAmount;//this.TotalPayableAmount;
        CardDetailsInfo.TotalItemPrice = this.TotalAmount;//this.TotalPayableAmount;
        //CardDetailsInfo.ProductPrice = this.TotalPayableAmount;
        //CardDetailsInfo.ProductPrice = this.TotalPayableAmount;
        CardDetailsInfo.AddedInCartDate = new Date;
        CardDetailsInfo.ItemId = CardDetailsInfo.ItemId;
        CardDetailsInfo.ShippingAddressId = this.selectedAddressId;
        CardDetailsInfo.QTY = CardDetailsInfo.QTY;
        CardDetailsInfo.CartDetailSize = CardDetailsInfo.CartDetailSize;
        CardDetailsInfo.ProductPackId = CardDetailsInfo.ProductPackId;
        CardDetailsInfo.Grade = CardDetailsInfo.Grade;
        CardDetailsInfo.BatchId = CardDetailsInfo.Batch_id;
        CardDetailsInfo.Size = CardDetailsInfo.Size;
        CardDetailsInfo.SizeParentId = CardDetailsInfo.SizeParentId;
        CardDetailsInfo.SizeDetailsId = CardDetailsInfo.SizeDetailsId;
      });
      
      this.data = JSON.stringify(CartBuyData);
      console.log(this.data);
      this.apiIntergnService.AddCartBuyDetails(CartBuyData)
        .subscribe(data => {
          if (data) {

            this.showThirdList = false;
            this.showForthList = true;

            this.PickUpPointName = data.data[0].DeliveryType;
            this.Address = data.data[0].Address;
            this.Time = new Date();
            this.OrderNo = data.data[0].OrderNo;
            this.PayableAmount = data.data[0].TotalItemPrice + data.data[0].DeliveryCharges;
            this.ProductDetailsSummary(data.data[0].cartId)
            //this.MyOrderStatus.show();
            this.cartItems = null;
            this.TotalItem = null;
            this.TotalAmount = null;
            //this.ngOnInit();
            localStorage.removeItem('Cart');
         
            this.appComp.ClearTotalAmountAndCount();
            this.appComp.ngOnInit();
            //this.router.navigate(['./Home']);
            //this.appComp.ngOnInit();
            //window.scrollTo(0, 0)
            this.PaymentGatewayId = 0;

          }
          else {

            alert("Something went wrong please try again!!")
            this.showThirdList = true;
            this.showForthList = false;
            window.scrollTo(0, 0)
          }

        });
      if (this.Loader.isShown) {
        this.Loader.hide();
      }
    }
    else {
      alert("You are logout please login again to continue.");
      this.router.navigateByUrl('/Home');

    }

  }


  BackToHome() {
    this.router.navigate(['./Home']);
    window.scrollTo(0, 0)
    this.appComp.ngOnInit();

  }
  public ProductDetailsSummary(id: number) {


    this.apiIntergnService.GetMyProductDetails(id)
      .subscribe(data => {
        if (data) {

          this.ProductlistData = data.data.orderDetails;

          //for (var i = 0; i < this.ProductlistData.length; i++)
          //{
          //  if (this.ProductlistData[i].Image) {
          //    this.ProductlistData[i].Image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          //      + this.ProductlistData[i].Image);
          //  }
          //  else {
          //    this.ProductlistData[i].Image = "../../assets/images/img7_4.jpg";
          //  }
          //}

        }
      }
      );

  }


  getSumOfData(): number {
 
    this.cartItemsCal = this.cartItems;
    let TotalAmount = 0;
    for (let i = 0; i < this.cartItemsCal.length; i++) {
      TotalAmount += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

    }
    // localStorage.setItem('CustDetails', TotalAmount.toString());
    //this.TotalCartValue = TotalAmount;
    this.TotalItem = 0;
    var t;
    t = 0;
    if (this.cartItemsCal) {
      for (let i = 0; i < this.cartItemsCal.length; i++) {
        t += this.cartItemsCal[i].qty;
      }
    }

    this.TotalItem = t;
    this.TotalAmount = TotalAmount;
    return TotalAmount;

  }

  public PaymentPageModal() {

    //this.getTotalAmounts();
    this.getSumOfData();
    this.PaymentForm.show();

  }
  //PayOnlineM: any;
  PayOnlineM: any = "Cash On Delivery";
    public CardOnDelivery(paymentType) {
        debugger;
        this.PayOnlineM = paymentType;
    if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
      this.NoItemFoundError = "Your cart is empty";
      return;
      //alert("You have no selected  item ")
    }
    if (this.PayOnlineM == "Cash On Delivery") {
      //this.PaymentForm.hide();
      if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

        this.ConfirmationPage.show();
        this.PaymentTypeName = this.PayOnlineM;
      }
      else {
        alert("You are logout please login again to continue.");
        this.router.navigateByUrl('/Home');
      }
    }
    //else {
    //  this.isPayOnlineUp();
    //}
  }

  //addresswork


  BackToAccount() {
    this.AddressForm.show();
    this.ResetFinalPassword.hide();
  }

  BackToAccountpage() {
    this.ResetFinalPassword.hide();
  }
  SaveGoogleMap() {
    this.ResetFinalPassword.hide();
  }


  get o() { return this.addForm.controls; }
  SaveAddtress() {

    debugger;
    this.oTPsubmitted = true;

    if (!this.addForm.valid) {

    }

    else {

      var ids = localStorage.getItem('CustId');
      this.addForm.value.Mode = this.Mode;
      this.addForm.value.Customershipping_Id = this.CustomershippingCustomerId
      this.addForm.value.Customershipping_CustomerId = ids;
      this.addForm.value.Customershipping_Createdby = ids;
      this.addForm.value.Customershipping_Status = 'Active';
      this.addForm.value.AreaName = this.addForm.value.AreaName;
      this.addForm.value.CityName = this.addForm.value.CityName;

      this.apiIntergnService.ChangeAddress(this.addForm.value).subscribe(response => {
        if (response) {
          if (!response.isError) {
            this.AddressForm.hide();
           // this.ResetFinalPassword.show();
            this.ngOnInit();
            // alert("Address Changed Successfully!!")
            this.addForm.reset();
            this.oTPsubmitted = false;
          }
          else {
            var msg = "";
            response.Message.forEach(value => {
              msg += value + "\n";
            });
            alert(msg.toUpperCase());
            this.oTPsubmitted = false;

          }




          //this.ResetFinalPassword.hide();
        }

        else {
          //alert("Something went wrong please try again");

        }

      });
    }

  }
  OpenAddressForm() {
    this.addForm.reset();
    this.ShippingAddress.hide();
    this.AddressForm.show();
    this.oTPsubmitted = false;
    this.Mode = "Insert";
  }

  CloseShippingAddressForm()
  {
    this.ShippingAddress.hide();
  }
  EditAddressForm(Item) {

  
    this.Mode = "Update";
    this.oTPsubmitted = false;
    this.CustomershippingCustomerId = Item.customershipping_Id;
    let custShipplinginfo: CustomershippingInfoModel = new CustomershippingInfoModel();
    custShipplinginfo.Customershipping_Id = Item.customershipping_Id;
    custShipplinginfo.Customershipping_CustomerId = Item.customershipping_CustomerId;
    var data = {
      "CustomerId": custShipplinginfo.Customershipping_CustomerId,
        "ShippingId": custShipplinginfo.Customershipping_Id
    }
    this.apiIntergnService.getCustomerShippingAddressById(data).subscribe(data => {
      if (data) {
        this.addForm.patchValue(data.data);
        this.AddressForm.show();
      }
      else {

      }
    })

  }


  DeleteAddress() {
    this.ConfirmationPageOfAddress.hide();
    var ids = localStorage.getItem('CustId');
    this.addForm.value.Mode = this.Mode;
    this.addForm.value.Customershipping_Id = this.CustomershippingCustomerId
    this.addForm.value.Customershipping_CustomerId = ids;


    this.apiIntergnService.ChangeAddress(this.addForm.value).subscribe(data => {
      if (data) {

        if (!data.isError) {
          //this.AddressDeleteModel.show();

          //alert("Address deleted successfully");
          this.ngOnInit();
        }
        else {
          var msg = "";
          data.Message.forEach(value => {
            msg += value + "\n";
          });
          alert(msg.toUpperCase());


        }




      }
    });
  }


  DeleteConfirmationForm(Item) {
    this.ConfirmationPageOfAddress.show();
    this.Mode = "Delete";
    this.CustomershippingCustomerId = Item.customershipping_Id;
  }

  GotoMap() {
  
    this.ResetFinalPassword.show();
  }

    DeliveryChargeplusAmount: number = 0;
    DeliveryCharge: number = 0;
  public keyDownFunction(event) {

    if (event.keyCode == 40) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input,select"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 38) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input,select"))
      const index =
        (inputs.indexOf(document.activeElement) - 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 13) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input,select"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }

  }

  SlotTime: any;
  public SelectSlotTime(Index) {
    debugger
    var i = "slot" + Index;
    document.getElementById(i).style.backgroundColor = "#bd9c6d";
    document.getElementById(i).style.color = "white";


    for (var k = 0; k < this.Slots.length; k++) {
     
        var y = "slot" + k;
        
        if (y == i) {
          this.SlotTime = this.Slots[k];
          localStorage.setItem("TimeSpan", this.SlotTime);
        }
        else {
          document.getElementById(y).style.backgroundColor = "white";
          document.getElementById(y).style.color = "#444";
        }
      
    }


  }

  public DeliverySlots(Day) {
    debugger;
    if (Day == "Today") {
      this.SlotDate = new Date();
      localStorage.setItem('TimeSpanDate', this.datepipe.transform(this.SlotDate, 'yyyy-MM-dd'));
      document.getElementById("Today").style.backgroundColor = "#bd9c6d";
      document.getElementById("Today").style.color = "white";
      document.getElementById("Tomorrow").style.backgroundColor = "white";
      document.getElementById("Tomorrow").style.color = "#444";
      document.getElementById("TheDayAfterTomorrow").style.backgroundColor = "white";
      document.getElementById("TheDayAfterTomorrow").style.color = "#444";
    
    }
    else if (Day == "Tomorrow") {
      this.SlotDate = new Date();
      this.SlotDate.setDate(this.SlotDate.getDate() + 1);
      localStorage.setItem('TimeSpanDate', this.datepipe.transform(this.SlotDate, 'yyyy-MM-dd'));
      document.getElementById("Tomorrow").style.backgroundColor = "#bd9c6d";
      document.getElementById("Tomorrow").style.color = "white";
      document.getElementById("Today").style.backgroundColor = "white";
      document.getElementById("Today").style.color = "#444";
      document.getElementById("TheDayAfterTomorrow").style.backgroundColor = "white";
      document.getElementById("TheDayAfterTomorrow").style.color = "#444";
    
    }
    else if (Day == "TheDayAfterTomorrow") {
      this.SlotDate = new Date();
      this.SlotDate.setDate(this.SlotDate.getDate() + 2);
      localStorage.setItem('TimeSpanDate', this.datepipe.transform(this.SlotDate, 'yyyy-MM-dd'));
      document.getElementById("TheDayAfterTomorrow").style.backgroundColor = "#bd9c6d";
      document.getElementById("TheDayAfterTomorrow").style.color = "white";
      document.getElementById("Tomorrow").style.backgroundColor = "white";
      document.getElementById("Tomorrow").style.color = "#444";
      document.getElementById("Today").style.backgroundColor = "white";
      document.getElementById("Today").style.color = "#444";
     
    }
    this.Slots = [];
    this.SlotHour = this.SlotDate.getHours();
    console.log("Slot hour:" + this.SlotHour);
    if (this.SlotHour < 12 || Day == "Tomorrow" || Day == "TheDayAfterTomorrow") {
      this.Slots.push("10 AM - 12 AM");
      }
    if (this.SlotHour < 14 || Day == "Tomorrow" || Day == "TheDayAfterTomorrow") {
      this.Slots.push("12 AM - 2 PM");
      }
    if (this.SlotHour < 16 || Day == "Tomorrow" || Day == "TheDayAfterTomorrow") {
      this.Slots.push("2 PM - 4 PM");
      }
    if (this.SlotHour < 18 || Day == "Tomorrow" || Day == "TheDayAfterTomorrow") {
        this.Slots.push("4 PM - 6 PM");
      }

    this.SelectSlotTime("0");

  }


}
