import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppComponent } from '../app.component';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Cart } from '../Models/cart';
import { FavouriteModel } from '../Models/favourite-model';
import { ProductInfoModal } from '../Models/product-info-modal';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.css']
})
export class SingleComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  Allcatid: any;
  myOptions = {
    'placement': 'right',
    'show-delay': 500,
    'theme': 'light'

  }
  myOptions1 = {
    'placement': 'left',
    'show-delay': 500,
    'theme': 'light'

  }
    UomId: any;
    BatchId: any;
    MRPPRICE: any;
    SELLINGPRICES: any;
    NewQty: any;
    AddCart: boolean;
    firstLoad: boolean = true;
  constructor(private router: Router, private apiIntergnService: ApiIntergnService, private route: ActivatedRoute, private appComp: AppComponent) { }
  ItemID: any;
  result2 = [];
  StorageData: string;
  CustomerId: number;
  SinglePage: boolean = true;
  //Changed: boolean = false;
  ngOnInit() {
    this.CustomerId = Number(localStorage.getItem('CustId'));
    this.SinglePage = this.appComp.RegisterForm;
    this.route.params.subscribe(params => {
      this.ItemID = params['Itemid'];
      this.firstLoad = true;
      this.LoadProductByID();

    });

    if (this.apiIntergnService.subsRefreshCartVar == undefined) {
      this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {
        if (this.route.routeConfig.path == "single/:Itemid") {
          this.appComp.changed = true;
          this.ngOnInit();
        }
        
      });
    }


    if (this.apiIntergnService.subsVar == undefined) {
      
      this.apiIntergnService.invokeQtyChange.subscribe((Obj: any) => {
          if ((Obj != null && !this.appComp.changed) || (Obj != null && this.appComp.homePageChanged)) {

              debugger;
           
            if (this.LoadProductData != undefined) {
              var ProductDataIndex = this.LoadProductData.findIndex(x => x.item_Id == Obj.Item.itemId);

            }
            else {
              ProductDataIndex = -1;
            }
            if (this.TopPopular != undefined) {
              var TopPopularIndex = this.TopPopular.findIndex(x => x.item_Id == Obj.Item.itemId);
            }
            else {
              TopPopularIndex = -1;
            }
            //if (this.TopSimilardata != undefined) {
            //  var TopSimilardataIndex = this.TopSimilardata.findIndex(x => x.item_Id == Obj.Item.itemId);
            //}
            //if (this.LoadProductByCatData != undefined) {
            //  var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == Obj.Item.itemId);
            //}
          let item: any = {};

          item.Item_Id = Obj.Item.itemId;
          item.ProductName = Obj.Item.productName;
          item.Item_SellingPrice = Obj.Item.productPrice;
            item.Batch_id = Obj.Item.batch_id;
          item.Item_MRP = Obj.Item.item_MRP;
          item.Weight = Obj.Item.weight;
          item.Unit_Name = Obj.Item.unit;
            item.OfferAmt = Obj.Item.offers;


            if (Obj != null) {
              if (((Obj != null && this.appComp.homePageChanged)) && (ProductDataIndex != null && ProductDataIndex != -1)) {
                this.quantityChanged(ProductDataIndex, Obj.Type, Obj.Item, 'Products');
         
              }
              if (((Obj != null && this.appComp.homePageChanged)) && TopPopularIndex != null && TopPopularIndex != -1) {
                this.quantityChanged(TopPopularIndex, Obj.Type, Obj.Item, 'TopPopular');

              }

              if (ProductDataIndex == -1 && TopPopularIndex == -1 && Obj.Item.qty == 0) {
                this.result2 = JSON.parse( localStorage.getItem('Cart'));
                this.result2.forEach((element, index) => {
                  if (element.item_Id == item.item_Id && (element.batchId || element.batch_id) == item.batch_id) {
                    Obj.Item.qty = element.qty;//+ 1;
                  }
                });
                  if (Obj.Item.qty = 0)
                    this.quantityChanged(0, Obj.Type, item, 'Delete');
      
              }
              this.appComp.homePageChanged = false;
              return;
            }
          //if (TopSimilardataIndex != null && TopSimilardataIndex != -1) {
          //  this.quantityChanged(TopSimilardataIndex, Obj.Type, Obj.Item, 'TopSimilar');
          //  return;
          //  }
          //  if (TopSimilarIndex != null && TopSimilarIndex != -1) {
          //    this.quantityChanged(TopSimilarIndex, Obj.Type, Obj.Item, 'TopSimilar');
          //    return;
          //  }

          if (this.appComp.cartItems[Obj.Index].qty == 0) {
            this.appComp.cartItems.splice(Obj.Index, 1);
          }
          //this.quantityChanged(Obj.Index, Obj.Type, Obj.Item, Obj.ObjectName);
        }
        else {
          this.appComp.changed = false;
        }
      });
    }

    if (this.apiIntergnService.subsVarCart == undefined) {
      this.apiIntergnService.invokeCartItemDeleteEvent.subscribe((Obj: any) => {
        debugger;
        if (this.LoadProductData != undefined) {
        var ProductDataIndex = this.LoadProductData.findIndex(x => x.item_Id == Obj.item_Id);
        }
        if (this.TopPopular != undefined) {
          var TopPopularIndex = this.TopPopular.findIndex(x => x.item_Id == Obj.item_Id);
        }
        if (this.TopSimilardata != undefined) {
          var TopSimilardataIndex = this.TopSimilardata.findIndex(x => x.item_Id == Obj.item_Id);
        }
        if (this.LoadProductByCatData != undefined) {
          var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == Obj.item.item_Id);
        }
        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.LoadProductData[ProductDataIndex].qty = 0;
        }
        if (TopPopularIndex != null && TopPopularIndex != -1) {
          this.TopPopular[TopPopularIndex].qty = 0;
        }
        if (TopSimilardataIndex != null && TopSimilardataIndex != -1) {
          this.TopSimilardata[TopSimilardataIndex].qty = 0;
        }
        if (TopSimilarIndex != null && TopSimilarIndex != -1) {
          this.LoadProductByCatData[TopSimilarIndex].qty = 0;
        }
        this.StorageData = localStorage.getItem('Cart');
        this.result2 = JSON.parse(this.StorageData);
      });
    }
   // this.LoadImgData();
   // this.LoadCategory();
    //this.LoadPopularProduct();
    //this.LoadAllCategory();
    this.StorageData = localStorage.getItem('Cart');
    
    this.result2 = JSON.parse(this.StorageData);
    
  }
    OpenShopForm(catid, CateName)
    {

      this.router.navigate(['./shop/' + CateName + '/' + catid.category_ParentID + catid.category_ID]);
       //this.router.navigate(['./shop/All Categories/' + catid]);
      window.scrollTo(0, 0)
    }

  OpenBatch(Batch, mainindex, subIndex, item) {
    debugger;
    this.StorageData = localStorage.getItem('Cart');
    if (this.StorageData != null) {
      this.result2 = JSON.parse(this.StorageData);
    }
    else {
      this.result2 = [];
    }
    mainindex = this.LoadProductData.findIndex(x => x.ItemId == item.ItemId && x.batch_id == item.batch_id);

    //this.LoadProductByCatData.forEach(function (element) {
    //  if (element.ItemId == item.ItemId && element.batch_id == item.batch_id) {

    //  }
    //})

    this.LoadProductData[mainindex].batchDetails.forEach(function (element) {
      element.BackgroundColour = "White";
      element.color = "Black";
    })

    this.LoadProductData[mainindex].batchDetails[subIndex].BackgroundColour = "#154c79";
    this.LoadProductData[mainindex].batchDetails[subIndex].color = "White";


    this.LoadProductData[mainindex].item_SellingPrice = this.LoadProductData[mainindex].batchDetails[subIndex].Batch_SellingPrice;
    this.LoadProductData[mainindex].item_MRP = this.LoadProductData[mainindex].batchDetails[subIndex].Batch_MRPPrice;
    this.LoadProductData[mainindex].isstockavilbl = this.LoadProductData[mainindex].batchDetails[subIndex].Stock ? 1 : 0;
    this.LoadProductData[mainindex].OfferAmt = 0;
    this.LoadProductData[mainindex].batch_id = this.LoadProductData[mainindex].batchDetails[subIndex].Batch_Id;
    this.LoadProductData[mainindex].weight = this.LoadProductData[mainindex].batchDetails[subIndex].Batch_Conversion;
    this.LoadProductData[mainindex].unit_Name = this.LoadProductData[mainindex].batchDetails[subIndex].Unit_Name;
    this.LoadProductData[mainindex].ItemId = this.LoadProductData[mainindex].batchDetails[subIndex].Batch_ItemId;
    this.LoadProductData[mainindex].item_Id = this.LoadProductData[mainindex].batchDetails[subIndex].Batch_ItemId;

    var indexLoadProductData = this.result2.findIndex(x => x.ItemId == Batch.Batch_ItemId || x.itemId == Batch.Batch_ItemId);
    if (indexLoadProductData != -1) {
      for (var i = 0; i < this.result2.length; i++) {
        if ((Batch.Batch_ItemId == this.result2[i].ItemId || Batch.Batch_ItemId == this.result2[i].itemId) &&
          (Batch.Batch_Id == this.result2[i].batch_id || Batch.Batch_Id == this.result2[i].batchId)) {
          this.LoadProductData[mainindex].qty = this.result2[i].qty;
        }

      }
    }
    else {
      this.LoadProductData[mainindex].qty = 0;
    }
    return;

  }
  LoadProductData: any;
  CatId: any;
  ProductName: any = "";
  APICall: boolean = true;


  public LoadProductByID() {
    debugger;
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
      itemId: this.ItemID,
      BranchId: 1
    }
    if (this.APICall == true) {
      this.APICall = false;
      this.apiIntergnService.getProductByID(obj).subscribe((data) => {
        debugger;
        if (!data.isError) {
          this.APICall = true;
          this.LoadProductData = data.data;

          console.log("product data from single page ", data.data);

          this.CatId = this.LoadProductData[0].catID;
          this.ProductName = this.LoadProductData[0].productName;

          this.LoadProductData.forEach(function (element) {
            element.qty = 0;
            element.batchDetails = JSON.parse(element.batchDetails);
            element.batchDetails.forEach(function (batch) {
              batch.Batch_qty = 0;

            })
            window.scrollTo(0, 0)
          });

          this.MRPPRICE = this.LoadProductData[0].batchDetails[0].Batch_MRPPrice;
          this.SELLINGPRICES = this.LoadProductData[0].batchDetails[0].Batch_SellingPrice;
          if (this.LoadProductData[0].batchDetails != null) {

            this.AddCart = true;
          }
          else {
            this.AddCart = false;
          }
          // this.LoadLikeProduct();
          this.ItemID = this.LoadProductData[0].item_Id;
          this.LoadImgData();
          // this.OpenCategory();
          for (var i = 0; i < this.LoadProductData.length; i++) {
            if (this.LoadProductData[i].batchDetails != null) {
              this.LoadProductData[i].batchDetails.forEach(function (element) {
                element.BackgroundColour = "White";
                element.color = "Black";
              })


              this.LoadProductData[i].item_SellingPrice = this.LoadProductData[i].batchDetails[0].Batch_SellingPrice;
              this.LoadProductData[i].item_MRP = this.LoadProductData[i].batchDetails[0].Batch_MRPPrice;
              this.LoadProductData[i].isstockavilbl = this.LoadProductData[i].batchDetails[0].Stock ? 1 : 0;
              this.LoadProductData[i].OfferAmt = 0;
              this.LoadProductData[i].batch_id = this.LoadProductData[i].batchDetails[0].Batch_Id;
              this.LoadProductData[i].weight = this.LoadProductData[i].batchDetails[0].Batch_Conversion;
              this.LoadProductData[i].unit_Name = this.LoadProductData[i].batchDetails[0].Unit_Name;
              this.LoadProductData[i].ItemId = this.LoadProductData[i].batchDetails[0].Batch_ItemId;
              this.LoadProductData[i].item_Id = this.LoadProductData[i].batchDetails[0].Batch_ItemId;
              for (var j = 0; j < this.LoadProductData[i].batchDetails.length; j++) {
                this.LoadProductData[i].batchDetails[0].BackgroundColour = "#154c79";
                this.LoadProductData[i].batchDetails[0].color = "White";
              }
            }
          }

          this.result2 = JSON.parse(localStorage.getItem('Cart'))
          if (this.result2 == undefined) { this.result2 = [] }
          if (this.result2.length > 0) {
            for (var k = 0; k < this.result2.length; k++) {
              if (this.LoadProductData[0].item_Id == this.result2[k].item_Id && this.LoadProductData[0].batchId == (this.result2[k].batchId || this.result2[k].batch_id)) {
                this.LoadProductData[0].qty = this.result2[k].qty;
                if (this.result2[k].Batch_qty == undefined) {
                  this.result2[k].Batch_qty = this.result2[k].qty;
                }
                this.NewQty = this.result2[k].Batch_qty;
                this.AddCart = false;
                break;
              }
              else {
                this.AddCart = true;
              }
            }
          }
          else {
            this.AddCart = true;
          }


          this.firstLoad = false

        }
        else {
          this.APICall = true;
        }
      });
    }
  }


  AddFavourite(items) {
    debugger;
    if (items.batch_id == undefined) {
      items.batch_id = items.batchId;
    }
  
      items.isFavt = !items.isFavt;
      let favouriteModel = new FavouriteModel();
      favouriteModel.Favt_Id = 0;
      favouriteModel.Favt_customerId = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ItemId = items.itemid;
      favouriteModel.Favt_CreateBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ModififedBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_CreatedDate = new Date();
      favouriteModel.Favt_ModififedDate = new Date();
      favouriteModel.Favt_IsDelete = false;
    if (items.isFavt == true)
      favouriteModel.Mode = "Insert"
    if (items.isFavt == false)
      favouriteModel.Mode = "Delete"

      favouriteModel.Favt_BatchId = parseInt(items.batch_id);
      this.apiIntergnService.Addfavourite(favouriteModel).subscribe(Val => {
        if (!Val.isError) {

        }
      });
    //}
    //else {
    //  alert("Please Login To Add Favourite");
    //}

  }



  public ChnageSize(index, unit, BatchId) {
    debugger;
    var i = "Unit" + index;
    document.getElementById(i).style.backgroundColor = "rgb(253, 233, 242)";
    document.getElementById(i).style.color = "rgb(244 51 51)";
    for (var k = 0; k < this.LoadProductData[0].batchDetails.length; k++) {
      if (k != index) {
        var i = "Unit" + k;
        document.getElementById(i).style.backgroundColor = "rgb(255, 255, 255)";
        document.getElementById(i).style.color = "rgb(51, 51, 51)";
      }
    }

    for (var k = 0; k < this.LoadProductData[0].batchDetails.length; k++) {
      if (this.LoadProductData[0].batchDetails[k].Batch_Id == BatchId) {
        this.MRPPRICE = this.LoadProductData[0].batchDetails[k].Batch_MRPPrice;
        this.SELLINGPRICES = this.LoadProductData[0].batchDetails[k].Batch_SellingPrice;
        break;
      }
    }

    this.UomId = unit.Batch_UOMId;
    this.BatchId = BatchId
    this.result2 = JSON.parse(localStorage.getItem("Cart"));

    if (this.result2 == undefined) { this.result2 = [] }
    if (this.result2.length > 0) {

      for (k = 0; k <= this.result2.length; k++) {
        if ((this.result2[k].batchId == BatchId) || (this.result2[k].batch_id == BatchId)) {
          if (this.result2[k].Batch_qty == undefined) {
            this.result2[k].Batch_qty = this.result2[k].qty;
          }
          this.NewQty = this.result2[k].Batch_qty;
          this.AddCart = false;
          break;
        }
        else {
          this.AddCart = true;
        }
      }
    }
    else {
      this.AddCart = true;
    }
  }
  LoadProductByCatData: any;
  OpenCategory() {
    debugger;
    //this.CateAllData123 = item.Category_DisplayName;
    //this.categoryNamesShow = item.Category_DisplayName;
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
      CateId: this.CatId,
      BrandId:""
    }
    this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {
      if (!data.isError) {
        this.LoadProductByCatData = data.data;
        console.log("single loadproducts data ", data.data);
        //this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
        //this.LoadSubProductCatDatalength = this.LoadProductByCatData.length;
        this.LoadProductByCatData.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
        });
        //this.FilterListMethodsort(this.selectedshift);
        for (var i = 0; i < this.LoadProductByCatData.length; i++) {
          for (var k = 0; k < this.result2.length; k++) {
            if (this.LoadProductByCatData[i].item_Id == this.result2[k].item_Id) {
              this.LoadProductByCatData[i].qty = this.result2[k].qty;
            }
          }
        }
      }
    });

    //this.LoadBrandByCatId(item.Category_ID);

    //this.showList(item, index);
  }
  ViewAllCategoryForSimilarProduct() {
    this.Allcatid = this.CatId;
    //this.router.navigate(['./shop/All Categories/' + this.Allcatid]);
    this.router.navigate(['./shop/' + 'Similar Product' + '/' + 3 + this.CatId]);
    window.scrollTo(0, 0)
  }
  LoadImages: any;
  LoadImagesLength: number=0;
  public LoadImgData() {
 
  
    this.apiIntergnService.GetAllImagesByItem(this.ItemID).subscribe((data) => {
      debugger;
      if (!data.isError) {
        console.log('load images from single page',data.data);
        this.LoadImages = data.data;
        this.LoadImagesLength = data.data.length;
      }

      //if (this.LoadProductData != null && this.LoadProductData[0].batchDetails != null) {
      //  this.ChnageSize(0, this.LoadProductData[0].batchDetails[0], this.LoadProductData[0].batchDetails[0].Batch_Id);
      //}
      //else if (this.LoadProductData != null) {
      //  this.BatchId = this.LoadProductData[0].batchId
      //}
    });
  }

  TopSimilardata: any;

  public LoadLikeProduct() {
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
      ShortName: this.LoadProductData[0].shortName,
    }
    this.apiIntergnService.getSimilarProduct(obj).subscribe((data) => {
      debugger;
      if (!data.isError) {
        this.TopSimilardata = data.data;
        //this.TopSaver.push({ Qty:0 });
        this.TopSimilardata.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
           
        });

        for (var i = 0; i < this.TopSimilardata.length; i++) {
          for (var k = 0; k < this.result2.length; k++) {
            if (this.TopSimilardata[i].item_Id == this.result2[k].item_Id) {
              this.TopSimilardata[i].qty = this.result2[k].qty;
            }
          }
        }
      }
    });
  }

  AddToCart(ProductsInfo: ProductInfoModal) {

    let CartInfo: Cart = new Cart();
    CartInfo.AddedInCartDate = new Date();
    CartInfo.ItemId = ProductsInfo.item_Id;
    CartInfo.ProductName = ProductsInfo.productName;
    CartInfo.ProductPrice = ProductsInfo.productPrice;
    CartInfo.BatchId = ProductsInfo.batch_id;
    CartInfo.QTY = 1;
    CartInfo.IsDecreaseQty = ProductsInfo.isDecreaseQty;
    CartInfo.TotalProductPrice = (CartInfo.ProductPrice) * (CartInfo.QTY);
    CartInfo.Item_MRP = ProductsInfo.item_MRP;
    CartInfo.Weight = ProductsInfo.weight;
    CartInfo.Unit_Name = ProductsInfo.unit;
    CartInfo.offers = ProductsInfo.offerAmt;
    var id = localStorage.getItem('CustId');
    CartInfo.UserId = Number(id);
    this.apiIntergnService.AddCart(CartInfo)
      .subscribe(response => {
        if (response) {

         // this.appComp.getTotalAmounts();

        }
        else {
          alert("Something went wrong please try again")
        }
      });

  }
  UserId: number = 0;

    ViewAllCategory()
    {
        debugger;
        this.Allcatid = 0;
        this.router.navigate(['./shop/All Categories/' + this.Allcatid]);
       //this.router.navigate(['./shop/All Categories/' + this.Allcatid], { queryParams: this.Allcatid, skipLocationChange: true });
        window.scrollTo(0, 0);
        this.appComp.OpenAboutUsPageforCategory();
  }
  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }
  OpenTopSaver(item, index, objectName) {
    debugger;
    if (item.batchDetails != null && this.UomId != undefined) {
      if (this.UomId != 0) {
        if (this.UomId > 0) {

        }
        else {
          alert("Please Select Unit");
        }
      }
      else {
        alert("Please Select Unit");
        return;
      }
    }
    this.StorageData = localStorage.getItem('Cart');
    if (this.StorageData != null) {
      this.result2 = JSON.parse(this.StorageData);
    }
    else
    {
      this.result2 = [];
    }
    var prod = new ProductInfoModal();

    this.UserId = Number(localStorage.getItem('CustId'));
   

    if (this.TopPopular != undefined) {
      var indexTopPopular = this.TopPopular.findIndex(x => x.item_Id == item.item_Id);

    }
    if (this.TopSimilardata != undefined) {
      var indexTopSimilardata = this.TopSimilardata.findIndex(x => x.item_Id == item.item_Id);

    }
    if (this.LoadProductData != undefined) {
      var indexLoadProductData = this.LoadProductData.findIndex(x => x.item_Id == item.item_Id);

    }
    if (this.LoadProductByCatData != undefined) {
      var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
    }
    if (objectName == 'Products') {
      prod.qty = this.LoadProductData[indexLoadProductData].qty = 1;
      if (indexTopPopular != -1 && indexTopPopular != null) {
        this.TopPopular[indexTopPopular].qty = this.LoadProductData[indexLoadProductData].qty;
      }
      if (indexTopSimilardata != -1 && indexTopSimilardata != null) {
        this.TopSimilardata[indexTopSimilardata].qty = this.LoadProductData[indexLoadProductData].qty;
      }
      if (indexLoadProductData != -1 && indexLoadProductData != null) {
        this.LoadProductData[indexLoadProductData].qty = this.LoadProductData[indexLoadProductData].qty;
      }
    }
    else if (objectName == 'TopPopular') {
      prod.qty = this.TopPopular[indexTopPopular].qty = 1;

      if (indexLoadProductData != -1 && indexLoadProductData != null) {
        this.LoadProductData[indexLoadProductData].qty = this.TopPopular[indexTopPopular].qty;
      }
      if (indexTopSimilardata != -1 && indexTopSimilardata != null) {
        this.TopSimilardata[indexTopSimilardata].qty = this.TopPopular[indexTopPopular].qty;
      }
    }
    else if (objectName == 'TopSimilar') {
      prod.qty = this.LoadProductByCatData[TopSimilarIndex].qty = 1;

      if (indexLoadProductData != -1 && indexLoadProductData != null) {
        this.LoadProductData[indexLoadProductData].qty = this.TopSimilardata[indexTopSimilardata].qty;
      }
      if (indexTopPopular != null && indexTopPopular != -1) {
        this.TopPopular[indexTopPopular].qty = this.TopSimilardata[indexTopSimilardata].qty;
      }
    }
    if (item.imagePath == undefined) {
      item.imagePath = item.path;
    }
    if (item.batchId != undefined) {
      item.batch_id = item.batchId
    }

    if (item.batchDetails != null) {
      for (var k = 0; k < item.batchDetails.length; k++) {
        if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
          item.batch_id = item.batchDetails[k].Batch_Id,
            item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
            item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
            item.uomId = item.batchDetails[k].Unit_Id
          break;
        }
      }
    }

    prod.Batch_qty = 1
    prod.itemId = item.item_Id;
    prod.item_Id = item.item_Id;
    prod.productName = item.productName;
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.item_MRP = item.item_MRP;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.offers = item.offerAmt;
    prod.imagePath = item.imagePath;
    prod.uomId = item.uomId;

    this.NewQty = prod.Batch_qty
    this.AddCart = false;
    var t = this.result2.find(list => list.item_Id == prod.item_Id && list.batch_id == prod.batchId)
    if (t) {
      this.result2.forEach((element, index) => {
        element.qty = prod.qty;
        element.Batch_qty = prod.Batch_qty
      })
    }

    this.result2.push(prod);
    prod.CustomerID = Number(localStorage.getItem('CustId'));



    this.appComp.TotalItem = this.result2.length;
    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));
    if (prod.UserId > 0) {
      debugger;
      var PreCartData = prod;
      PreCartData.qty = 1;
      this.apiIntergnService.AddPreSingleCart(PreCartData)
        .subscribe(Val => {
          debugger;
          if (!Val.isError) {
            this.result2.forEach((element, _index) => {
              if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                element.qty = Val.data.qty;
              }
            });
            localStorage.setItem('Cart', (JSON.stringify(this.result2)));
          }
        });
    }

    localStorage.setItem('Cart', (JSON.stringify(this.result2)));
  }


  AppquantityChanged(index, type, item, objectName) {
    debugger;
    if (item.batchDetails != null && this.UomId != undefined) {
      if (this.UomId != 0) {
        if (this.UomId > 0) { }
        else { this.UomId = 0 }
      } else { this.UomId = 0 }
      if (this.UomId == 0) {
        alert("Please select Unit");
        return;
      }
    }
    if (this.router.url.includes("/single/")) {
      this.appComp.changed = true;
      var prod = new ProductInfoModal();

      this.UserId = Number(localStorage.getItem('CustId'));
      if (objectName == 'Products') {

        if (type == 'Increament') {
          prod.isDecreaseQty = false;
        }
        else {
          this.LoadProductData[index].qty -= 1;
          prod.isDecreaseQty = true;
        }

        if (item.imagePath == undefined) {
          item.imagePath = item.path;
        }
        if (item.batchId != undefined) {
          item.batch_id = item.batchId
        }
        this.result2 = JSON.parse(localStorage.getItem("Cart"));

        if (this.result2 == undefined) { this.result2 = [] }

        if (item.batchDetails != null) {
          for (var k = 0; k < this.result2.length; k++) {
            if (this.result2[k].uomId == this.UomId && this.result2[k].batchId == this.BatchId) {
              prod.Batch_qty = this.result2[k].Batch_qty;
              if (this.BatchId == this.result2[k].batchId) {
                this.NewQty = prod.Batch_qty
              }
              break;
            }
          }
        }
        else {
          for (var k = 0; k < this.result2.length; k++) {
            if (this.result2[k].batchId == item.batchId) {
              prod.Batch_qty = this.result2[k].Batch_qty;
              if (this.BatchId == this.result2[k].batchId) {
                this.NewQty = prod.Batch_qty
              }
              break;
            }
          }
        }

      }


      this.appComp.cartItems = this.result2
      prod.CustomerID = Number(localStorage.getItem('CustId'));
      prod.UserId = Number(localStorage.getItem('CustId'));

      localStorage.setItem('Cart', (JSON.stringify(this.result2)));

      this.StorageData = localStorage.getItem('Cart');

      this.appComp.TotalItem = this.result2.length;
    }
  }
  quantityChanged(index, type, item, objectName) {
    debugger;
    if (item.batchDetails != null && this.UomId != undefined) {
      if (this.UomId != 0) {
        if (this.UomId > 0) { }
        else { this.UomId = 0 }
      } else { this.UomId = 0 }
      if (this.UomId == 0) {
        alert("Please Select Unit");
        return;
      }
    }
    if (this.router.url.includes("/single/")) {
      this.appComp.changed = true;
      var prod = new ProductInfoModal();
      var _Qty = 0;
      this.UserId = Number(localStorage.getItem('CustId'));

      //if (this.UserId == 0) {
      //  this.LoginModalOpen();
      //  return;
      //}
      if (objectName == 'Products') {

        //var prod = new ProductInfoModal();
        if (type == 'Increament') {
          this.LoadProductData[index].qty += 1;
        }
        else {
          this.LoadProductData[index].qty -= 1;
          prod.isDecreaseQty = true;
        }
        _Qty = this.LoadProductData[index].qty;
        if (this.TopPopular != undefined) {
          var indexTopPopular = this.TopPopular.findIndex(x => x.item_Id == item.item_Id);

        }
        if (indexTopPopular != -1 && indexTopPopular != null) {
          this.TopPopular[indexTopPopular].qty = this.LoadProductData[index].qty;
        }
        if (this.LoadProductByCatData != undefined) {
          var indexTopSimilardata = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
        }
        if (indexTopSimilardata != -1 && indexTopSimilardata != null) {
          this.LoadProductByCatData[indexTopSimilardata].qty = this.LoadProductData[index].qty;
        }
        if (item.imagePath == undefined) {
          item.imagePath = item.path;
        }
        if (item.batchId != undefined) {
          item.batch_id = item.batchId
        }
        this.result2 = JSON.parse(localStorage.getItem("Cart"));

        if (this.result2 == undefined) { this.result2 = [] }

        if (item != null) {
      
          if (item.batchDetails != null && item.batchDetails != undefined) {
            for (var k = 0; k < this.result2.length; k++) {
              if (this.result2[k].uomId == item.batchDetails[0].Unit_Id && (this.result2[k].batchId || this.result2[k].batch_id) == item.batchDetails[0].Batch_Id) {
                if (this.result2[k].Batch_qty == undefined) {
                  this.result2[k].Batch_qty = this.result2[k].qty;
                }
                prod.Batch_qty = this.result2[k].Batch_qty;
                this.AddCart = false;
                break;
              }
            }
            for (var k = 0; k < item.batchDetails.length; k++) {
              if (item.batchDetails[k].Batch_Id == item.batchDetails[0].Batch_Id && item.batchDetails[k].Batch_UOMId == item.batchDetails[0].Unit_Id) {
                item.batch_id = item.batchDetails[k].Batch_Id,
                  item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
                  item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
                  item.uomId = item.batchDetails[k].Unit_Id;
                if (type == 'Increament') {
                  if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
                  prod.Batch_qty += 1;

                }
                else {
                  if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                  //  alert("Can't Find The Product With Selected Size In Cart");
                    return;
                  }
                  prod.Batch_qty -= 1;
                }

                break;
              }
            }
          }
          else {
            for (var k = 0; k < this.result2.length; k++) {
              if ((this.result2[k].batchId == item.batchId) || (this.result2[k].batch_id == item.batchId)) {
                prod.Batch_qty = this.result2[k].Batch_qty;
                this.AddCart = false;
              }
            }
            if (type == 'Increament') {
              if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
              prod.Batch_qty += 1;
            }
            else {
              if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
               // alert("Can't Find The Product With Selected Unit In Cart");
                return;
              }
              prod.Batch_qty -= 1;
            }
          }
        }

        else {
          for (var k = 0; k < this.result2.length; k++) {
            if ((this.result2[k].batchId == item.batchId) || (this.result2[k].batch_id == item.batchId)) {
              prod.Batch_qty = this.result2[k].Batch_qty;
              this.AddCart = false;
            }
          }
          if (type == 'Increament') {
            if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
            prod.Batch_qty += 1;
          }
          else {
            if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
             // alert("Can't Find The Product With Selected Unit In Cart");
              return;
            }
            prod.Batch_qty -= 1;
          }
        }

        if (item.item_SellingPrice == undefined) {
          item.item_SellingPrice = item.productPrice;
        }
        if (item.unit_Name == undefined) {
          item.unit_Name = item.unit;
        }


        prod.itemId = item.item_Id;
        prod.productName = item.productName;
        prod.productPrice = item.item_SellingPrice;
        prod.batch_id = item.batch_id;
        prod.batchId = item.batch_id;
        prod.item_MRP = item.item_MRP;
        prod.weight = item.weight;
        prod.unit = item.unit_Name;
        prod.uomId = item.uomId;
        prod.offers = item.offerAmt;
        prod.qty = this.LoadProductData[index].qty;
        prod.imagePath = item.imagePath;

        this.NewQty = prod.Batch_qty;
        //this.result2.forEach((element, index) => {
        //  if (element.ItemId == prod.ItemId) {
        //    element.QTY = prod.QTY;
        //    if (prod.QTY == 0) {
        //      const index = this.result2.findIndex(list => list.ItemId == prod.ItemId);//Find the index of stored id
        //      this.result2.splice(index, 1)
        //    }
        //  }
        //});

        //  prod.CustomerID = Number(localStorage.getItem('CustId'));
        //  localStorage.setItem('Cart', (JSON.stringify(this.result2)));
        //  this.StorageData = localStorage.getItem('Cart');
      }

      else if (objectName == 'TopPopular') {
        //var prod = new ProductInfoModal();
        if (type == 'Increament') {
          this.TopPopular[index].qty += 1;
        }
        else {
          this.TopPopular[index].qty -= 1;
          prod.isDecreaseQty = true;
        }
        _Qty = this.TopPopular[index].qty;
        if (this.LoadProductData != undefined) {
          var indexLoadProductData = this.LoadProductData.findIndex(x => x.item_Id == item.item_Id);
        }

        if (indexLoadProductData != -1 && indexLoadProductData != null) {
          this.LoadProductData[indexLoadProductData].qty = this.TopPopular[index].qty;
        }
        if (this.LoadProductByCatData != undefined) {
          var indexTopSimilardata = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
        }

        if (indexTopSimilardata != -1 && indexTopSimilardata != null) {
          this.LoadProductByCatData[indexTopSimilardata].qty = this.TopPopular[index].qty;
        }


        //prod.ItemId = item.Item_Id;
        //prod.ProductName = item.ProductName;
        //prod.ProductPrice = item.Item_SellingPrice;
        //prod.Batch_id = item.Batch_id;
        //prod.QTY = this.TopPopular[index].Qty;
        //prod.Item_MRP = item.Item_MRP;
        //prod.Weight = item.Weight;
        //prod.Unit = item.Unit_Name;
        //prod.offers = item.OfferAmt;
        //this.result2.forEach((element, index) => {
        //  if (element.ItemId == prod.ItemId) {
        //    element.QTY = prod.QTY;
        //    if (prod.QTY == 0) {
        //      const index = this.result2.findIndex(list => list.ItemId == prod.ItemId);//Find the index of stored id
        //      this.result2.splice(index, 1)
        //    }
        //  }
        //});
        //  prod.CustomerID = Number(localStorage.getItem('CustId'));
        //  localStorage.setItem('Cart', (JSON.stringify(this.result2)));
        //  this.StorageData = localStorage.getItem('Cart');
        //  console.log(this.StorageData);
      }

      else if (objectName == 'TopSimilar') {
        // var prod = new ProductInfoModal();

        if (type == 'Increament') {
          _Qty = this.LoadProductByCatData[index].qty += 1;
        }
        else {
          _Qty = this.LoadProductByCatData[index].qty -= 1;
          prod.isDecreaseQty = true;
        }
        _Qty = this.LoadProductByCatData[index].qty;
        if (this.LoadProductData != undefined) {
          var indexLoadProductData = this.LoadProductData.findIndex(x => x.item_Id == item.item_Id);
        }

        if (indexLoadProductData != -1 && indexLoadProductData != null) {
          this.LoadProductData[indexLoadProductData].qty = this.TopSimilardata[index].qty;
        }
        if (this.TopPopular != undefined) {
          var indexTopPopular = this.TopPopular.findIndex(x => x.item_Id == item.item_Id);
        }

        if (indexTopPopular != -1 && indexTopPopular != null) {
          this.TopPopular[indexTopPopular].qty = this.TopSimilardata[index].qty;
        }


        //prod.ItemId = item.Item_Id;
        //  prod.ProductName = item.ProductName;
        //  prod.ProductPrice = item.Item_SellingPrice;
        //  prod.Batch_id = item.Batch_id;
        //prod.QTY = this.TopSimilardata[index].Qty;
        //this.result2.forEach((element, index) => {
        //  console.log(element.ItemId);
        //  if (element.ItemId == prod.ItemId) {
        //    element.QTY = prod.QTY;
        //    if (prod.QTY == 0) {
        //      const index = this.result2.findIndex(list => list.ItemId == prod.ItemId);//Find the index of stored id
        //      this.result2.splice(index, 1)
        //    }
        //  }
        //});

        //  prod.CustomerID = Number(localStorage.getItem('CustId'));
        //  localStorage.setItem('Cart', (JSON.stringify(this.result2)));
        //  this.StorageData = localStorage.getItem('Cart');
      }
      else if (objectName == 'Delete') {
        var prod = new ProductInfoModal();
        prod.isDecreaseQty = true;
        _Qty = 0
      }
      if (item.imagePath == undefined) {
        item.imagePath = item.path;
      }
      prod.itemId = item.item_Id;
      prod.item_Id = item.item_Id;
      prod.productName = item.productName;
      prod.productPrice = item.item_SellingPrice;
      prod.batch_id = item.batch_id;
      prod.batchId = item.batch_id;
      prod.item_MRP = item.item_MRP;
      prod.weight = item.weight;
      prod.unit = item.unit_Name;
      prod.offers = item.offerAmt;
      prod.qty = _Qty;
      prod.Batch_qty = _Qty;
      prod.uomId = item.uomId;
      prod.imagePath = item.imagePath;

      this.result2.forEach((element, index) => {
        if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId ) {
          element.qty = prod.qty;
          element.Batch_qty = prod.Batch_qty
          //if (prod.qty == 0) {
          //  const index = this.result2.findIndex(list => list.item_Id == prod.itemId);//Find the index of stored id
          //  this.result2.splice(index, 1);
          //  this.AddCart = true;
          //  //this.appComp.cartItems.splice(index, 1);
          //}
          if (prod.Batch_qty == 0) {
            const index = this.result2.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId ));
            this.result2.splice(index, 1);
            this.AddCart = true;
          }
        }
        //else {
        //  this.result2.push(prod);
        //}
      });

    
      if (this.result2.length > 0) {
        for (var k = 0; k < this.result2.length; k++) {
          if (this.LoadProductData[0].item_Id == this.result2[k].item_Id && this.LoadProductData[0].batchId == (this.result2[k].batchId || this.result2[k].batch_id)) {
            this.LoadProductData[0].qty = this.result2[k].qty;
            if (this.result2[k].Batch_qty == undefined) {
              this.result2[k].Batch_qty = this.result2[k].qty;
            }
            this.NewQty = this.result2[k].Batch_qty;
            this.AddCart = false;
            break;
          }
          else {
            this.AddCart = true;
          }
        }
      }
      else {
        this.AddCart = true;
      }
      this.appComp.cartItems = this.result2
      prod.CustomerID = Number(localStorage.getItem('CustId'));
      prod.UserId = Number(localStorage.getItem('CustId'));
      if (prod.UserId > 0) {
        debugger;
        var PreCartData = prod;
        PreCartData.qty = 1;
        this.apiIntergnService.AddPreSingleCart(PreCartData)
          .subscribe(Val => {
            debugger;
            if (!Val.isError) {
              this.result2.forEach((element, _index) => {
                if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                  element.qty = Val.data.qty;
                }
              });
              localStorage.setItem('Cart', (JSON.stringify(this.result2)));
            }
          });
      }
      localStorage.setItem('Cart', (JSON.stringify(this.result2)));

      this.StorageData = localStorage.getItem('Cart');

      this.appComp.TotalItem = this.result2.length;
    }
  }

  AllCatData: any;
  public LoadAllCategory() {

    this.apiIntergnService.getAllCategories().subscribe((data) => {
      if (!data.isError) {
        this.AllCatData = data.data;
      }
    });
  }


  TopPopular: any;
  public LoadPopularProduct() {
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
    }
  
    this.apiIntergnService.GETMostPopularProducts(obj).subscribe((data) => {
      if (!data.isError) {
        this.TopPopular = data.data;
        console.log("top popular data from single ", this.TopPopular);
        //this.TopSaver.push({ Qty:0 });
        this.TopPopular.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
        });

        for (var i = 0; i < this.TopPopular.length; i++) {
          for (var k = 0; k < this.result2.length; k++) {
            if (this.TopPopular[i].item_Id == this.result2[k].item_Id) {
              this.TopPopular[i].qty = this.result2[k].qty;
            }
          }
        }
        //console.log(this.TopSaver);
      }
    });
  }

  OpenSingleForm(ID) {
    debugger;
    this.route.params.subscribe(
      params => {
        this.ItemID = ID;
        params['Itemid'] = this.ItemID;

        this.LoadProductByID();
      }
    );

    this.router.navigate(['./single/' + this.ItemID]);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  carouselOptionsForSection3 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 5,
        nav: true,
        dots: false
      },
      600: {
        items: 5,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }
  carouselOptionsForSection4 = {
    margin: 25,
    nav: true,
    dots: true,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: true
      },
      600: {
        items: 1,
        nav: true,
        dots: true
      },
      1000: {
        items: 1,
        nav: true,
        loop: false,
        dots: true
      },
      1500: {
        items: 1,
        nav: true,
        loop: false,
        dots: true
      }
    }
  }

  carouselOptionsForSEction2 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 3,
        nav: true,
        dots: false,
        pagination: true
      },
      600: {
        items: 5,
        nav: true,
        dots: false,
        pagination: true
      },
      1000: {
        items: 8,
        nav: true,
        loop: false,
        dots: false,
        pagination: true
      },
      1500: {
        items: 8,
        nav: true,
        loop: false,
        dots: false,
        pagination: true
      }
    }
  }

 
  CateData: any;
  public LoadCategory() {
  
      this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
      if (!data.isError) {
        this.CateData = data.data;
      }
    });
  }

  carouselOptionsForSection5 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      600: {
        items: 2,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  

  

}
