import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { ModalDirective } from 'ngx-bootstrap';
import { CustomershippingInfoModel } from '../Models/customershipping-info-model';
import { MapsAPILoader } from '@agm/core';

import { Title } from '@angular/platform-browser';
import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';
declare var $: any;
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  @ViewChild('searchel', { static: false }) public searchElement: ElementRef;
  @ViewChild('AddressForm', { static: false }) AddressForm: ModalDirective;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('ConfirmationPageOfAddress', { static: false }) ConfirmationPageOfAddress: ModalDirective;
  @ViewChild('AddressDeleteModel', { static: false }) AddressDeleteModel: ModalDirective;
  @ViewChild('ResetFinalPassword', { static: false }) ResetFinalPassword: ModalDirective;

  public searchControl: FormControl;
  title = 'My first AGM project';
  unamePattern = "^[a-zA-Z\\s]*$";
  NumberPattern = "^[0-9]*$"
  //lat = 19.077065;
  //lng = 72.998993;
  //google: any;

  //new value

  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;


  //latitude: number;
  //longitude: number;
  //zoom: number;
  address: string;
  private geoCoder;
 

  constructor(private router: Router, private formBuilder: FormBuilder, private apiIntergnService: ApiIntergnService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private ref: ChangeDetectorRef
) { }
  pnlAccount: boolean; oTPsubmitted = false;
  addForm: FormGroup; Mode: string; CustomershippingCustomerId: number =0;
  ngOnInit()
  {

    debugger;
      this.addForm = this.formBuilder.group({
          CustomershippingAddress: ['', Validators.required],
          Customershipping_State: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
          Customershipping_Pincode: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.NumberPattern)]],
          Customershipping_Name: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
          Customershipping_Address1: ['', Validators.required],
          Customershipping_Address2: ['', Validators.required],
          Customershipping_FSSAINo: [''],

      },

      );

     // this.setCurrentPosition();


      this.searchControl = new FormControl();
      
      this.mapsAPILoader.load().then(() => {
        
        this.geoCoder = new google.maps.Geocoder;

        this.setCurrentLocation();
       
        
        let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types: ["address"]});
        debugger;
        //let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            debugger;
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.address = place.formatted_address;
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;
          });
        });
      });


   
    
    this.LoadCategory();

    this.CustomerAddress();

  
    
  }

  

 

  private setCurrentPosition()
  {
    //this.latitude = 18.51957;
    //this.longitude = 73.85535;
    //this.zoom = 12;

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
  

  // Get Current Location Coordinates
  private setCurrentLocation()
  {
    debugger;
    this.latitude = 18.51957;
    this.longitude = 73.85535;
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);

    //if ('geolocation' in navigator)
    //{
    //  navigator.geolocation.getCurrentPosition((position) => {
    //    this.latitude = position.coords.latitude;
    //    this.longitude = position.coords.longitude;
    //    this.zoom = 8;
    //    this.getAddress(this.latitude, this.longitude);
    //  });
    //}
  }
  
  markerDragEnd($event: any) {
    debugger;
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    debugger;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.addForm.controls.AreaName.setValue(this.address);
        } else {
          window.alert('No results found');
        }
      } else
      {
       // window.alert('Geocoder failed due to: ' + status);
      }

    });
   
  }



  addressText: string;
  currentCenter: any;
  findPlace() {
    debugger;
    this.geocode(this.addressText).then(place =>
    {
      this.currentCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      console.log(this.currentCenter);

     // this.currentLat = position.coords.latitude;
     // this.currentLong = position.coords.longitude;
     //this.ResetFinalPassword.show();

    })
      .catch(err => {
        console.log(err);
      });
  }



  geocode(address: string): Promise<any> {

    debugger;
   
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          address: address
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results[0]);
          } else {
            reject(new Error(status.toString()));
          }
        }
      );
    });
  }



  //private setCurrentLocation() {
  //  debugger;
  //  if ('geolocation' in navigator) {
  //    navigator.geolocation.getCurrentPosition((position) => {
  //      this.latitude = position.coords.latitude;
  //      this.longitude = position.coords.longitude;
  //      this.zoom = 8;
  //      this.getAddress(this.latitude, this.longitude);
  //    });
  //  }
  //}



  //markerDragEnd($event: any)
  //{
  //  debugger;
  //  console.log($event);
  //  this.latitude = $event.coords.lat;
  //  this.longitude = $event.coords.lng;
  //  this.getAddress(this.latitude, this.longitude);
  //}


  //getAddress(latitude, longitude)
  //{
  //  debugger;
    
  //  const geocoder = new google.maps.Geocoder();
  //  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //    console.log(results);
  //    console.log(status);
  //    if (status === 'OK') {
  //      if (results[0]) {
  //        this.zoom = 12;
  //        this.address = results[0].formatted_address;
  //      } else {
  //        window.alert('No results found');
  //      }
  //    } else {
  //      window.alert('Geocoder failed due to: ' + status);
  //    }

  //  });
  //}



  
  AddressLength: number = 0;
  CateData: any;
  public LoadCategory() {
    var ids = localStorage.getItem('CustId');
    this.apiIntergnService.getAllCustomerAddress(ids).subscribe((data) => {
      if (!data.isError) {
        this.CateData = data.data;
        console.log("CateData ,allcustomeraddress in account", data.data);
        this.AddressLength = this.CateData.length;
       

      }
    });
  }


  UserId: number = 0; 
  LogoutLocalStorage = [];
  StorageData: string;
  currentLat: number = 0;
  currentLong: number = 0;
  findMe()
  {
    debugger;
    if (navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    }
    else
    {
     // alert("Geolocation is not supported by this browser.");
    }
  }

  Pincode() {
    debugger;
    if (this.addForm.controls.Customershipping_Pincode.errors == null) {
      this.apiIntergnService.GetDataThroughPincode(this.addForm.controls.Customershipping_Pincode.value).subscribe(response => {
        if (response) {
          if (!response.isError) {
            console.log("pincode in account", response.data);
            this.addForm.controls.Customershipping_State.setValue(response.data.StateName);
          }
          else {
            this.addForm.controls.Customershipping_State.setValue("");
          }
        }
      });
    }
    else {
      this.addForm.controls.Customershipping_State.setValue("");
    }
    //if(this.addForm.value.Customershipping_Pincode);
  }

  map: any;
  marker: any;
  showPosition(position) {
    debugger;
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;



    //this.ResetFinalPassword.show();
    //let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    //console.log(location);
   
    //let geocoder = new google.maps.Geocoder;
    //let latlng = new google.maps.LatLng(this.currentLat, this.currentLong);
    //let request = { latLng: latlng };
   
    
    //geocoder.geocode({ 'location': latlng }, (results, status) => {
    //  console.log(google.maps.GeocoderStatus);
    //  if (status == google.maps.GeocoderStatus.OK) {
    //    let result = results[0];
    //    let rsltAdrComponent = result.address_components;
    //    let resultLength = rsltAdrComponent.length;
    //    if (result != null) {
          
    //    } else {
    //      alert("No address available!");
    //    }
    //  }

    //})

    
  }


  OpenLocationMap()
  {
    this.ResetFinalPassword.show();
  }




  LogOut()
  {
    debugger;
    this.StorageData = localStorage.getItem('Cart');
   
    this.LogoutLocalStorage = JSON.parse(this.StorageData);
    localStorage.clear();
    this.router.navigateByUrl('/Home');
    this.UserId = 0;
    location.reload();
    localStorage.setItem('Cart', (JSON.stringify(this.LogoutLocalStorage)));
    //this.ngOnInit();
  }


  AccountForm() {
    debugger;
    this.pnlAccount = false;
    this.router.navigate(['./Account']);
    window.scrollTo(0, 0)

  }


  MyOrderForm() {
    this.pnlAccount = false;
    this.router.navigate(['./MyOrder']);
    window.scrollTo(0, 0)
  }
  CustomerAddress1: string; CustomerAddress2: string;
  CustomerInfo: any; CustomerName: string; CustomerPhoneNo: string;

  CustomerAddress() {

    var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
    this.CustomerInfo = JSON.parse(CustomerDetails);
    console.log("customer all details in account", this.CustomerInfo);
    this.CustomerName = this.CustomerInfo.data.customer_Name;
    this.CustomerPhoneNo = this.CustomerInfo.data.customer_Mobile;
    //this.CustomerAddress1 = this.CustomerInfo.Data.Customer_Address1;
    //this.CustomerAddress2 = this.CustomerInfo.Data.Customer_Address2;
    //return this.Address;
  }


  
  get o() { return this.addForm.controls; }


  BackToAccount()
  {
    this.AddressForm.show();
    this.ResetFinalPassword.hide();
  }

  BackToAccountpage()
  {
    this.ResetFinalPassword.hide();
  }
  SaveGoogleMap()
  {
    this.ResetFinalPassword.hide();
  }
  //this.addForm.value.Customershipping_Address2 + " ," + this.address;

    SaveAddtress()
    {
        debugger;
        this.oTPsubmitted = true;
    
            var ids = localStorage.getItem('CustId');
            this.addForm.value.Mode = this.Mode;
            this.addForm.value.Customershipping_Id = this.CustomershippingCustomerId
            this.addForm.value.Customershipping_CustomerId = ids;
            this.addForm.value.Customershipping_Createdby = ids;
            this.addForm.value.Customershipping_Status = 'Active';
            var s = JSON.stringify(this.addForm.value);
            this.apiIntergnService.ChangeAddress(this.addForm.value).subscribe(response => {
                if (response) {
                    if (!response.isError) {
                        this.AddressForm.hide();
                        // this.ResetFinalPassword.show();
                        this.ngOnInit();
                        // alert("Address Changed Successfully!!")
                        this.addForm.reset();
                        this.oTPsubmitted = false;
                    }
                    else {
                        var msg = "";
                        response.Message.forEach(value => {
                            msg += value + "\n";
                        });
                        alert(msg.toUpperCase());
                        this.oTPsubmitted = false;

                    }




                    
                }

                else {
                    

                }

            });
        

    }
  OpenAddressForm()
  {
    this.addForm.reset();
    this.AddressForm.show();
    this.oTPsubmitted = false;
    this.Mode = "Insert";
  }


  EditAddressForm(Item)
  {

    debugger;
    this.Mode = "Update";
    this.oTPsubmitted = false;
    this.CustomershippingCustomerId = Item.customershipping_Id;
    let custShipplinginfo: CustomershippingInfoModel = new CustomershippingInfoModel();
    custShipplinginfo.Customershipping_Id = Item.customershipping_Id;
    custShipplinginfo.Customershipping_CustomerId = Item.customershipping_CustomerId;
   // custShipplinginfo.Customershipping_Address2 = Item.Customershipping_Address1 + " ," + this.address;

    //custShipplinginfo.Customershipping_Address2 = Item.Customershipping_CustomerId;
    var data = {
      "CustomerId": custShipplinginfo.Customershipping_CustomerId,
      "ShippingId": custShipplinginfo.Customershipping_Id
    }

    this.apiIntergnService.getCustomerShippingAddressById(data).subscribe(data => {
      if (data)
      {
        this.addForm.patchValue(data.data);
        console.log("shipping address in account", data.data);
        //this.addForm.controls.address.setValue(data.Data.AreaName);
         this.AddressForm.show();
      }
    });
  }


  DeleteAddress()
  {
    this.ConfirmationPageOfAddress.hide();
    var ids = localStorage.getItem('CustId');
    this.addForm.value.Mode = this.Mode;
    this.addForm.value.Customershipping_Id = this.CustomershippingCustomerId
    this.addForm.value.Customershipping_CustomerId = ids;

    
    this.apiIntergnService.ChangeAddress(this.addForm.value).subscribe(data => {
      if (data) {

        if (!data.isError) {
          //this.AddressDeleteModel.show();
          //alert("ADDRESS DELETED SUCCESSFULLY");
          this.ngOnInit();
        }
        else
        {
          var msg = "";
          data.Message.forEach(value => {
            msg += value + "\n";
          });
          alert(msg.toUpperCase());


        }




      }

    });
  }


  DeleteConfirmationForm(Item)
  {
    this.ConfirmationPageOfAddress.show();
    this.Mode = "Delete";
    this.CustomershippingCustomerId = Item.customershipping_Id;
  }

  GotoMap()
  {
    debugger;
    this.ResetFinalPassword.show();
  }


  public keyDownFunction(event) {

    if (event.keyCode == 40) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input,select"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 38) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input,select"))
      const index =
        (inputs.indexOf(document.activeElement) - 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 13) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input,select"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }

  }


}
