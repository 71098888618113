import { Component, OnInit } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { location } from 'ngx-bootstrap/utils/facade/browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router,private apiIntergnService: ApiIntergnService) { }

  ngOnInit() {
      this.LoadCategory();
      this.GetCategoriesNavigation();
  }
  OpenShopForm(catid, catname) {
    this.router.navigate(['./shop/' + catname+'/'+ catid]);
    window.scrollTo(0, 0)
  }
    CateAllData: any;

    public GetCategoriesNavigation() {
        debugger;
        this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
            if (!data.isError) {
                this.CateAllData = data.data;

                //this.CateAllData.forEach(function (element) {
                //    element.subcategory = false;

                //})
            }
        });
    }

    OpenCategory(catid, CateName)
    {
        debugger;

      
        //this.listShow = false;
     // this.router.navigate(['./shop/' + CateName + '/' + catid.category_ParentID + catid.category_ID]);
      this.router.navigate(['./']);
        //for (let myChild of this.CateAllData) {
        //    myChild.BackgroundColour = "white";
        //}

        //catid.BackgroundColour = "lightGrey";
    }
  public LoadCategory() {

    this.apiIntergnService.getAllCategories().subscribe((data) => {
      if (!data.isError) {
        this.CateAllData = data.data;

      }
    });
  }

  AboutUs() {
  
    this.router.navigateByUrl('/AboutUs');
     window.scrollTo(0, 0)
  }
  ContactUs() {
    this.router.navigateByUrl('/ContactUs');
    window.scrollTo(0, 0)
  }
  Privacy() {
    this.router.navigateByUrl('/PrivacyPolicy');
    window.scrollTo(0, 0)
  }
}
