import { Component, ViewEncapsulation, ViewChild, OnInit, AfterViewInit, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

import { ApiIntergnService } from './AppServices/api-intergn.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationStart, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Cart } from './Models/cart';
import { ProductInfoModal } from './Models/product-info-modal';
import { MustMatch } from './Models/must-match';
import { ValidateInfo } from './Models/validate-info';
import { HomePageComponent } from './home-page/home-page.component';
import { filter, take } from 'rxjs/operators';
import { Local } from 'protractor/built/driverProviders';



declare var $: any;


@Component({
  //host: {
  //  '(document:click)': 'onClick($event)',
  //},
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  @ViewChild('CartForm', { static: false }) CartForm: ModalDirective;
  @ViewChild('SignUPForm', { static: false }) SignUPForm: ModalDirective;
  @ViewChild('LoginSuccessMSG', { static: false }) LoginSuccessMSG: ModalDirective; OtpMassages: string;
  @ViewChild('ResetFinalPassword', { static: false }) ResetFinalPassword: ModalDirective;
  @ViewChild('ForgetPaswordForm', { static: false }) ForgetPaswordForm: ModalDirective;
  @ViewChild('ValidateForm', { static: false }) ValidateForm: ModalDirective; ErrorRegistrationMassages: string;
  @ViewChild('ConfirmPasswordForm', { static: false }) ConfirmPasswordForm: ModalDirective;
  @ViewChild('SearchForm', { static: false }) SearchForm: ModalDirective;
  @Output() GetLocalSTorageData: EventEmitter<any> = new EventEmitter<any>();
  submitted = false; unamePattern = "^[a-zA-Z\\s]*$";
  submittedForget = false; MobilePjonesubmitted = false; pnlLoginOtp: boolean; pnlLoginPassword: boolean = true;
  submittedLogin = false; oTPsubmitted = false; OpenLoginForms: string;
  submittedChaned = false; MobileErrorMassages: string; IsOTPLogin: boolean = false;
  searchText: string; CustomerID: number;
  CustomerOTPID: number;
  SearchBar: boolean = true;
  @ViewChild('CityName', { static: false }) CityName: ElementRef;

  catid: number = 0; LocalStrogedata: any;
  CityNameLocation: string = "Pune"; pnlAccount: boolean;
  pnlLogin: boolean = true;
   ErrorLoginMassages: string;
  pnltable: boolean = false;
  pnl1row: boolean = true;
  Customer_Mobile: any; Password: any;
  cartItems: any[];
  addForm: FormGroup;
  ForgetPasswordForm: FormGroup;
  count : number=1
  LogiForm: FormGroup;
  OTPForm: FormGroup;
  CutomerMobilePhonForm: FormGroup;
  ChangedPasswordForm: FormGroup;
  cartItemsCal: any;
  TotalAmount: number = 0; TotalItem: number = 0;
  btnAddtoCart: boolean = false;
  btnAddtoCartIncrement: boolean = true;
  pnlRegistration: boolean = false;
  OpenCity: boolean = false;
  OpenCityAddress: boolean = false;
  listShow: boolean = false;
  min: any;
  title = 'GW-Online-Retail';
  navbarOpen = false;
  sidebarOpen = false;
  txtValue: number;
  divElementUl: any;
  IsVisible: boolean = true;
  Footer: boolean = false;
  IsVisibleOffer: boolean = true;
  IsVisiblemenu: boolean = true;
  IsVisibleExpandedmenu: boolean = true;
  IsVisibleUpper: boolean = true;
  IsVisibleHeader: boolean = false;
  hide: boolean = true;
    changed: boolean = false;
    Height: number = 126;
    loadManufacture: any;
    loadManufactureLength: any;
    loadManufactureMore: any;
    Batch_Id: any;
  UomId: any;
  LoginPageOpen: boolean = true;
  OtherPageOpen: boolean = false;
  RegisterForm: boolean = false;

    constructor(private formBuilder: FormBuilder, private apiIntergnService: ApiIntergnService, private router: Router, private _eref: ElementRef) {
      
    router.events.pipe(filter(e => e instanceof NavigationStart), take(1))
      .subscribe((e) => {
        var CustId = localStorage.getItem('CustId');
        var CustomerDetails = localStorage.getItem('CustomerAllDetails');
        if (CustId != null && CustomerDetails != null)
          this.apiIntergnService.CartDetails(CustId)
            .subscribe(Valdata => {
              debugger;
              if (Valdata != null && !Valdata.isError) {
                localStorage.setItem('Cart', (JSON.stringify(Valdata.data)));
                this.ngOnInit();
                this.apiIntergnService.RefreshCartEnvet();
                //var Data = localStorage.getItem('Cart');
                //this.cartItems = JSON.parse(Data);
              }
            });
      });
      
    router.events.subscribe(
       
      (event: any) => {
        
      
        //if (event instanceof NavigationStart) {
          
        //}
        if (router.url == "/" || router.url == "/Home") {
          this.IsVisible = false;
          this.IsVisibleHeader = false;
          this.Footer = true;

          this.IsVisibleOffer = true;
          this.IsVisiblemenu = true;
          this.IsVisibleExpandedmenu = false;
          //this.OpenCity = true;
          this.IsVisibleUpper = false;
        }
        else if (router.url == "/CheckOut") {
            this.IsVisibleHeader = true;
         this.IsVisible = false;
         this.IsVisibleOffer = false;
         this.Footer = false
         this.IsVisiblemenu = false;
         this.IsVisibleExpandedmenu = false;

         this.IsVisibleUpper = false;
        }
        else if (router.url == "/Payment") {
            this.IsVisibleHeader = true;
            this.IsVisible = false;
            this.IsVisibleOffer = false;
            this.Footer = false
            this.IsVisiblemenu = false;
            this.IsVisibleExpandedmenu = false;

            this.IsVisibleUpper = false;
        }

     

        else if ((router.url.includes("/Search/")) || (router.url.includes("/single/")) || (router.url.includes("/shop/")) || (router.url.includes("/MyOrder"))
          || (router.url.includes("/Account")) || (router.url.includes("/ViewAllCategory")) || (router.url.includes("/Category")) || (router.url.includes("/AboutUs")) || (router.url.includes("/ContactUs")) || (router.url.includes("/PrivacyPolicy"))
          || (router.url.includes("/OrderDetails/")) || (router.url.includes("/WishList"))) {

          this.IsVisible = true;
          this.IsVisibleOffer = true;
          this.IsVisiblemenu = true;
          this.IsVisibleExpandedmenu = true;
          //this.OpenCity = true;
          this.Footer = false
          this.IsVisibleUpper = false;
          this.IsVisibleHeader = false;
        }
        else {
          this.IsVisibleHeader = false;
          this.IsVisible = false;
          this.IsVisibleOffer = false;
          this.Footer = false
          this.IsVisiblemenu = false;
          this.IsVisibleExpandedmenu = false;
          this.IsVisibleUpper = false;
        }
        //if (event instanceof NavigationStart) {

        //}
        // start loading pages
        
      });
  }



  @HostListener('document:click', ['$event'])
  public onClick(event): void {
   
    if (!event.target.className.includes('Fake')) {
      // or some similar check
      if (this.pnlAccount == true) {
        this.pnlAccount = false;
      }
      if (this.OpenCity == true) {
        this.OpenCity = false;
      }
    }

    if (!event.target.className.includes('Fakedata')) {
      // or some similar check
      if (this.listShow == true) {
        this.listShow = false;
      }

    }

  }

  public OpenSingleForm(itemid) {}

@Output() CheckItemQTYEvent: EventEmitter < any > = new EventEmitter();
 
public CheckItemQty(_TopServerdata, objectName): any {
  debugger;
  var Data = localStorage.getItem('Cart');
  var LocalStorageitm = JSON.parse(Data);
  if (_TopServerdata != null) {

    if (Data != null) {
      for (var j = 0; j < _TopServerdata.length; j++) {
        if (objectName == 'TopSaver') {
          for (var i = 0; i < LocalStorageitm.length; i++) {
            if (_TopServerdata[j].item_Id == LocalStorageitm[i].item_Id && _TopServerdata[j].batch_id == LocalStorageitm[i].batch_id ) {
              _TopServerdata[j].qty = LocalStorageitm[i].qty;
            }
          }
        }
        else if (objectName == 'TopOffers') {
   
          for (var k = 0; k < LocalStorageitm.length; k++) {
            if (_TopServerdata[j].item_Id == LocalStorageitm[k].item_Id && _TopServerdata[j].batch_id == LocalStorageitm[k].batch_id ) {
              _TopServerdata[j].qty = LocalStorageitm[k].qty;
            }
          }
        }
      }


    }
  }
  return _TopServerdata;
}

  ngAfterViewInit() {
    //this.CityName.nativeElement.focus();
      this.listShow = false;
      this.Search(this.filter);
  }

  OpenSubCategory() {
    debugger;
    this.listShow = !this.listShow;
  }
  secoudrow() {
    this.pnltable = true;
    this.pnl1row = false;
  }
  SideNavbar() {

    this.sidebarOpen = !this.sidebarOpen;
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  private _opened: boolean = false;

  private _toggleSidebar() {
    this._opened = !this._opened;
  }

  OPenCityLOcation() {
    this.OpenCity = !this.OpenCity;
    this.pnltable = false;
    this.pnl1row = true;
  }


  OpenShopForm() {
    this.OpenCity = false;
    /// this.router.navigate(['./shop/' + this.catid]);
  }
  MyProfile() {

  }
  OpenCityDiv() {

    //this.OpenCity = !this.OpenCity;
    this.pnl1row = true;
    //this.CityName.nativeElement.focus();
  }
  imagesofSection2 = [
    {
      text: "Fruits & Vegetables",
      items: "150 Items",
      image: "../assets/img/small/1.jpg"
    },
    {
      text: "Grocery & Staples",
      items: "95 Items",
      image: "../assets/img/small/2.jpg"
    },
    {
      text: "Beverages",
      items: "65 Items",
      image: "../assets/img/small/3.jpg"
    },
    {
      text: "Home & Kitchen",
      items: "965 Items",
      image: "../assets/img/small/4.jpg"
    },
    {
      text: "Furnishing & Home Needs",
      items: "125 Items",
      image: "../assets/img/small/5.jpg"
    },
    {
      text: "Beverages",
      items: "65 Items",
      image: "../assets/img/small/3.jpg"
    },




  ]

  carouselOptionsForSection6 = {
    margin: 15,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
        dots: false
      },
      600: {
        items: 2,
        nav: false,
        dots: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }



    GotoViewAllCategoryPage()
    {
      this.hide = false;
      this.router.navigate(['./ViewAllCategory']);
    }



  OpenModelOfSearch(event) {
    debugger;
    //var Value = " ";
    // this.SearchProducts(Value);
    event.srcElement.blur();
    event.preventDefault();
    //if (this.searchText == null || this.searchText == undefined)
    //{
    //  if (this.TopOffers != null)
    //  this.Products = this.TopOffers;
    //  this.Productslength = this.Products.length;
    //}
    
    this.SearchForm.show();
    this.SearchBar = false;
  }

  modelChanged(Value)
  {
    debugger;
    if (Value == '') {
      this.Products = [];
      this.Productslength = this.Products.length;

    }
    else
    {
      this.SearchProducts(Value);
    }


  }

  Categories: any;
  Productslength: number = 0;
  Products: any;
  CategoriesName: string;
  public SearchProducts(Value) {
    debugger;
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
      Search: Value,
    }


    this.apiIntergnService.GetMasterSearch(obj).subscribe((data) => {
      debugger;
      if (!data) {
        this.Products = [];
        this.Productslength = this.Products.length;
        this.Products.push({ ProductName: "No Item Found." })
        //this.SearchErrorMassaageg = "No data found";

      }
      else {

        if (!data.isError)
        {
          debugger;
          if (data.data)
          {
            console.log("get master search in app", data.data);
            this.Products = data.data;
            this.Productslength = this.Products.length;

            //this.productLength = this.Products.length;
            //this.ItemsCount = this.Products.length;
            //this.CategoriesName = data.Data.Categories[0].Category_DisplayName;



          }
          else
          {
            alert("No data found");
          }
        }
        else {

          this.Products = [];
        }
      }


    });
  }


  public LoadManufacture() {

    debugger;
    this.apiIntergnService.GetManufacture().subscribe((data) => {
      if (!data.isError) {
        
        this.loadManufacture = data.data;
        this.loadManufactureLength = this.loadManufacture.length;
      }
    });
  }
  public LoadManufactureMore() {

    debugger;
    this.apiIntergnService.GetManufactureMore().subscribe((data) => {
      if (!data.isError) {

        this.loadManufactureMore = data.data;
      }
    });
  }
  public LoadProductsByManufacture(Item) {

    debugger;
    var data = {
      "ManufactureId": Item.manufactureId,
      "customrid" :localStorage.getItem('CustId')
    }
    this.apiIntergnService.GetProductByManufactureId(data).subscribe((data) => {
      if (!data.isError) {
        this.loadManufacture = data.data;
        this.loadManufactureLength = this.loadManufacture.length;
      }
    });
  }
  






  OpenLogin() {

    this.pnlLogin = true;
    this.pnlRegistration = false;
    this.submittedLogin = false;
    this.OtpMassages = '';
    this.LogiForm.reset();



  }

  LoginWithPassword() {
    debugger;

    var CustomerMobile = this.LogiForm.value.Customer_Mobile;

    if (CustomerMobile != "") {
      this.pnlLoginOtp = true;
      this.pnlLoginPassword = false;
      this.MobileErrorMassages = '';
      this.IsOTPLogin = true;
      this.apiIntergnService.ResetPassword(CustomerMobile).subscribe(response => {
        debugger;
        if (response) {

          if (!response.isError) {
            this.OtpMassages = "OTP Generated Successfully";
            //this.ForgetPaswordForm.hide();
            //this.ValidateForm.show();
            //this.MobilePjonesubmitted = false;
            //this.LogiForm.reset();
            ///this.pnlLoginPassword = true;
          }
          else {
            //var msg = "";
            //response.Message.forEach(value => {
            //  msg += value + "\n";
            //});
            //alert(msg);
            //this.OtpMassages = msg;

          }

        }

        else {

        }

      });
    }
    else {
      this.MobileErrorMassages = "Please Enter the mobile number";
    }


  }





  LoginWithOtp() {

    this.pnlLoginOtp = false;
    this.pnlLoginPassword = true;
    this.OtpMassages = '';
    this.ErrorLoginMassages = '';
    //this.LogiForm.reset();
    this.IsOTPLogin = false;
    this.addForm.value.OTP = '';

  }



  OpenRegistration() {
    this.pnlLogin = false;
    this.pnlRegistration = true;
    this.submitted = false;
    this.OtpMassages = '';
    this.addForm.reset();
  }
  CateAllData: any;
  CategoryData: any;
  subCategories: any;
  Secification: any;
  CateAllDataFilter: any[];

  CateAllData1: any;
  Category_ID1: number;
  Category_ID2: number;
  Category_ID3: number;
  Category_ID4: number;
  Category_ID5: number;
  Category_ID6: number;
  CateAllData2: any;
  CateAllData3: any;
  CateAllData4: any;
  CateAllData5: any;
  CateAllData6: any;


  public LoadCategory() {
    debugger;
    this.apiIntergnService.getAllCategories().subscribe((data) => {
      debugger;
      if (!data.isError) {
        console.log("get all categories in app", data.data);
        this.CateAllData = data.data;

      }
    });
    }

    public GetCategoriesNavigation() {
      debugger;
        this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
          if (!data.isError) {
            this.CategoryData = data.data;
                this.CategoryData.forEach(function (element) {
                  element.subcategory = false;
                //  element.subCategories = JSON.parse(element.subCategories);
                })

            console.log("get  categories navigation in app", this.CategoryData);
            }
        });
  }
  public GetSubCategoriesNavigation(CatID) {
    debugger;
    this.apiIntergnService.getSubCategories(CatID).subscribe((data) => {
      if (!data.isError) {
        this.subCategories = data.data;

        console.log("New category data", this.subCategories);

        this.CategoryData.forEach(function (element) {
          element.subcategory = false;


        })

      }
    });
  }


  
  item1: boolean;

    showList(item, index)
    {
      debugger;
       
        console.log(item);
        this.item1 = item.subcategory;
        this.CategoryData.forEach(function (element) {
            element.subcategory = false;

        })
        if (this.item1 == false) {
            this.CategoryData[index].subcategory = true;
        }
        else {
            this.CategoryData[index].subcategory = false;
        }
        if (item.subCategories == null) {
          var x = document.getElementById("navbarTexted");
            x.className = 'collapse navbar-collapse';
            this.OpenCategory(item, item.category_DisplayName)
        }

    }
    OpenCategory(catid, CateName)
    {

      debugger;
      this.hide = false;
    this.apiIntergnService.CatName = CateName;
      this.listShow = false;
        this.router.navigate(['./shop/' + CateName + '/' + catid.category_ParentID+catid.category_ID]);
      catid.BackgroundColour = "lightGrey";
      this.apiIntergnService.RefreshCartEnvet();
  }
  OpenManuFacture(Manf, Manf_Name) {

    debugger;
    this.hide = false;
    this.listShow = false;
    localStorage.setItem('Manf', JSON.stringify(Manf));
    this.router.navigate(['./shop/' + Manf_Name + '/' + "M"+ Manf_Name]);
    Manf.BackgroundColour = "lightGrey";
    this.apiIntergnService.RefreshCartEnvet();
    
  }
    OpenSubCategoryNav(catid, CateName)
    {
      debugger;
      this.hide = false;
        this.apiIntergnService.CatName = CateName;
        this.listShow = false;
        this.router.navigate(['./shop/' + CateName + '/' + catid.subCate_ParenUnderID + catid.subCate_ID]);
        //for (let myChild of this.CateAllData)
        //{
        //   myChild.BackgroundColour = "white";
        //}
      catid.BackgroundColour = "lightGrey";
      var x = document.getElementById("navbarTexted");
      x.className ='collapse navbar-collapse';
     
        
    }

    OpenSpecificCategoryNav(catid, CateName)
    {
      this.hide = false;
        this.apiIntergnService.CatName = CateName;
        this.listShow = false;
        this.router.navigate(['./shop/' + CateName + '/' + catid.subSpc_ParenUnderID +catid.subSpc_ID]);
        for (let myChild of this.CateAllData) {
            myChild.BackgroundColour = "white";
        }
       
        catid.BackgroundColour = "lightGrey";
      
    }

    public OpenAboutUsPageforCategory()
    {
      debugger;
        for (let myChild of this.CateAllData) {
            myChild.BackgroundColour = "white";
        }
        
    }

  public ChangePassword() {
    this.pnlAccount = false;
    this.submittedForget = false;

    this.ResetFinalPassword.show();


  }



  get r() { return this.ForgetPasswordForm.controls; }

  ChangedPasswordss() {
 
    this.submittedForget = true;

    // stop here if form is invalid

    if (!this.ForgetPasswordForm.valid) {


    }
    else
    {
      debugger;
      let ValidateInfos: ValidateInfo = new ValidateInfo();
      // ValidateInfos.MobileNo = this.addForm.value.MobileNo;
      ValidateInfos.OldPassword = this.ForgetPasswordForm.value.OldPassword;
      var ids = localStorage.getItem('CustId');

      ValidateInfos.Customer_ID = Number(ids)
      ValidateInfos.Password = this.ForgetPasswordForm.value.PresentPasswords;

      this.apiIntergnService.ChangePassword(ValidateInfos).subscribe(response => {
        if (response) {

          if (!response.isError) {
            alert("Password changed successfully!!")
            this.ResetFinalPassword.hide();
            this.ForgetPasswordForm.reset();
            this.submittedForget = false;
          }
          else {
            var msg = "";
            response.message.forEach(value => {
              msg += value + "\n";
            });
            alert(msg);
            this.submitted = false;
          }


          //alert("Password Changed Successfully!!")
          //this.ResetFinalPassword.hide();
        }


      });

    }
  }




  TopSaver: any;
  public LoadTopSavers() {
    debugger;
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
    }
    this.apiIntergnService.getTopServers(obj).subscribe((data) => {
      if (!data.isError) {
        console.log("get top savers in app", data.data);

        this.TopSaver = data.data;
        //this.TopSaver.push({ Qty:0 });
        this.TopSaver.forEach(function (element) {
          element.qty = 0;
        });
        //console.log(this.TopSaver);
      }
    });
  }

    homePageChanged = false;
  public quantityChanged(index, type, item, objectName) {
    debugger;
    
      var prod = new ProductInfoModal();
    this.Batch_Id = item.batch_id;
    this.UomId = item.uomId;
    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));

      if (type == 'Increament') {
        this.cartItems[index].qty += 1;
      }
      else {
        this.cartItems[index].qty -= 1;
        prod.isDecreaseQty = true;
    }
    if (item.imagePath == undefined) {
      item.imagePath = item.path;
    }
    prod.productName = item.productName;
    prod.productPrice = item.productPrice;
    prod.batch_id = item.batch_id;
    prod.qty = this.cartItems[index].qty;
    prod.Batch_qty = this.cartItems[index].qty;
    prod.batchId = item.batch_id;
    prod.itemId = item.item_Id;
    prod.uomId = item.uomId;
    prod.unit = item.unit;
    prod.imagePath = item.imagePath;
    prod.weight = item.weight;
    item.itemId = item.item_Id;
    item.batchId = item.batch_id;
      
    this.cartItems.forEach((element, index) => {
      if (element.item_Id == prod.itemId && (element.batchId || element.batch_id) == prod.batchId) {
        element.qty = prod.qty;
        element.Batch_qty = prod.Batch_qty
        if (prod.qty == 0) {
          const index = this.cartItems.findIndex(list => list.item_Id == prod.itemId);//Find the index of stored id
          this.cartItems.splice(index, 1)
        }
      }

    });

      this.cartItemsCal = this.cartItems;
      this.LocalStrogedata = this.cartItemsCal;
      this.TotalAmount = 0;
      var t = 0;
      for (let i = 0; i < this.cartItemsCal.length; i++) {
        t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

      }
      var sum = 0;
      this.TotalAmount = t;
      this.TotalItem = 0;
      t = 0;
      if (this.cartItemsCal) {
        for (let i = 0; i < this.cartItemsCal.length; i++) {
          t += this.cartItemsCal[i].qty;
        
        }
      }
      sum = this.cartItemsCal.length;
      this.TotalItem = sum;

    localStorage.setItem('Cart', JSON.stringify(this.cartItemsCal));
    var obj = {
      Index: index, Type: type, Item: item, ObjectName: objectName
      };
    this.homePageChanged = true;

    this.apiIntergnService.QtyChangeEvent(obj);
    this.cartItemslength = this.cartItems.length;
    
     // this.apiIntergnService.RefreshCartEnvet();
    
  }
  city = [
    {
      text: "Kolkata"
    },
    {
      text: "New Delhi"
    },
    {
      text: "Bengaluru"
    },
    {
      text: "Hyderabad"
    },
  ]
  location(text) {

 
    this.CityNameLocation = text;
    //this.OpenShopForm();
    this.OpenCity = false;
    this.pnl1row = false;

  }
  imagesofsection2 = [
    {

      image: "//webcdn.grofers.com/images/cities/kolkata-f960233.png",
      text: "Kolkata"

    },
    {

      image: "//webcdn.grofers.com/images/cities/delhi-8cb9a0a.png",
      text: "New Delhi"
    },
    {

      image: "//webcdn.grofers.com/images/cities/bengaluru-78062ed.png",
      text: "Bengaluru"
    },
    {

      image: "//webcdn.grofers.com/images/cities/hyderabad-bafc7e9.png",
      text: "Hyderabad"
    },





  ]
  TopOffers: any;


  //------------Add To CartMethod-----------

  AddToCart(ProductsInfo: ProductInfoModal) {
    debugger;
    let CartInfo: Cart = new Cart();
    CartInfo.AddedInCartDate = new Date();
    CartInfo.ItemId = ProductsInfo.item_Id;
    CartInfo.ProductName = ProductsInfo.productName;
    CartInfo.ProductPrice = ProductsInfo.productPrice;
    CartInfo.BatchId = ProductsInfo.batch_id;
    CartInfo.QTY = 1;
    CartInfo.IsDecreaseQty = ProductsInfo.isDecreaseQty;
    CartInfo.TotalProductPrice = (CartInfo.ProductPrice) * (CartInfo.QTY);
    CartInfo.Weight = ProductsInfo.weight;
    CartInfo.Unit = ProductsInfo.unit;
    CartInfo.UomId = ProductsInfo.uomId;

    var id = localStorage.getItem('CustId');
    CartInfo.UserId = Number(id);
    this.apiIntergnService.AddCart(CartInfo)
      .subscribe(response => {
        if (response) {
          this.getTotalAmounts();
        }
        else {
          alert("Something went wrong please try again")
        }
      });

  }

  get f() { return this.addForm.controls; }

  CreateRegistrationForm() {
    debugger;
    this.submitted = true;
    // stop here if form is invalid
    if (!this.addForm.valid) {
      return;
    }
    else {
      this.apiIntergnService.Registration(this.addForm.value).subscribe(response =>
      {
        debugger;
        if (response) {
          if (!response.isError) {
            localStorage.setItem('CustomerAllDetails', (JSON.stringify(response)));
            localStorage.setItem('CustId', response.data.customer_ID.toString());
            this.cartItems = JSON.parse(this.StorageData);
            var AddPreCartData = this.cartItems;
            var prod = new ProductInfoModal();
            prod.UserId = Number(localStorage.getItem('CustId'));
            if (AddPreCartData != null || AddPreCartData != undefined) {
              AddPreCartData.forEach((item, index) => {
                item.item_Id = item.item_Id;
                item.productName = item.productName;
                item.qty = item.qty;
                item.isDecreaseQty = false
                item.productPrice = item.productPrice;
                item.batch_id = item.batch_id;
                item.batchId = item.batch_id;
                item.weight = item.weight;
                item.unit_Name = item.unit_Name;
                item.customerID = Number(localStorage.getItem('CustId'));
                item.userId = Number(localStorage.getItem('CustId'));
                item.uomId = item.uomId
                item.productStatus = "Pending",
                item.totalProductPrice = item.productPrice * item.qty,
                item.sizeDetailsId = 0,
                item.sizeParentId = 0,
                item.size = "",
                item.unit = item.unit,
                item.weight = item.weight,
                item.imagePath = item.imagePath,
                item.categoryId = item.categoryId,
                item.promo_ID = 0,
                item.employeeId = 0,
                item.conversionId = item.conversionId,
                item.saveAmt = item.saveAmt

              })
            }

            if (prod.UserId > 0 && AddPreCartData != null) {

                  var s = JSON.stringify(AddPreCartData);
                  console.log(s);
                  this.apiIntergnService.AddPreCart(AddPreCartData)
                      .subscribe(Val => {

                          if (!Val.isError) {

                          }
                      });
              }
           if (this.OpenLoginForms == "OpenLoginForm")
           {
                  this.router.navigate(['./CheckOut']);
           }


            var msg = "";
            response.message.forEach(value => {
              msg += value + "\n";
            });
            alert(msg);
           // this.LoginForm.hide();
            this.ClearMethod();
              this.ngOnInit();
          }


          else
          {
            var msg = "";
            response.Message.forEach(value =>
            {
              msg += value + "\n";
            });

            this.ErrorRegistrationMassages = msg;

            //alert(msg.toUpperCase());
          }
          
          
        }
        else {
          // alert("Something went wrong please try again");
        }
      });
    }

  }

  public keyDownFunction(event) {
    debugger;
    if (event.keyCode == 40) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 38) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input"))
      const index =
        (inputs.indexOf(document.activeElement) - 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 13) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }

  }

  public ClearMethod() {
    this.addForm = this.formBuilder.group({
      'Customer_Mobile': [],
      'Password': [],
      'Customer_Name': [],
      'Customer_Pincode': [],
      'Customer_EmailID': [],
      'confirmPassword': [],
      'Customer_BirthdayDate': [''],
      'Customer_Address1': [],
      'Customer_Landmark': [],
      'Customer_City': [],
      'Customer_State': [],
      'Gender': [''],
      'selectedCountriesControl': ['']
    });
  }


  SignUpModalOpen() {
    debugger;
    this.SignUPForm.show();
  }

  HidetheSucessMsg() {
    debugger;
    this.LoginSuccessMSG.hide();
  }

  SignUpModalHide() {
    debugger;
    this.SignUPForm.hide();
  }

  LoginModalOpen() {
    debugger;
      this.ErrorLoginMassages = "";
      this.MobileErrorMassages = "";
      this.OtpMassages = "";
      this.LogiForm.value.Customer_Mobile = "";
      this.LogiForm.value.Password = "";
      this.LogiForm.patchValue(this.LogiForm.value);
      this.pnlLogin = true;
    this.pnlRegistration = false;
    this.RegisterForm = true;
    this.LoginForm.show();
  }


  OpenCartModel() {
    this.CartForm.show();
  }

  CloseCartModel() {
   this.apiIntergnService.RefreshCartEnvet();
    this.CartForm.hide();
  }

  LogoutLocalStorage = [];

  LogOut() {
    debugger;
    this.pnlAccount = false;
    this.StorageData = localStorage.getItem('Cart');
    this.LogoutLocalStorage = JSON.parse(this.StorageData);
    localStorage.clear();
    /*    this.router.navigateByUrl('/Home');*/
    this.UserId = 0;
    this.RegisterForm = true;
    //    
    //localStorage.removeItem('Cart');
    this.TotalItem = 0;

    //this.router.navigate(['./Home']);
    this.pnlLoginPassword = true;
    this.pnlLoginOtp = false;
    this.IsOTPLogin = false;
    this.router.navigate(['./Home']);
  //  this.ngOnInit();
  }


  LoginModalHide() {
    debugger;
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }

  ClearData() {
    debugger;
    this.cartItems = null;
  }


  UserId: number = 0;
  cartItemslength: number = 0;

  CityAddressDataLength: number = 0;
  CityAddressData: any;
  public LoadCityAddress() {
    debugger;
    var ids = localStorage.getItem('CustId');
    if (ids != null) {
      this.apiIntergnService.getAllCustomerAddress(ids).subscribe((data) => {
        if (!data.isError) {
          console.log("get all customer add in app", data.data);

          this.CityAddressData = data.data;
          this.CityAddressDataLength = this.CityAddressData.length;
        }
      });
    }
  }

  NumberPattern = "^[0-9]*$";
  ngOnInit() {

    this.UserId = Number(localStorage.getItem('CustId'));


   

    if (this.apiIntergnService.loaderVar == undefined) {
      this.apiIntergnService.invokeLoaderEnvet.subscribe((Obj: any) => {
        this.IsVisible = false;
        this.IsVisibleOffer = true;
        this.IsVisiblemenu = true;
        this.IsVisibleExpandedmenu = true;
        this.IsVisibleHeader = false;
        //this.OpenCity = true;
        this.IsVisibleUpper = true;
        this.Footer = false
      });
    }

    // this.LoadCityAddress();
    // this.LoadManufacture();
    //this.LoadManufactureMore();

    this.addForm = this.formBuilder.group({
      Customer_Mobile: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.NumberPattern)]],
    //  Password: ['', [Validators.required, Validators.minLength(6)]],
    //  confirmPassword: ['', Validators.required],
      Customer_Name: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
     // Customer_EmailID: ['', [Validators.required, Validators.email]],
      MobileNo: [],
      OTP: [],


    },
    //  { validator: MustMatch('Password', 'confirmPassword') }

    );

    this.LogiForm = this.formBuilder.group({

      Customer_Mobile: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.NumberPattern)]],

   //   Password: ['', [Validators.required, Validators.minLength(6)]],
    },

    );

    this.ForgetPasswordForm = this.formBuilder.group({

      PresentPasswords: ['', [Validators.required, Validators.minLength(6)]],
      confirmPresentPasswords: ['', Validators.required],
      OldPassword: ['', [Validators.required, Validators.minLength(6)]],
    },
      { validator: MustMatch('PresentPasswords', 'confirmPresentPasswords') }

    );


    this.ChangedPasswordForm = this.formBuilder.group({

      ChangedPassword: ['', [Validators.required, Validators.minLength(6)]],
      ChangedconfirmPassword: ['', Validators.required],

    },
      { validator: MustMatch('ChangedPassword', 'ChangedconfirmPassword') }

    );


    this.OTPForm = this.formBuilder.group({
      OTP: ['', Validators.required],

    },
    );


    this.CutomerMobilePhonForm = this.formBuilder.group({
      Customer_Mobile: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.NumberPattern)]]

    },
    );


    (function () {

      $("#cart").on("click", function () {
        $(".shoppingcart").fadeToggle("fast");
      });

    })();

    this.ErrorLoginMassages = '';
    this.ErrorRegistrationMassages = '';
    this.OpenLoginForms = '';
    this.MobileErrorMassages = '';
    this.OtpMassages = '';

    if (this.UserId < 0 || this.UserId == null || this.UserId == 0) {
      this.RegisterForm = true
    }
    else {
      this.RegisterForm = false


      // this.LoadTopSavers();
      // this.GetCategoriesNavigation();
      this.CustomerAddress();

      //this.OpenCartModel();




      var t = 0;
      //this.LoadCategory();
      this.StorageData = localStorage.getItem('Cart');
      this.cartItems = JSON.parse(this.StorageData);

      if (this.cartItems) {
        this.cartItemslength = this.cartItems.length;
        console.log("cart item length in app", this.cartItemslength)
        for (let i = 0; i < this.cartItems.length; i++) {
          t += this.cartItems[i].qty;
          //t += length++;
        }
        var sum;
        sum = this.cartItems.length;
        this.TotalItem = sum;
      }

      else {
        this.TotalItem = 0;
      }

      var cartOpen = false;
      var numberOfProducts = 0;
      var cartOpen = false;
      var numberOfProducts = 0;

      $('body').on('click', '.js-toggle-cart', toggleCart);
      //$('body').on('click', '.js-add-product', addProduct);
      //$('body').on('click', '.js-remove-product', removeProduct);

      function toggleCart(e) {
        e.preventDefault();
        if (cartOpen) {
          closeCart();
          return;
        }
        openCart();
      }

      function openCart() {

        cartOpen = true;
        $('body').addClass('open');
      }

      function closeCart() {
        cartOpen = false;
        $('body').removeClass('open');
      }



      const rangeInput = document.querySelector('input');
      const rangeInputStep = Number(rangeInput.step);
      var currentRangeValue = Number(rangeInput.value);
      const output = document.querySelector('output');
      const rangeText = document.querySelector('.quantity-widget__text');

      function updateRangeText() {
        if (currentRangeValue === 0) {
          rangeText.innerHTML = "Add to Cart"
        }
        else {
          rangeText.innerHTML = rangeInput.value + ' in cart'
        }
      }
    }
   

  }


  CustomerInfo: any; CustomerName: string; CustomerPhoneNo: string; Customer_EmailID: any;
  CustomerAddress() {
    debugger;
    var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));

    if (CustomerDetails != null) {
      this.CustomerInfo = JSON.parse(CustomerDetails);
      this.CustomerName = this.CustomerInfo.data.customer_Name;
      this.CustomerPhoneNo = this.CustomerInfo.data.customer_Mobile;
      this.Customer_EmailID = this.CustomerInfo.data.customer_EmailID;
    }

    //return this.Address;
  }





  getTotalAmounts() {
    debugger;
    this.GetAddToCardDetails((data) => {
      this.cartItems = data;
      this.cartItemsCal = this.cartItems;

      this.TotalAmount = 0;
      var t = 0;
      if (this.cartItemsCal != null) {

        for (let i = 0; i < this.cartItemsCal.length; i++) {
          t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

        }
        var sum = 0;
        this.TotalAmount = t;
        this.TotalItem = 0;
        t = 0;
        if (this.cartItemsCal) {
          for (let i = 0; i < this.cartItemsCal.length; i++) {
            t += this.cartItemsCal[i].qty;
            //t += length++;
          }
        }
        sum = this.cartItemsCal.length;
        this.TotalItem = sum;
      }
      });
 

    return this.TotalAmount;
  }

  StorageData: string;
  async MyCart() {
    debugger;
    var id = Number(localStorage.getItem('CustId'));
    if (id != 0) {
      await this.apiIntergnService.CartDetails(id)
        .subscribe(data => {
          if (data) {
            this.cartItems = data.data;
            this.cartItems.forEach(element => {
              element.Batch_qty = element.qty;
              element.SizeNo = element.sizeNo
              element.SizeId = element.sizeId;
              element.batchDetails = JSON.parse(element.batchDetails);
            })
            localStorage.setItem('Cart', JSON.stringify(this.cartItems));
           // this.apiIntergnService.RefreshCartEnvet();
            this.CalculateAmt();
          }
        }
        );
    }
    else {
      this.CalculateAmt();
    }

  }

  CalculateAmt() {

    debugger;
    this.StorageData = localStorage.getItem('Cart');
    this.cartItems = JSON.parse(this.StorageData);
    this.cartItemslength = 0;


    if (this.cartItems) {

      this.cartItemsCal = this.cartItems;
      this.cartItemslength = this.cartItems.length;
      this.TotalAmount = 0;
      var t = 0;
      for (let i = 0; i < this.cartItemsCal.length; i++) {
        t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].Batch_qty;

      }
      var sum = 0;
      this.TotalAmount = t;
      this.TotalItem = 0;
      t = 0;
      if (this.cartItemsCal) {
        for (let i = 0; i < this.cartItemsCal.length; i++) {
          t += this.cartItemsCal[i].Batch_qty;
          //t += length++;
        }
      }
      sum = this.cartItemsCal.length;
      this.TotalItem = sum;
      this.OpenCartModel();
    }



  }

  myFunction() {
 
    var x = document.getElementById("myDIV");
    if (x.style.display === "none")
    {
      x.style.display = "block";

    }
    else if (x.style.display === "")
    {
      x.style.display = "block";
    }
    else if (x.style.display === "block")
    {
      x.style.display = "none";
    }
  }


  clearCartItem(item, index) {
    debugger;
    var prod = new ProductInfoModal();
    //if (this.UserId == 0)
    //{
    this.cartItems.splice(index, 1)
    //localStorage["Cart"] = this.cartItems
    localStorage.setItem('Cart', (JSON.stringify(this.cartItems)));
    this.cartItemslength = this.cartItems.length;
    this.TotalItem = this.cartItems.length;
    var t = 0;
    for (let i = 0; i < this.cartItemsCal.length; i++) {
      t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

    }
    var sum = 0;
    this.TotalAmount = t;
    this.apiIntergnService.CartItemDeleteEvent(item);
    

    this.apiIntergnService.CheckoutProductEvent();


    if (item.imagePath == undefined) {
      item.imagePath = item.path
    }
    prod.itemId = item.item_Id;
    prod.productName = item.productName;
    prod.qty = item.qty;
    prod.isDecreaseQty = true
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.uomId = item.uomId
    prod.imagePath = item.imagePath;


    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));
    if (prod.UserId > 0) {
 
      var PreCartData = prod;
      this.apiIntergnService.DeleteCartItems(PreCartData)
        .subscribe(Val => {
  
          if (!Val.isError) {

          }
        });
    }
  };

    //ngAfterContentChecked() {

    //    debugger;
    //    this.Search(this.filter);

    //}
    

    filter: any;
    SearchItem: string = '';
    public Search(term)
    {
      debugger;
      if (term == undefined) {
        term = "";
      }
        if (term)
        {
            this.SearchItem = term;
            this.router.navigate(['./Search/' + this.SearchItem]);
            console.log(window.location.href);
            console.log(this.router.url);
            
            var withoutLastChunk = window.location.href.slice(0, window.location.href.lastIndexOf("/"));
            //if (window.location.href == withoutLastChunk + '/' + this.SearchItem)
            //{
            //    window.open(window.location.href);

            //}
            //else {
            //    this.SearchItem = term;
            //    this.router.navigate(['./Search/' + this.SearchItem]);
            //}
            
            //location.reload();
            //this.ngOnInit();
          this.SearchForm.hide();
          this.SearchBar = true;
        }
  }


  public CloseSearchEngine() {
    debugger;
    this.SearchForm.hide();
    this.SearchBar = true;
  }

  //---------AddToCartDetails--------------

  GetAddToCardDetails(callback) {
    debugger;
    var id = Number(localStorage.getItem('CustId'));
    this.apiIntergnService.CartDetails(id)
      .subscribe(data => {
        if (data) {
          if (callback) {

            callback(data.data);

          }

        }

        this.cartItems = data.data;
        localStorage.setItem('Cart', JSON.stringify(this.cartItems));

      }
      );

  }

  OpenHomePage() {

   // $('body').on('click', '.js-toggle-cart', toggleCart);
    this.router.navigate(['./Home']);
    
  }

  AccountForm() {

    this.pnlAccount = false;
    this.router.navigate(['./Account']);
    window.scrollTo(0, 0)

  }
  OpenWishListForm() {

    this.pnlAccount = false;
    this.router.navigate(['./WishList']);
    window.scrollTo(0, 0)

  }

  MyOrderForm() {
    debugger;
    this.pnlAccount = false;
    this.router.navigate(['./MyOrder']);
    window.scrollTo(0, 0)
  }

  OpenTheAccount() {
    this.pnlAccount = true;
  }
  CheckOut() {
    debugger;
    var SessionID = Number(localStorage.getItem('CustId'));
    if (SessionID == 0) {
      //this.router.navigate(['./Home']);
      this.LoginModalOpen();
      this.OpenLoginForms = "OpenLoginForm";
      window.scrollTo(0, 0)
      this.CloseCartModel();
    }
    else {
      this.CartForm.hide();
      this.router.navigate(['./CheckOut']);
      window.scrollTo(0, 0);
     
    }

  }

  OpenAboutUsPage() {
    this.router.navigate(['./AboutUs']);
  }

  OpenContactUsPage() {
    this.router.navigate(['./ContactUs']);
  }
  OpenCategoryForm() {
    this.router.navigate(['./Category']);
  }

  Home() {
    this.router.navigate(['./Home']);
  }
  
  public ForgetPasword() {
   // this.LoginForm.hide();
    this.MobilePjonesubmitted = false;
    this.ForgetPaswordForm.show();

  }


  //----------ResetPassword-----------------
  get a() { return this.CutomerMobilePhonForm.controls; }
  public ResetPassword() {
    debugger;
    this.MobilePjonesubmitted = true;
    var CustomerMobile = this.CutomerMobilePhonForm.value.Customer_Mobile;
    localStorage.setItem('CustMobileNo', this.CutomerMobilePhonForm.value.Customer_Mobile);

    if (!this.CutomerMobilePhonForm.valid) {


    }
    else {
      debugger;
      this.apiIntergnService.ResetPassword(CustomerMobile).subscribe(response => {

        if (response) {

          if (!response.isError) {
            this.ForgetPaswordForm.hide();
            this.ValidateForm.show();
            this.MobilePjonesubmitted = false;
            this.CutomerMobilePhonForm.reset();
          }
          else {
            var msg = "";
            response.Message.forEach(value => {
              msg += value + "\n";
            });
            alert(msg);
            this.MobilePjonesubmitted = false;
          }





          //alert("OTP sent successfully !!")

        }

        else {
          //alert("Something went wrong please try again");
          // this.ForgetPaswordForm.hide();
          // this.ValidateForm.show();
        }

      });
    }
  }


  public ClearTotalAmountAndCount() {
    this.TotalAmount = 0;
    this.TotalItem = 0;
  }

  //---------ValideOTP------------
  get o() { return this.OTPForm.controls; }
  public ValiidateOTP() {
    debugger;
    this.oTPsubmitted = true;

    if (!this.OTPForm.valid) {

    }
    else {

      let ValidateInfos: ValidateInfo = new ValidateInfo();
      var custumerNo = localStorage.getItem('CustMobileNo');
      //  CartInfo.UserId = Number(id);

      ValidateInfos.MobileNo = custumerNo;
      ValidateInfos.OTP = this.OTPForm.value.OTP;

      this.apiIntergnService.ValidateOTP(ValidateInfos).subscribe(response => {
        if (response) {

          if (!response.isError) {
            localStorage.setItem('CustResponseOtpDetails', (JSON.stringify(response)));

            this.ValidateForm.hide();
            this.oTPsubmitted = false;
            /// this.addForm.setValue()
            this.ConfirmPasswordForm.show();
            this.OTPForm.reset();
          }
          else {
            var msg = "";
            response.Message.forEach(value => {
              msg += value + "\n";
            });
            alert(msg);
            this.oTPsubmitted = false;
          }

          //alert("Successfully Validated!!")
        }

        else {
          //alert("Something went wrong please try again");
          //this.ResetFinalPassword.show();
        }

      });
      //}
    }

  }
  OTP: string; Mobile: string;
  get c() { return this.ChangedPasswordForm.controls; }
  ChangedPassword() {
    debugger;
    this.submittedChaned = true;
    var CustomerDetails = (localStorage.getItem('CustResponseOtpDetails'));
    this.CustomerInfo = JSON.parse(CustomerDetails);
    this.CustomerID = this.CustomerInfo.data.oTP_UserId;
    this.CustomerOTPID = this.CustomerInfo.data.oTP_Id;
    this.OTP = this.CustomerInfo.data.oTP_Code;
    this.Mobile = this.CustomerInfo.data.oTP_Mobile;



    if (!this.ChangedPasswordForm.valid) {

    }
    else {
      debugger;
      let ValidateInfos: ValidateInfo = new ValidateInfo();
      ValidateInfos.Password = this.ChangedPasswordForm.value.ChangedPassword;
      ValidateInfos.OTP = this.OTP;
      ValidateInfos.OTPId = this.CustomerOTPID
      ValidateInfos.MobileNo = this.Mobile;
      this.apiIntergnService.ChangePassword(ValidateInfos).subscribe(response => {
        if (response) {


          if (!response.isError) {
            alert("PASSWORD CHANGED SUCCESSFULLY");
            this.ConfirmPasswordForm.hide();
            this.submittedChaned = false;
            this.ChangedPasswordForm.reset();

          }
          else {
            var msg = "";
            response.Message.forEach(value => {
              msg += value + "\n";
            });
            alert(msg);
            this.submittedChaned = false;
          }



          //alert("Password Changed Successfully!!")
          //this.ConfirmPasswordForm.hide();

        }

        //else
        //{
        //  alert("Something went wrong please try again");

        //}

      });

    }

  }


  get s() { return this.LogiForm.controls; }

  CreateLoginAccount() {
    debugger;
    this.submittedLogin = true;
    this.OtpMassages = '';
    // stop here if form is invalid
    if (!this.LogiForm.valid) {

    }

    else {
      debugger;
        this.LogiForm.value.IsOTPLogin = this.IsOTPLogin;
      var s = JSON.stringify(this.LogiForm.value);
      var data = {
        "MobileNo": this.LogiForm.value.Customer_Mobile,
        "Password": this.LogiForm.value.Password
      }
      this.apiIntergnService.Login(data).subscribe(response => {
        debugger;
        if (response) {
          if (!response.isError) {
            //alert("User MobileNo.And Password Is InCorrect")
            localStorage.setItem('CustomerAllDetails', (JSON.stringify(response)));
            localStorage.setItem('CustId', response.data.customer_ID.toString());
            /*this.LoginForm.hide();*/
            this.RegisterForm = false;
            this.submittedLogin = false;
            //this.LoginSuccessMSG.show();
            //alert("Login successfully !! ");
            //this.ClearMethod();
            this.addForm.reset();
            this.StorageData = localStorage.getItem('Cart');
            if (response.data != null) {
             
              if (response.data.customer_ID > 0) {
                if (this.StorageData != '[]' && this.StorageData != 'null' && this.StorageData != null) {
                  if (this.StorageData.length >2 ) {
                    var json = JSON.parse(this.StorageData);
                    json.forEach(values => {
                     values.UserId = response.data.customer_ID;
                      values.batchId = values.batch_id;
                      this.apiIntergnService.AddPreCart(values)
                        .subscribe(Val => {
                          if (!Val.isError) {
                            this.router.navigate(['./']);
                            window.location.reload();
                          }
                        });
                    });
                  }
                  else {
                    this.apiIntergnService.CartDetails(response.data.customer_ID)
                      .subscribe(Valdata => {
                      
                        if (!Valdata.isError) {
                          
                        //  this.cartItems = Valdata.Data;
                          localStorage.setItem('Cart', (JSON.stringify(Valdata.data)));
                          //this.cartItems.forEach(val => {
                          //  this.cartItems = data;
                          //});
                          this.router.navigate(['./']);
                          window.location.reload();
                        }
                      });
                  }
                }
                else {
                  debugger;
                  this.apiIntergnService.CartDetails(response.data.customer_ID)
                    .subscribe(Valdata => {
                     
                      if (!Valdata.isError) {
                        console.log("get CartDetails in app", Valdata.data);

                        this.cartItems = Valdata.data;
                        localStorage.setItem('Cart', (JSON.stringify(Valdata.data)));
                        //this.cartItems.forEach(val => {
                        //  this.cartItems = data;
                        //});
                        this.router.navigate(['./Home']);
                        window.location.reload();
                      }
                    });
                }
              }
            }

            if (this.OpenLoginForms == "OpenLoginForm") {
              this.router.navigate(['./CheckOut']);
            }

            this.ngOnInit();
          }
          else
          {

            //var msg = "";
            //response.message.forEach(value => {
            //  msg += value + "\n";
            //});
            //this.ErrorLoginMassages= msg;
            //alert(msg.toUpperCase());
            alert("Invalid Username or Password. Please Try Again With Correct Information");
            this.submittedLogin = false;
         
            this.OtpMassages = '';
          }

        }
        
        //else {
        //  //alert("Something went wrong please try again");
        //}

      });
    }

  }



  HideLogin() {
    this.RegisterForm = false;
  }
}
