import { Component, OnInit, ViewChild, ViewEncapsulation, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalDirective } from 'ngx-bootstrap';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { delay } from 'q';
import { ProductInfoModal } from '../Models/product-info-modal';
import { Cart } from '../Models/cart';
import { AppComponent } from '../app.component';
import { config } from 'rxjs';
import { FavouriteModel } from '../Models/favourite-model';
import { collectExternalReferences } from '@angular/compiler';
import { async } from 'rxjs/internal/scheduler/async';
declare var $: any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
 
})
export class HomePageComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  @Input() LocalStrageDetails: EventEmitter<any> = new EventEmitter<any>();;
  cartItems: any[];
  Allcatid: any;
  cartItemsCal: any;
  TotalAmount: number; TotalItem: number;
  p: number = 1;
  myOptions = {
    'placement': 'right',
    'show-delay': 500,
    'theme': 'light'
    
  }
  myOptions1 = {
    'placement': 'left',
    'show-delay': 500,
    'theme': 'light'

  }
  constructor(private router: Router, private route: ActivatedRoute,  private apiIntergnService: ApiIntergnService, private appComp: AppComponent) { }


  UomId: any;
  BatchId: any;
  firstLoad: boolean = false;
  CustomerId: number = 0;
  CategoryData: any;
  HomePage: boolean = true;




  ngOnInit() {
    debugger;
  
    this.HomePage = this.appComp.RegisterForm;
    
    this.CustomerId = Number(localStorage.getItem('CustId'));
    //if (this.CustomerId < 0 || this.CustomerId == null || this.CustomerId == 0) {
    //  this.appComp.LoginModalOpen();
    //}
      if (this.apiIntergnService.subsVar == undefined) {
          this.apiIntergnService.invokeQtyChange.subscribe((Obj: any) => {
              debugger;
              if (Obj != null && this.appComp.homePageChanged) {
                  this.quantityChanged(Obj.Index, Obj.Type, Obj.Item, Obj.ObjectName);
                this.appComp.homePageChanged = false;
                return;
              }
              else if (Obj != null && !this.appComp.changed ) {
                this.quantityChanged(Obj.Index, Obj.Type, Obj.Item, Obj.ObjectName);
                this.appComp.homePageChanged = false;
                this.appComp.changed = true;
                return;
              }
              else {
              //  this.appComp.changed = false;
                this.appComp.homePageChanged = false;
                
                return;
            }
            
          });
    }

    if (this.apiIntergnService.subsRefreshCartVar == undefined) {
      this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {
        this.appComp.changed = true;
        this.ngOnInit();
        
      });
    }

    if (this.apiIntergnService.subsVarCart == undefined) {
      this.apiIntergnService.invokeCartItemDeleteEvent.subscribe((Obj: any) => {
        debugger;
        var TopOfferIndex = this.TopOffers.findIndex(x => x.item_Id == Obj.item_Id);
        var TopSaverIndex = this.TopSaver.findIndex(x => x.item_Id == Obj.item_Id);
        if (TopSaverIndex != null && TopSaverIndex != -1) {
          this.TopSaver[TopSaverIndex].qty = 0;
        }
        if (TopOfferIndex != null && TopOfferIndex != -1) {
          this.TopOffers[TopOfferIndex].qty = 0;
        }
        this.StorageData = localStorage.getItem('Cart');
        this.result99 = JSON.parse(this.StorageData);
      });
    }

    var data = this.LocalStrageDetails;
    console.log(data);

    if (!this.HomePage) {
      this.firstLoad = true;
      this.LoadCategory();
      // this.LoadTopSavers();
      // this.LoadTopOffers();
      //  this.GetCategoriesNavigation();S
      this.StorageData = localStorage.getItem('Cart');
      if (localStorage.getItem('Cart') != null)
        this.result99 = JSON.parse(this.StorageData);

    }
  }


  GetCatNavAPI: boolean = true;
  public GetCategoriesNavigation() {
    debugger;
    if (this.GetCatNavAPI == true) {
      this.GetCatNavAPI = false;
      this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
        this.GetCatNavAPI = true;
        if (!data.isError) {
          this.CategoryData = data.data;
          this.CategoryData.forEach(function (element) {
            element.subcategory = false;
            //element.subCategories = JSON.parse(element.subCategories);
          })

          console.log("get  categories navigation in app", this.CategoryData);
        }
      });
    }
  }

  hide: boolean;
  listShow: boolean;

  OpenCategory(catid, CateName) {

    debugger;
    this.hide = false;
    this.apiIntergnService.CatName = CateName;
    this.listShow = false;
    this.router.navigate(['./shop/' + CateName + '/' + catid.category_ParentID + catid.category_ID]);
    catid.BackgroundColour = "lightGrey";
    this.apiIntergnService.RefreshCartEnvet();

  }



  public CheckItemQty() {
 
    var Data = localStorage.getItem('Cart');
    var LocalStorageitm = JSON.parse(Data);
    for (var i = 0; i < LocalStorageitm.length; i++) {

      for (var j = 0; j < this.TopSaver.length; j++) {
        if (this.TopSaver[j].item_Id == LocalStorageitm[i].item_Id) {
          this.TopSaver[j].qty = LocalStorageitm[i].qty;
        }
      }
        for (var k = 0; k < this.TopOffers.length; k++) {
          if (this.TopOffers[k].item_Id == LocalStorageitm[i].itemId) {
            this.TopOffers[k].qty = LocalStorageitm[i].qty;
          }
      }
    }
  }

  OpenSingleForm(ItemID)
  {
    this.router.navigate(['./single/' + ItemID]);
    window.scrollTo(0, 0)
  }

  ViewAllCategory()
  {
    this.Allcatid = 0;
    this.router.navigate(['./shop/All Categories/' + this.Allcatid ]);
    window.scrollTo(0, 0)
  }

  OpenShopForm(catid, CatName)
  {
    this.apiIntergnService.CatName = CatName;
    this.router.navigate(['./shop/' + CatName +'/'+ catid]);
    window.scrollTo(0, 0)
  }
  CateData: any; CatId: any; CatDisplayName: any;
  LoadCategoryAPI: boolean = true;
  public LoadCategory() {

    if (this.LoadCategoryAPI == true) {
      this.LoadCategoryAPI = false;
      this.apiIntergnService.getAllCategories().subscribe((data) => {
        this.LoadCategoryAPI = true;
        if (!data.isError) {
          console.log("get all categories in account", data.data);
          this.CateData = data.data;
          this.CatId = data.data[0].category_ID;
          console.log(this.CateData)
        }
        this.LoadProductByCat(data.data[0].category_ID, data.data[0].category_DisplayName);
        this.p = 1;
      });
    }
  }


  OpenBatch(Batch, mainindex, subIndex, item) {
    debugger;
    this.StorageData = localStorage.getItem('Cart');
    if (this.StorageData != null) {
      this.result2 = JSON.parse(this.StorageData);
    }
    else {
      this.result2 = [];
    }
    mainindex = this.LoadProductByCatData.findIndex(x => x.ItemId == item.ItemId && x.batch_id == item.batch_id);

    //this.LoadProductByCatData.forEach(function (element) {
    //  if (element.ItemId == item.ItemId && element.batch_id == item.batch_id) {

    //  }
    //})

    this.LoadProductByCatData[mainindex].batchDetails.forEach(function (element) {
      element.BackgroundColour = "White";
      element.color = "Black";
    })

    this.LoadProductByCatData[mainindex].batchDetails[subIndex].BackgroundColour = "#154c79";
    this.LoadProductByCatData[mainindex].batchDetails[subIndex].color = "White";


    this.LoadProductByCatData[mainindex].item_SellingPrice = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Batch_SellingPrice;
    this.LoadProductByCatData[mainindex].item_MRP = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Batch_MRPPrice;
    this.LoadProductByCatData[mainindex].isstockavilbl = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Stock ? 1 : 0;
    this.LoadProductByCatData[mainindex].OfferAmt = 0;
    this.LoadProductByCatData[mainindex].batch_id = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Batch_Id;
    this.LoadProductByCatData[mainindex].weight = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Batch_Conversion;
    this.LoadProductByCatData[mainindex].unit_Name = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Unit_Name;
    this.LoadProductByCatData[mainindex].ItemId = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Batch_ItemId;
    this.LoadProductByCatData[mainindex].item_Id = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Batch_ItemId;
    this.LoadProductByCatData[mainindex].uomId = this.LoadProductByCatData[mainindex].batchDetails[subIndex].Unit_Id;

    var indexLoadProductData = this.result2.findIndex(x => x.ItemId == Batch.Batch_ItemId || x.itemId == Batch.Batch_ItemId);
    if (indexLoadProductData != -1) {
      for (var i = 0; i < this.result2.length; i++) {
        if ((Batch.Batch_ItemId == this.result2[i].ItemId || Batch.Batch_ItemId == this.result2[i].itemId) &&
          (Batch.Batch_Id == this.result2[i].batch_id || Batch.Batch_Id == this.result2[i].batchId)) {
          this.LoadProductByCatData[mainindex].qty = this.result2[i].qty;
        }

      }
    }
    else {
      this.LoadProductByCatData[mainindex].qty = 0;
    }
    return;

  }

  LoadProductByCatData: any;

  public TopSaver: any;
  LoadTopSaverAPI: boolean = true;
  APICall: boolean = true;
  LoadProductByCatDatalength: number;


  public LoadProductByCat(CatId,Category_Name) {
    debugger;

    var obj = {
      CatId: CatId,
      BrandId: 0,
      PricelevID: 0,
      CustomerId: this.CustomerId
      //PageNo: this.p,
      //NoofItem:9
  }
 
    if (this.APICall == true) {
      this.APICall = false;
      this.firstLoad = true;
      this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {

        if  (data != null&& !data.isError ) {
          debugger;
          this.CatDisplayName = Category_Name;
          this.firstLoad = false;
          this.APICall = true;
          this.LoadProductByCatData = data.data;
          this.p = 1;
          console.log("load product data from shop page:", this.LoadProductByCatData);
          this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
          this.LoadProductByCatData.forEach(function (element) {
            element.Litemid = 0;
            element.LBatch_id = 0;
            element.ItemId = element.item_Id;
            element.qty = 0;
            window.scrollTo(0, 0);
            element.sizedtls = JSON.parse(element.sizedtls);
            element.batchDetails = JSON.parse(element.batchDetails);
            element.batchDetails.forEach(function (batch) {
            batch.Batch_qty = 0;
            })
            window.scrollTo(0, 0)
          });

          debugger;

          for (var i = 0; i < this.LoadProductByCatData.length; i++) {
            if (this.LoadProductByCatData[i].batchDetails != null) {
              this.LoadProductByCatData[i].batchDetails.forEach(function (element) {
                element.BackgroundColour = "White";
                element.color = "Black";
              })


              this.LoadProductByCatData[i].item_SellingPrice = this.LoadProductByCatData[i].batchDetails[0].Batch_SellingPrice;
              this.LoadProductByCatData[i].item_MRP = this.LoadProductByCatData[i].batchDetails[0].Batch_MRPPrice;
              this.LoadProductByCatData[i].isstockavilbl = this.LoadProductByCatData[i].batchDetails[0].Stock ? 1 : 0;
              this.LoadProductByCatData[i].OfferAmt = 0;
              this.LoadProductByCatData[i].batch_id = this.LoadProductByCatData[i].batchDetails[0].Batch_Id;
              this.LoadProductByCatData[i].weight = this.LoadProductByCatData[i].batchDetails[0].Batch_Conversion;
              this.LoadProductByCatData[i].unit_Name = this.LoadProductByCatData[i].batchDetails[0].Unit_Name;
              this.LoadProductByCatData[i].ItemId = this.LoadProductByCatData[i].batchDetails[0].Batch_ItemId;
              this.LoadProductByCatData[i].item_Id = this.LoadProductByCatData[i].batchDetails[0].Batch_ItemId;
              for (var j = 0; j < this.LoadProductByCatData[i].batchDetails.length; j++) {
                this.LoadProductByCatData[i].batchDetails[0].BackgroundColour = "#154c79";
                this.LoadProductByCatData[i].batchDetails[0].color = "White";
              }
            }
          }

          //this.FilterListMethodsort(this.selectedshift);
          this.StorageData = localStorage.getItem('Cart');

          this.result2 = JSON.parse(this.StorageData);
          for (var i = 0; i < this.LoadProductByCatData.length; i++) {
            for (var k = 0; k < this.result2.length; k++) {
              if (this.LoadProductByCatData[i].item_Id == this.result2[k].item_Id && this.LoadProductByCatData[i].batch_id == this.result2[k].batch_id) {
                this.LoadProductByCatData[i].qty = this.result2[k].qty;
              }
            }
          }
          window.scrollTo(0, 0)
        }
        else {
          this.APICall = true;
          window.scrollTo(0, 0)
      }

      });
    }

  }






  public LoadTopSavers() {
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
    }
    if (this.LoadTopSaverAPI == true) {
      this.LoadTopSaverAPI = false
      this.apiIntergnService.getTopServers(obj).subscribe((data) => {
        this.LoadTopSaverAPI = true;
        if (!data.isError) {
          console.log("top savers in homepage", data.data);
          this.TopSaver = data.data;
          this.TopSaver.forEach(function (element) {
            element.qty = 0;
            element.batchDetails = JSON.parse(element.batchDetails);
            element.batchDetails.forEach(function (batch) {
              batch.Batch_qty = 0;
            })
            window.scrollTo(0, 0)
          });

          this.TopSaver = this.appComp.CheckItemQty(this.TopSaver, 'TopSaver');
          this.firstLoad = false;
          this.apiIntergnService.LoaderEnvet(this.firstLoad);
        }
        else {
          this.firstLoad = false;
          this.apiIntergnService.LoaderEnvet(this.firstLoad);
        }
      });
    }
  }
  AddFavourite(items) {
    debugger;
    if (this.CustomerId != 0) {
      items.isFavt = !items.isFavt;
      let favouriteModel = new FavouriteModel();
      favouriteModel.Favt_Id = 0;
      favouriteModel.Favt_customerId = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ItemId = items.item_Id;
      favouriteModel.Favt_CreateBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ModififedBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_CreatedDate = new Date();
      favouriteModel.Favt_ModififedDate = new Date();
      favouriteModel.Favt_IsDelete = false;
      favouriteModel.Favt_BatchId = parseInt(items.batch_id);
      if (items.isFavt == true)
        favouriteModel.Mode = "Insert"
      if (items.isFavt == false)
        favouriteModel.Mode = "Delete"


      this.apiIntergnService.Addfavourite(favouriteModel).subscribe(Val => {
        if (!Val.isError) {

        }
      });
    }
    else {
      alert("Please Login To Add Favourite");
    }

  }
  productcheck: any[];
  res: any[];
  result2:any;
  StorageData: string;

  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }

  pageChanged(event) {
    this.p = event;

    window.scrollTo(0, 0);


  }


  async quantityChanged(index, type, item, objectName) {
    debugger;
   // if (this.router.url == "/" || this.router.url == "/Home") {
      this.UserId = Number(localStorage.getItem('CustId'));
    this.StorageData = localStorage.getItem('Cart');
    //if (this.UserId == 0) {
    //  this.LoginModalOpen();
    //  return;
    //}


      this.BatchId = item.batch_id;
      this.UomId = item.uomId;
    

      if (localStorage.getItem('Cart') != null)
            this.result99 = JSON.parse(this.StorageData);

      if (objectName == 'TopSaver') {
        var prod = new ProductInfoModal();
        if (type == 'Increament') {
          this.TopSaver[index].qty += 1;
        }
        else {
          this.TopSaver[index].qty -= 1;
          prod.isDecreaseQty = true;
        }
        var indexTopOffer = this.TopOffers.findIndex(x => x.item_Id == item.item_Id);
        if (indexTopOffer != null && indexTopOffer != -1) {
          this.TopOffers[indexTopOffer].qty = this.TopSaver[index].qty;
        }
        if (item.imagePath == undefined) {
          item.imagePath = item.path;
        }
        if (item.batchId != undefined) {
          item.batch_id = item.batchId
        }

        if (item != null) {
          for (var k = 0; k < this.result99.length; k++) {
            if (this.result99[k].uomId == this.UomId && (this.result99[k].batchId || this.result99[k].batch_id) == this.BatchId) {
              if (this.result99[k].Batch_qty == undefined) {
                this.result99[k].Batch_qty = this.TopSaver[index].qty;
              }
              prod.Batch_qty = this.result99[k].Batch_qty;
           
              break;
            }
          }
          if (item.batchDetails != null && item.batchDetails != undefined) {
            for (var k = 0; k < item.batchDetails.length; k++) {
              if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
                item.batch_id = item.batchDetails[k].Batch_Id,
                  item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
                  item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
                  item.uomId = item.batchDetails[k].Unit_Id;
                if (type == 'Increament') {
                  if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
                  prod.Batch_qty += 1;
                }
                else {
                  if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                    alert("Can't Find The Product With Selected Size In Cart");
                    return;
                  }
                  prod.Batch_qty -= 1;
                }

                break;
              }
            }
          }
          else {
            for (var k = 0; k < this.result99.length; k++) {
              if ((this.result99[k].batchId == item.batchId) || (this.result99[k].batch_id == item.batchId)) {
                prod.Batch_qty = this.result99[k].Batch_qty;
               
              }
            }
            if (type == 'Increament') {
              if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
              prod.Batch_qty += 1;
            }
            else {
              if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                alert("Can't Find The Product With Selected Unit In Cart");
                return;
              }
              prod.Batch_qty -= 1;
            }
          }
        }


        prod.itemId = item.item_Id;
        prod.item_Id = item.item_Id;
        prod.productName = item.productName;
        prod.productPrice = item.item_SellingPrice;
        prod.batch_id = item.batch_id;
        prod.batchId = item.batch_id;
        prod.item_MRP = item.item_MRP;
        prod.weight = item.weight;
        prod.unit = item.unit_Name;
        prod.uomId = item.uomId;
        prod.offers = item.offerAmt;
        prod.qty = this.TopSaver[index].qty;
        prod.imagePath = item.imagePath;

     

        this.result99.forEach((element, index) => {
          if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId) {
            element.qty = prod.qty;
            element.Batch_qty = prod.Batch_qty
          
            if (prod.Batch_qty == 0) {
              const index = this.result2.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId));
              this.result99.splice(index, 1);
          
            }
          }
        });


        prod.CustomerID = Number(localStorage.getItem('CustId'));
        prod.UserId = Number(localStorage.getItem('CustId'));
        if (prod.UserId > 0) {
          debugger;
          var PreCartData = prod;
          PreCartData.qty = 1;
        await  this.apiIntergnService.AddPreSingleCart(PreCartData)
            .subscribe(Val => {
              debugger;
              if (!Val.isError) {
                this.result99.forEach((element, _index) => {
                  if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                    element.qty = Val.data.qty;
                  }
                });
                localStorage.setItem('Cart', (JSON.stringify(this.result99)));
              }
            });
        }
        localStorage.setItem('Cart', (JSON.stringify(this.result99)));

        this.StorageData = localStorage.getItem('Cart');
        console.log(this.StorageData);

        this.TopSaver = this.appComp.CheckItemQty(this.TopSaver, 'TopSaver');
      }
      else if (objectName == 'TopOffers') {
        var prod = new ProductInfoModal();



        this.BatchId = item.batch_id;
        this.UomId = item.uomId;

        if (type == 'Increament') {
          this.TopOffers[index].qty += 1;
        }
        else {
          this.TopOffers[index].qty -= 1;
          prod.isDecreaseQty = true;

        }
        var indexSaver = this.TopSaver.findIndex(x => x.item_Id == item.item_Id);
        if (indexSaver != null && indexSaver != -1) {
          this.TopSaver[indexSaver].qty = this.TopOffers[index].qty;
        }
        if (item.imagePath == undefined) {
          item.imagePath = item.path;
        }
        if (item.batchId != undefined) {
          item.batch_id = item.batchId
        }

        if (item != null) {
          for (var k = 0; k < this.result99.length; k++) {
            if (this.result99[k].uomId == this.UomId && (this.result99[k].batchId || this.result99[k].batch_id) == this.BatchId) {
              if (this.result99[k].Batch_qty == undefined) {
                this.result99[k].Batch_qty = this.TopOffers[index].qty;
              }
              prod.Batch_qty = this.result99[k].Batch_qty;

              break;
            }
          }
          if (item.batchDetails != null && item.batchDetails != undefined) {
            for (var k = 0; k < item.batchDetails.length; k++) {
              if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
                item.batch_id = item.batchDetails[k].Batch_Id,
                  item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
                  item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
                  item.uomId = item.batchDetails[k].Unit_Id;
                if (type == 'Increament') {
                  if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
                  prod.Batch_qty += 1;
                }
                else {
                  if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                    alert("Can't Find The Product With Selected Size In Cart");
                    return;
                  }
                  prod.Batch_qty -= 1;
                }

                break;
              }
            }
          }
          else {
            for (var k = 0; k < this.result99.length; k++) {
              if ((this.result99[k].batchId == item.batchId) || (this.result99[k].batch_id == item.batchId)) {
                prod.Batch_qty = this.result99[k].Batch_qty;

              }
            }
            if (type == 'Increament') {
              if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
              prod.Batch_qty += 1;
            }
            else {
              if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                alert("Can't Find The Product With Selected Unit In Cart");
                return;
              }
              prod.Batch_qty -= 1;
            }
          }
        }

        prod.itemId = item.item_Id;
        prod.item_Id = item.item_Id;
        prod.productName = item.productName;
        prod.productPrice = item.item_SellingPrice;
        prod.batch_id = item.batch_id;
        prod.batchId = item.batch_id;
        prod.item_MRP = item.item_MRP;
        prod.weight = item.weight;
        prod.unit = item.unit_Name;
        prod.uomId = item.uomId;
        prod.offers = item.offerAmt;
        prod.qty = this.TopOffers[index].qty;
        prod.imagePath = item.imagePath;


        this.result99.forEach((element, index) => {
          if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId) {
            element.qty = prod.qty;
            element.Batch_qty = prod.Batch_qty

            if (prod.Batch_qty == 0) {
              const index = this.result99.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId));
              this.result99.splice(index, 1);
          
            }
          }
         
        });

        prod.CustomerID = Number(localStorage.getItem('CustId'));
        prod.UserId = Number(localStorage.getItem('CustId'));
        if (prod.UserId > 0) {
          debugger;
          var PreCartData = prod;
          PreCartData.qty = 1;
         await this.apiIntergnService.AddPreSingleCart(PreCartData)
            .subscribe(Val => {
              debugger;
              if (!Val.isError) {
                this.result99.forEach((element, _index) => {
                  if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                    element.qty = Val.data.qty;
                  }
                });
                localStorage.setItem('Cart', (JSON.stringify(this.result99)));
              }
            });
        }
       
        localStorage.setItem('Cart', (JSON.stringify(this.result99)));
        this.StorageData = localStorage.getItem('Cart');
        console.log(this.StorageData);

        this.TopOffers = this.appComp.CheckItemQty(this.TopOffers, 'TopOffers');



      }

      else if (objectName == 'Products') {
        var prod = new ProductInfoModal();



        this.BatchId = item.batch_id;
        this.UomId = item.uomId;
        if (this.LoadProductByCatData != undefined) {

          var ProductDataIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
        }



        if (type == 'Increament') {
          this.LoadProductByCatData[ProductDataIndex].qty += 1;
        }
        else {
          this.LoadProductByCatData[index].qty -= 1;
          prod.isDecreaseQty = true;

        }
      
        if (item.imagePath == undefined) {
          item.imagePath = item.path;
        }
        if (item.batchId != undefined) {
          item.batch_id = item.batchId
        }

        if (item != null) {
          for (var k = 0; k < this.result99.length; k++) {
            if (this.result99[k].uomId == this.UomId && (this.result99[k].batchId || this.result99[k].batch_id) == this.BatchId) {
              if (this.result99[k].Batch_qty == undefined) {
                this.result99[k].Batch_qty = this.LoadProductByCatData[ProductDataIndex].qty;
              }
              prod.Batch_qty = this.result99[k].Batch_qty;

              break;
            }
          }
          if (item.batchDetails != null && item.batchDetails != undefined) {
            for (var k = 0; k < item.batchDetails.length; k++) {
              if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
                item.batch_id = item.batchDetails[k].Batch_Id,
                  item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
                  item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
                  item.uomId = item.batchDetails[k].Unit_Id;
                if (type == 'Increament') {
                  if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
                  prod.Batch_qty += 1;
                }
                else {
                  if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                    alert("Can't Find The Product With Selected Size In Cart");
                    return;
                  }
                  prod.Batch_qty -= 1;
                }

                break;
              }
            }
          }
          else {
            for (var k = 0; k < this.result99.length; k++) {
              if ((this.result99[k].batchId == item.batchId) || (this.result99[k].batch_id == item.batchId)) {
                prod.Batch_qty = this.result99[k].Batch_qty;

              }
            }
            if (type == 'Increament') {
              if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
              prod.Batch_qty += 1;
            }
            else {
              if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                alert("Can't Find The Product With Selected Unit In Cart");
                return;
              }
              prod.Batch_qty -= 1;
            }
          }
        }

        prod.itemId = item.item_Id;
        prod.item_Id = item.item_Id;
        prod.productName = item.productName;
        prod.productPrice = item.item_SellingPrice;
        prod.batch_id = item.batch_id;
        prod.batchId = item.batch_id;
        prod.item_MRP = item.item_MRP;
        prod.weight = item.weight;
        prod.unit = item.unit_Name;
        prod.uomId = item.uomId;
        prod.offers = item.offerAmt;
        prod.qty = prod.Batch_qty;
        prod.imagePath = item.imagePath;


        this.result99.forEach((element, index) => {
          if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId) {
            element.qty = prod.qty;
            element.Batch_qty = prod.Batch_qty

            if (prod.Batch_qty == 0) {
              const index = this.result99.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId));
              this.result99.splice(index, 1);

            }
          }

        });

        prod.CustomerID = Number(localStorage.getItem('CustId'));
        prod.UserId = Number(localStorage.getItem('CustId'));
        if (prod.UserId > 0) {
          debugger;
          var PreCartData = prod;
          PreCartData.qty = 1;
          this.apiIntergnService.AddPreSingleCart(PreCartData)
            .subscribe(Val => {
              debugger;
              if (!Val.isError) {
                this.result99.forEach((element, _index) => {
                  if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                    element.qty = Val.data.qty;
                  }
                });
                localStorage.setItem('Cart', (JSON.stringify(this.result99)));
              }
            });
        }
        localStorage.setItem('Cart', (JSON.stringify(this.result99)));
        this.StorageData = localStorage.getItem('Cart');
        console.log(this.StorageData);

        this.TopOffers = this.appComp.CheckItemQty(this.TopOffers, 'TopOffers');



      }

      else if (objectName == 'All') {
        debugger;
        this.appComp.changed = true;
        var qty = 0;
        var prod = new ProductInfoModal();
        if (this.TopOffers != null) {
          var TopOfferIndex = this.TopOffers.findIndex(x => x.item_Id == item.item_Id);
        }
        else {
          TopOfferIndex = -1;
        }

        if (this.TopSaver != null) {
          var TopSaverIndex = this.TopSaver.findIndex(x => x.item_Id == item.item_Id);
        }
        else {
          TopSaverIndex = -1;
        }
        if (this.LoadProductByCatData != null) {
          var ProdutIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
        }
        else {
          ProdutIndex = -1;
        }
        
        if (item.batchId != undefined) {
          item.batch_id = item.batchId
        }
        if (type == 'Increament') {

          if (TopOfferIndex != null && TopOfferIndex != -1) {
            this.TopOffers[TopOfferIndex].qty += 1;
            qty = this.TopOffers[TopOfferIndex].qty;
          }
          if (TopSaverIndex != null && TopSaverIndex != -1) {
            this.TopSaver[TopSaverIndex].qty += 1;
            qty = this.TopSaver[TopSaverIndex].qty;
          }
          if (ProdutIndex != null && ProdutIndex != -1) {
            this.LoadProductByCatData[ProdutIndex].qty += 1;
            qty = this.LoadProductByCatData[ProdutIndex].qty;
          }
          if (TopSaverIndex == -1 && TopSaverIndex == -1 &&  ProdutIndex==-1 && qty == 0) {
            this.result99.forEach((element, index) => {
              if (element.item_Id == item.item_Id && (element.batchId || element.batch_id) == item.batch_id) {
                qty = element.qty;//+ 1;
              }
            });
          }
         

        }
        else {

          if (TopOfferIndex != null && TopOfferIndex != -1) {
            this.TopOffers[TopOfferIndex].qty -= 1;
            qty = this.TopOffers[TopOfferIndex].qty;
          }
          if (TopSaverIndex != null && TopSaverIndex != -1) {
            this.TopSaver[TopSaverIndex].qty -= 1;
            qty = this.TopSaver[TopSaverIndex].qty;
          }
          if (ProdutIndex != null && ProdutIndex != -1) {
            this.LoadProductByCatData[ProdutIndex].qty -= 1;
            qty = this.LoadProductByCatData[ProdutIndex].qty;
          }
          if (TopSaverIndex == -1 && TopSaverIndex == -1 && ProdutIndex== -1 && qty == 0) {
            this.result99.forEach((element, index) => {
              if (element.item_Id == item.item_Id && (element.batchId || element.batch_id) == item.batch_id) {
                qty = element.qty;// - 1;
              }
            });
          }
       
          prod.isDecreaseQty = true;

        }
        {
          
          if (item.imagePath == undefined) {
            item.imagePath = item.path
          }


          prod.itemId = item.item_Id;
          prod.item_Id = item.item_Id;
          prod.productName = item.productName;
          prod.productPrice = item.item_SellingPrice;
          prod.batch_id = item.batch_id;
          prod.batchId = item.batch_id;
          prod.item_MRP = item.item_MRP;
          prod.weight = item.weight;
          prod.unit = item.unit_Name;
          prod.uomId = item.uomId;
          prod.offers = item.offerAmt;
          prod.qty = qty;
          prod.Batch_qty = qty;
          prod.imagePath = item.imagePath;



          this.result99.forEach((element, index) => {
            if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId) {
              element.qty = prod.qty;
              element.Batch_qty = prod.Batch_qty

              if (prod.Batch_qty == 0) {
                const index = this.result99.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId));
                this.result99.splice(index, 1);
              }
            }
          });

   
          this.StorageData = localStorage.getItem('Cart');
          console.log(this.StorageData);
          prod.CustomerID = Number(localStorage.getItem('CustId'));
          prod.UserId = Number(localStorage.getItem('CustId'));
          if (prod.UserId > 0) {
            debugger;
            var PreCartData = prod;
            PreCartData.qty = 1;
            this.apiIntergnService.AddPreSingleCart(PreCartData)
              .subscribe(Val => {
                debugger;
                if (!Val.isError) {
                  this.result99.forEach((element, _index) => {
                    if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                      element.qty = Val.data.qty;
                    }
                  });
                  localStorage.setItem('Cart', (JSON.stringify(this.result99)));
                }
              });
          }
          localStorage.setItem('Cart', (JSON.stringify(this.result99)));
          this.StorageData = localStorage.getItem('Cart');
          this.result99 = JSON.parse(this.StorageData);
          this.appComp.cartItems = this.result99;

        }
    }
      else if (objectName == 'Delete') {
        var prod = new ProductInfoModal();
        prod.isDecreaseQty = true;
        qty = 0
        {

          if (item.imagePath == undefined) {
            item.imagePath = item.path
          }


          prod.itemId = item.item_Id;
          prod.item_Id = item.item_Id;
          prod.productName = item.productName;
          prod.productPrice = item.item_SellingPrice;
          prod.batch_id = item.batch_id;
          prod.batchId = item.batch_id;
          prod.item_MRP = item.item_MRP;
          prod.weight = item.weight;
          prod.unit = item.unit_Name;
          prod.uomId = item.uomId;
          prod.offers = item.offerAmt;
          prod.qty = qty;
          prod.Batch_qty = qty;
          prod.imagePath = item.imagePath;



          this.result99.forEach((element, index) => {
            if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId) {
              element.qty = prod.qty;
              element.Batch_qty = prod.Batch_qty

              if (prod.Batch_qty == 0) {
                const index = this.result99.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId));
                this.result99.splice(index, 1);
              }
            }
          });

       
          this.StorageData = localStorage.getItem('Cart');
          console.log(this.StorageData);
          prod.CustomerID = Number(localStorage.getItem('CustId'));
          prod.UserId = Number(localStorage.getItem('CustId'));
          if (prod.UserId > 0) {
            debugger;
            var PreCartData = prod;
            PreCartData.qty = 1;
            this.apiIntergnService.AddPreSingleCart(PreCartData)
              .subscribe(Val => {
                debugger;
                if (!Val.isError) {
                  this.result99.forEach((element, _index) => {
                    if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                      element.qty = Val.data.qty;
                    }
                  });
                  localStorage.setItem('Cart', (JSON.stringify(this.result99)));
                }
              });
          }
          localStorage.setItem('Cart', (JSON.stringify(this.result99)));
          this.StorageData = localStorage.getItem('Cart');
          this.result99 = JSON.parse(this.StorageData);
          this.appComp.cartItems = this.result99;

        }
      }
      this.appComp.TotalItem = this.result99.length;
   // }
  }
  TopOffers: any;
  LoadTopOffersAPI: boolean = true;

  public LoadTopOffers() {
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
    }
    if (this.LoadTopOffersAPI == true) {
      this.LoadTopOffersAPI = false;

      this.apiIntergnService.getTopOffers(obj).subscribe((data) => {
        this.LoadTopOffersAPI = true;
        if (!data.isError) {
          console.log("top offers in homepage", data.data);
          this.TopOffers = data.data;
          this.TopOffers.forEach(function (element) {
            element.qty = 0;
            element.batchDetails = JSON.parse(element.batchDetails);
            element.batchDetails.forEach(function (batch) {
              batch.Batch_qty = 0;
            })
            window.scrollTo(0, 0)
          });
          this.TopOffers = this.appComp.CheckItemQty(this.TopOffers, 'TopOffers');
        }
      });
    }

  }
 


  pnlLogin: boolean = true;
  btnAddtoCart: boolean = true;
  btnTopSaver: boolean = true;
  btnTopSaverIncrement: boolean = false;
  btnAddtoCartIncrement: boolean = false;
  pnlRegistration: boolean = false;
  min: any;
  title = 'GW-Online-Retail';
  //@ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  //@ViewChild('SignUPForm', { static: false }) SignUPForm: ModalDirective;
  navbarOpen = false;
  sidebarOpen = false;
  txtValue: number;
  UserId: number = 0;
 
  result99 = [];
  OpenTopSaver(item, index, objectName)
  {
    debugger;
    
    this.StorageData = localStorage.getItem('Cart');
    if (this.StorageData != null && this.StorageData != "null") {
      this.result99 = JSON.parse(this.StorageData);
    }
    this.UserId = Number(localStorage.getItem('CustId'));
    if (this.LoadProductByCatData != undefined) {

      var ProductDataIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
    }
    //if (this.UserId == 0) {
    //  this.LoginModalOpen();
    //  return;
    //}
    var prod = new ProductInfoModal();

    this.BatchId = item.batch_id;
    this.UomId = item.uomId;
   
      if (objectName == 'TopSaver') {
        prod.qty = this.TopSaver[index].qty = 1;
        var TopOfferIndex = this.TopOffers.findIndex(x => x.item_Id == item.item_Id);
        if (TopOfferIndex != null && TopOfferIndex != -1) {
          this.TopOffers[TopOfferIndex].qty = 1;
        }
      }
      else if (objectName == 'TopOffers') {
        prod.qty =  this.TopOffers[index].qty = 1;
        var TopSaverIndex = this.TopSaver.findIndex(x => x.item_Id == item.item_Id);
        if (TopSaverIndex != null && TopSaverIndex != -1) {
          this.TopSaver[TopSaverIndex].qty = 1;
        }
    }
    else if (objectName == 'Products') {
      prod.qty = this.LoadProductByCatData[ProductDataIndex].qty = 1;
    
    }
    if (item.imagePath == undefined) {
      item.imagePath = item.path
    }
    if (item.batchId != undefined) {
      item.batch_id = item.batchId
    }
    if (item.batchDetails != null) {
      for (var k = 0; k < item.batchDetails.length; k++) {
        if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
          item.batch_id = item.batchDetails[k].Batch_Id,
            item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
            item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
            item.uomId = item.batchDetails[k].Unit_Id
          break;
        }
      }
    }


    prod.Batch_qty = 1
    prod.itemId = item.item_Id;
    prod.item_Id = item.item_Id;
    prod.productName = item.productName;
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.item_MRP = item.item_MRP;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.offers = item.offerAmt;
    prod.imagePath = item.imagePath;
    prod.uomId = item.uomId;

   
        this.result99.push(prod);
        prod.CustomerID = Number(localStorage.getItem('CustId'));
        localStorage.setItem('Cart', (JSON.stringify(this.result99)));

    prod.UserId = Number(localStorage.getItem('CustId'));
    if (prod.UserId > 0) {
      debugger;
      var PreCartData = prod;
      PreCartData.qty = 1;
      this.apiIntergnService.AddPreSingleCart(PreCartData)
        .subscribe(Val => {
          debugger;
          if (!Val.isError) {
            this.result99.forEach((element, _index) => {
              if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                element.qty = Val.data.qty;
              }
            });
            localStorage.setItem('Cart', (JSON.stringify(this.result99)));
          }
        });
    }
    localStorage.setItem('Cart', (JSON.stringify(this.result99)));
      
      


      this.appComp.TotalItem = this.result99.length;
    
  }

  OpenAddtoCart() {
    this.btnAddtoCart= false;
    this.btnAddtoCartIncrement= true;
  }
  SideNavbar()
  {
     
    this.sidebarOpen = !this.sidebarOpen;
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  private _opened: boolean = false;

  private _toggleSidebar() {
    this._opened = !this._opened;
  }
 
  delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
  }



  //------------Add To CartMethod-----------

  AddToCart(ProductsInfo: ProductInfoModal)
  {
     
    let CartInfo: Cart = new Cart();
    CartInfo.AddedInCartDate = new Date();
    CartInfo.ItemId = ProductsInfo.item_Id;
    CartInfo.ProductName = ProductsInfo.productName;
    CartInfo.ProductPrice = ProductsInfo.productPrice;
    CartInfo.BatchId = ProductsInfo.batch_id;
    CartInfo.ImgPath = ProductsInfo.path;
    CartInfo.QTY = 1;
    CartInfo.IsDecreaseQty = ProductsInfo.isDecreaseQty;
    CartInfo.TotalProductPrice = (CartInfo.ProductPrice) * (CartInfo.QTY);
    var id = localStorage.getItem('CustId');
    CartInfo.UserId = Number(id);
    CartInfo.UomId = ProductsInfo.uomId;
    CartInfo.Unit_Name = ProductsInfo.unit_Name;
    CartInfo.Unit = ProductsInfo.unit;

    this.apiIntergnService.AddPreSingleCart(CartInfo)
      .subscribe(response =>
      {
        if (response)
        {

          this.appComp.getTotalAmounts();
         
        }
        else
        {
          alert("Something went wrong please try again")
        }
      });
  }

  getTotalAmounts()
  {
    this.GetAddToCardDetails((data) =>
    {
      this.cartItems = data;
      this.cartItemsCal = this.cartItems;

      this.TotalAmount = 0;
      var t = 0;
      for (let i = 0; i < this.cartItemsCal.length; i++) {
        t += this.cartItemsCal[i].ProductPrice * this.cartItemsCal[i].qty;

      }
      this.TotalAmount = t;
      this.TotalItem = 0;
      t = 0;
      if (this.cartItemsCal)
      {
        for (let i = 0; i < this.cartItemsCal.length; i++) {
          t += this.cartItemsCal[i].qty;
        }
      }
      this.TotalItem = t;
    });

  
    return this.TotalAmount;
  }


  //---------AddToCartDetails--------------

  GetAddToCardDetails(callback)
  {
     
    var id = Number(localStorage.getItem('CustId'));
    this.apiIntergnService.CartDetails(id)
      .subscribe(data =>
      {
        if (data) {
          if (callback) {
            console.log("cartdetails in homepage", data.data);
            callback(data.data);
            
          }
        
        }
        console.log("cartdetails in homepage", data.data);

        this.cartItems = data.data;
        localStorage.setItem('Cart', JSON.stringify(this.cartItems));
        //for (var i = 0; i < this.cartItems.length; i++) {
        //  if (this.cartItems[i].Image) {
        //    this.cartItems[i].Image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
        //      + this.cartItems[i].Image);
        //  }
        //  else
        //  {
            
        //  }
        //}
      }
      );

  }



  
  //------------------------------------------section1---------------------------------------
  carouselOptions = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],

    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: false
      },
      600: {
        items: 1,
        nav: true,
        dots: false
      },
      1000: {
        items: 1,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  images = [
    {
      text: "Everfresh Flowers",

      image: "../assets/img/slider/slider1.jpg"
    },
    {
      text: "Festive Deer",
      image: "../assets/img/slider/slider2.jpg"
    },
    {
      text: "Festive Deer",
      image: "../assets/img/slider/slider1.jpg"
    },
    {
      text: "Festive Deer",
      image: "../assets/img/slider/slider2.jpg"
    }

  ]

  //---------------------------------------------section2------------------------------------
  //section2
  carouselOptionsForSEction2 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 3,
        nav: true,
        dots: false,
        pagination: true
      },
      600: {
        items: 5,
        nav: true,
        dots: false,
        pagination: true
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
        dots: false,
        pagination: true
      },
      1500: {

        items: 4,
        nav: true,
        loop: false,
        dots: false,
        pagination: true
      }
    }
 

    }


    carouselOptionsForSEction10 = {
        
        nav: false,
        dots: false,
        navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
        responsiveClass: true,
        autoPlaySpeed: 3000,
        smartSpeed: 2500,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true,
                loop: true,
                pagination: true
            },
            600: {
                items: 1,
                nav: false,
                dots: true,
                loop: true,
                pagination: true
            },
            1000: {
                items: 1,
                nav: false,
                loop: true,
                dots: true,
                pagination: true
            },
            1500: {

                items: 1,
                nav: false,
                loop: true,
                dots: true,
                pagination: true
            }
        }


    }



  //---------------------------------------------section3------------------------------------
  carouselOptionsForSection3 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      600: {
        items: 3,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  //imagesofsection3 = [
  //  {


  //    image: "../assets/img/item/1.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/2.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/3.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/4.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/5.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/6.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/7.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/8.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/9.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/12.jpg"
  //  }


  //]

  //---------------------------------------------section4------------------------------------
  carouselOptionsForSection4 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      600: {
        items: 3,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  //imagesofsection4 = [
  //  {

  //    image: "../assets/img/item/6.jpg",
  //    lable: "All Fruits"
  //  },
  //  {

  //    image: "../assets/img/item/7.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/8.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/9.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/12.jpg"
  //  },
  //  {


  //    image: "../assets/img/item/1.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/2.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/3.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/4.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/5.jpg"
  //  }



  //]

}
