import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OwlModule } from 'ngx-owl-carousel';
import { LoginComponent } from './Login/login/login.component';
import { ModalModule } from 'ngx-bootstrap';
import { AccordionModule } from "ngx-accordion";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TooltipModule } from 'ng2-tooltip-directive';
import { SafePipe } from './safe.pipe';

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular-6-social-login";


import { HomePageComponent } from './home-page/home-page.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { FooterComponent } from './footer/footer.component';
import { ShopComponent } from './shop/shop.component';
import { SingleComponent } from './single/single.component';
import { SearchComponent } from './search/search.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CategoryComponent } from './category/category.component';
import { GrdFilterPipe } from './Models/grd-filter-pipe';
import { AccountComponent } from './account/account.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { MyOrderDetailsComponent } from './my-order-details/my-order-details.component';
import { LoggedInGuard } from './logged-in.guard';
import { ApiIntergnService } from './AppServices/api-intergn.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ViewAllCategoryComponent } from './view-all-category/view-all-category.component';
import { PaymentComponent } from './payment/payment.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { DatePipe } from '@angular/common';

export function getAuthServiceConfigs() {

  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("123")
      },
      
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        
        provider: new GoogleLoginProvider("123")
      }
      
    ]
  );
  return config;
}


const routes: Routes =
  [

    { path: '', component: HomePageComponent, pathMatch: 'full' },
    { path: 'Home', component: HomePageComponent },
    { path: 'shop/:catname/:id', component: ShopComponent },
    { path: 'Search/:term', component: SearchComponent },
    { path: 'single/:Itemid', component: SingleComponent },
    { path: 'CheckOut', component: CheckOutComponent, canActivate: [LoggedInGuard] },
    { path: 'Payment', component: PaymentComponent, canActivate: [LoggedInGuard] },
    { path: 'AboutUs', component: AboutUsComponent },
    { path: 'ContactUs', component: ContactUsComponent },
    { path: 'PrivacyPolicy', component: PrivacyPolicyComponent },
    { path: 'Category', component: CategoryComponent },
    { path: 'ViewAllCategory', component: ViewAllCategoryComponent },
    { path: 'Account', component: AccountComponent, canActivate: [LoggedInGuard],  },
    { path: 'MyOrder', component: MyOrderComponent, canActivate: [LoggedInGuard] },
    { path: 'WishList', component: WishlistComponent },
    { path: 'OrderDetails/:id', component: MyOrderDetailsComponent, canActivate: [LoggedInGuard] },
    { path: '**', component: PageNotFoundComponent },
    { path: 'reviews/:Itemid', component: ReviewsComponent }
    //{ path: '**', component: HomePageComponent, pathMatch: 'full' },
    
    
  
  ]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomePageComponent,
    FooterComponent,
    CheckOutComponent,
    ShopComponent,
    GrdFilterPipe,
    SingleComponent,
    SearchComponent,
    AboutUsComponent,
    ContactUsComponent,
    CategoryComponent,
    AccountComponent,
    MyOrderComponent,
    MyOrderDetailsComponent,
    PageNotFoundComponent,
    SafePipe,
   
    ViewAllCategoryComponent,
   
    PaymentComponent,
   
    WishlistComponent,
   
    PrivacyPolicyComponent,
   
    ReviewsComponent,
   
    
  ],
  imports: [
    ReactiveFormsModule,
    GooglePlaceModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    OwlModule,
    AccordionModule,
    HttpClientModule,
      SocialLoginModule,
      TooltipModule ,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
     
      apiKey: 'AIzaSyAoy2kjgYFHo1JoF1y544ofNH30rTA4l_Y',

      libraries: ['places']
     
    }),

   

    [RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })],
    
    
  ],
  exports: [RouterModule],
  providers: [ApiIntergnService, DatePipe,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
    , LoggedInGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
  
}
