import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductInfoModal } from '../Models/product-info-modal';
import { Cart } from '../Models/cart';
import { AppComponent } from '../app.component';
import { FormArray, FormControl } from '@angular/forms';
import { FavouriteModel } from '../Models/favourite-model';
import { fail } from 'assert';
import { ModalDirective } from 'ngx-bootstrap';
import { DebugHelper } from 'protractor/built/debugger';
declare var $: any;
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  [x: string]: any;
  firstLoad: boolean;
  Allcatid: any;
  items = []; searchText: string="";
  searchBrandText: string;
  APICall: boolean = true;
  pageOfItems: Array<any>; categoryShow: boolean = true;

  myOptions = {
    'placement': 'right',
    'show-delay': 500,
    'theme': 'light'

  }
  myOptions1 = {
    'placement': 'left',
    'show-delay': 500,
    'theme': 'light'

  }
  constructor(private router: Router, private apiIntergnService: ApiIntergnService, private route: ActivatedRoute, private appComp: AppComponent) { }
  catid: any;
  p: number = 1;
  listShow1: boolean = false;
  ShowCategoryColla: boolean = true;
  ShowPriceColla: boolean = true;
  ShowBrandColl: boolean = true;
  Subcatdata: boolean = false;
  ShowBlock: boolean = true;
    ShowSubCategory: boolean = false;
  SpecialisationNav: boolean = false;
  BatchId: number=0;
  UomId: number = 0;
  ShowPriceCollapse()
  {
    debugger;
    console.log(this.ShowPriceColla);
    this.ShowPriceColla = !this.ShowPriceColla;
  }
  ShowCategoryCollapse()
  {
    debugger;
    this.ShowCategoryColla = !this.ShowCategoryColla;
  }
  AddFavourite(items) 
  {
    debugger;
    if (this.CustomerId != 0)
    {
      items.isFavt = !items.isFavt;
      let favouriteModel = new FavouriteModel();
      favouriteModel.Favt_Id = 0;
      favouriteModel.Favt_customerId = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ItemId = items.item_Id;
      favouriteModel.Favt_CreateBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ModififedBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_CreatedDate = new Date();
      favouriteModel.Favt_ModififedDate = new Date();
      favouriteModel.Favt_IsDelete = false;
      favouriteModel.Favt_BatchId = items.batch_id;
      if (items.isFavt == true)
        favouriteModel.Mode = "Insert"
      if (items.isFavt == false)
        favouriteModel.Mode = "Delete"

      this.apiIntergnService.Addfavourite(favouriteModel).subscribe(Val => {
        if (!Val.isError) {

        }
      });
    }
    else
    {
      alert("Please Login To Add Favourite");
    }
    
  }
  item1: boolean;
  showList(item,index) {
    debugger;
    console.log(item);
    this.item1 = item.subcategory;
    this.CategoriesWithSubCat.forEach(function (element) {
      element.subcategory = false;
      
    })
    if (this.item1 == false) {
      this.CategoriesWithSubCat[index].subcategory = true;
    }
    else {
      this.CategoriesWithSubCat[index].subcategory = false;
    }
   

    }
    MainCat: string;
    SubCat: string;
    SpeciCat: string;
  result3 = [];
  StorageData: string;
  SpecificationName: string;
  CustomerId: number;
  //changed: boolean = false;
  ngOnInit() {
    debugger;
    this.CustomerId = Number(localStorage.getItem('CustId'));
    this.appComp.filter = "";
    this.firstLoad = true;
    if (this.apiIntergnService.subsRefreshCartVar == undefined) {
      this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {
          this.appComp.changed = true;
          this.ngOnInit();
      });
    }

    if (this.apiIntergnService.subsVar == undefined) {

        this.apiIntergnService.invokeQtyChange.subscribe((Obj: any) => {
            if ((Obj != null && !this.appComp.changed) || (Obj != null &&  this.appComp.homePageChanged)) {

                debugger;
              this.appComp.homePageChanged = false;
              if (this.LoadProductByCatData != undefined) {

                var ProductDataIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == Obj.Item.item_Id);
              }
              if (this.TopOffersData != undefined) {

                var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == Obj.Item.item_Id);
              }
          let item: any = {};

          item.item_Id = Obj.Item.item_Id;
          item.productName = Obj.Item.productName;
          item.item_SellingPrice = Obj.Item.productPrice;
          item.batch_id = Obj.Item.batch_id;
              item.batchId = Obj.Item.batch_id;
              item.qty = Obj.Item.qty;
          item.item_MRP = Obj.Item.item_MRP;
          item.weight = Obj.Item.weight;
          item.unit_Name = Obj.Item.unit;
              item.offerAmt = Obj.Item.offers;
              item.uomId = Obj.Item.uomId;

          if (ProductDataIndex != null && ProductDataIndex != -1) {
            this.quantityChanged(ProductDataIndex, Obj.Type, item, 'Products');
            return;
          }
          if (TopPopularIndex != null && TopPopularIndex != -1) {
            this.quantityChanged(TopPopularIndex, Obj.Type, item, 'TopPopular');
            return;
              }
              if (ProductDataIndex == -1 && TopPopularIndex == -1 && Obj.Item.qty ==0) {
                this.quantityChanged(0, Obj.Type, item, 'Delete');
                return;
              }

          if (this.appComp.cartItems[Obj.Index].qty == 0) {
            this.appComp.cartItems.splice(Obj.Index, 1);
          }
          //this.quantityChanged(Obj.Index, Obj.Type, Obj.Item, Obj.ObjectName);
        }
        else {
          this.appComp.changed = false;
        }
      });
    }

    if (this.apiIntergnService.subsVarCart == undefined) {
      this.apiIntergnService.invokeCartItemDeleteEvent.subscribe((Obj: any) => {
        debugger;
        var ProductDataIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == Obj.item_Id);
        var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == Obj.item_Id);

        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.LoadProductByCatData[ProductDataIndex].qty = 0;
        }
        if (TopPopularIndex != null && TopPopularIndex != -1) {
          this.TopOffersData[TopPopularIndex].qty = 0;
        }
        this.StorageData = localStorage.getItem('Cart');

        this.result3 = JSON.parse(this.StorageData);
      });
    }






    this.StorageData = localStorage.getItem('Cart');

    this.result3 = JSON.parse(this.StorageData);

    this.route.params.subscribe(params =>
    {
        debugger;
        this.catid = params['id'];
      var IdentityId =  this.catid.substring(0, 1);
        var CategoryId = this.catid.substring(1);
        if (IdentityId == "1")
        {
            document.getElementById("myDiv").style.marginTop = "0%";
            this.SpecificationName = "Specification";
            this.SpecialisationNav = false;
            this.CateAllData123 = params['catname'];
            this.categoryNamesShow = params['catname'];
            this.catid = CategoryId;
            this.ShowSubCategory = true;
            this.GetCategoriesNavigation();
           
        }
        else if (IdentityId == "2")
        {
           document.getElementById("myDiv").style.marginTop = "0%";
            this.SpecificationName = "Specification";
            this.SpecialisationNav = false;
            this.CateAllData123 = params['catname'];
            this.categoryNamesShow = params['catname'];
            this.catid = Number(CategoryId);
            this.ShowSubCategory = true;
            this.GetSubCateNavigation();
            this.LoadProductByCat();
        }
        else if (IdentityId == "3")
        {
           document.getElementById("myDiv").style.marginTop = "5%";
            this.CateAllData123 = params['catname'];
            this.categoryNamesShow = params['catname'];
            this.catid = Number(CategoryId);
            this.ShowSubCategory = false;
            this.GetSpecificCateNavigation();
            this.LoadProductByCat();
        }
        else if (IdentityId == "M") {
          
            //document.getElementById("myDiv").style.marginTop = "5%";
          var Manf = JSON.parse( localStorage.getItem('Manf'));
          this.GetProductByManufactureId(Manf);

        }
        else
        {
         // document.getElementById("myDiv").style.marginTop = "5%";
          this.CateAllData123 = params['catname'];
          this.categoryNamesShow = params['catname'];
          this.catid = Number(CategoryId);
          this.ShowSubCategory = false;
          this.SpecialisationNav = false;
          //this.GetSpecificCateNavigation();
          this.LoadProductByCat();
        }
      this.catid = Number(CategoryId);
      this.LoadManufactureByCatid(this.catid);
        
      
        
      
    });
      //if (this.MainCat == 'true')
      //{
      //    this.Subcatdata=true;
          
      //}
      //else if (this.SubCat == 'true')
      //{
      //    this.Subcatdata = true;
          
      //}
      //else
      //{
      //    this.Subcatdata = false;
          
      //}
    
    this.items = Array(150).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}` }));
    //this.LoadCategory();
    this.LoadTopOffers();
    


    $(".toggle").click(function (e) {
      e.preventDefault();

      var $this = $(this);

      if ($this.next().hasClass("show")) {
        $this.next().removeClass("show");
        $this.next().slideUp(350);
      } else {
        $this
          .parent()
          .parent()
          .find("li .inner")
          .removeClass("show");
        $this
          .parent()
          .parent()
          .find("li .inner")
          .slideUp(350);
        $this.next().toggleClass("show");
        $this.next().slideToggle(350);
      }
    });


  }


  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }

  ViewAllCategory() {
      this.Allcatid = 0;
      
      this.router.navigate(['./shop/All Categories/' + this.Allcatid]);
      
    //this.router.navigate(['./shop/All Categories/' + this.Allcatid], { queryParams: this.Allcatid, skipLocationChange: true });
      window.scrollTo(0, 0);
      this.appComp.OpenAboutUsPageforCategory();
  }

  OpenSingleForm(ItemID) {
    this.router.navigate(['./single/' + ItemID]);
  }
  showhideCate: boolean = true;
  showhideprice: boolean = false;
  showhidebrand: boolean = false;
  showhideimpfruits: boolean = false;
  result2 = [];

  UpDown(listType) {
    debugger;
    switch (listType) {
      case 'cate': {
        this.showhideprice = false;
        this.showhidebrand = false;
        this.showhideimpfruits = false;
        if (this.showhideCate) {
          this.showhideCate = false;
        }
        else {
          this.showhideCate = true;
          window.scrollTo(0, 0)
        }
        break;
      }
      case 'price': {
        this.showhideCate = false;
        this.showhidebrand = false;
        this.showhideimpfruits = false;
        if (this.showhideprice) {
          this.showhideprice = false;
        }
        else {
          this.showhideprice = true;
          window.scrollTo(0, 0)
        }
        break;
      }
      case 'brand': {
        this.showhideprice = false;
        this.showhideCate = false;
        this.showhideimpfruits = false;
        if (this.showhidebrand) {
          this.showhidebrand = false;
        }
        else {
          this.showhidebrand = true;
          window.scrollTo(0, 0)
        }
        break;
      }
      case 'impfruits': {
        this.showhideprice = false;
        this.showhidebrand = false;
        this.showhideCate = false;
        if (this.showhideimpfruits) {
          this.showhideimpfruits = false;
        }
        else {
          this.showhideimpfruits = true;
           window.scrollTo(0, 0)
        }
        break;
      }
    }
   
  }


 

  separatedStringBrand: string="";
  onChangeBrand(brand: any, isChecked: boolean)
  {
    
    if (isChecked)
    {
      this.result2.push(brand);
      this.separatedStringBrand = Array.prototype.map.call(this.result2, function (item) { return item.Brand_Id; }).join(",");
    } else
    {
      const index = this.result2.findIndex(list => list.Brand_Id == brand.Brand_Id);//Find the index of stored id
      this.result2.splice(index, 1)
      this.separatedStringBrand = Array.prototype.map.call(this.result2, function (item) { return item.Brand_Id; }).join(",");
     
    }
    debugger;
    this.LoadProductByCatDatalength == this.result2.length;
    this.OpenBrand(brand);
  }
  onChange(event)
  {

  }
  result1 = [];
  separatedString: string="";
  onChangeCategory(email: any, isChecked: boolean) {

    if (isChecked) {
      this.result1.push(email);
      this.separatedString = Array.prototype.map.call(this.result1, function (item) { return item.category_ID; }).join(",");
      console.log(this.separatedString);
    } else {
     
      const index = this.result1.findIndex(list => list.category_ID == email.category_ID);//Find the index of stored id
      this.result1.splice(index, 1)
      this.separatedString = Array.prototype.map.call(this.result1, function (item) { return item.category_ID; }).join(",");
    }

    //this.OpenCategory(email);
  }

  priceFilter = () => {
    debugger;
    let ordered = this.LoadProductByCatData.sort((a, b) => (a.item_SellingPrice > b.item_SellingPrice) ? 1 : -1);
    
    this.LoadProductByCatData = ordered;
    this.LoadProductByCatDatalength == this.LoadProductByCatData.length;
    
  }

  priceHighToLowFilter = () => {
    debugger;
    let ordered = this.LoadProductByCatData.sort((a, b) => (a.item_SellingPrice < b.item_SellingPrice) ? 1 : -1);
    this.LoadProductByCatData = ordered;
    this.LoadProductByCatDatalength == this.LoadProductByCatData.length;
  }

  DiscountHighToLowFilter = () => {
    debugger;
    let ordered = this.LoadProductByCatData.sort((a, b) => (a.offerAmt < b.offerAmt) ? 1 : -1);
    this.LoadProductByCatData = ordered;
    this.LoadProductByCatDatalength == this.LoadProductByCatData.length;
  }
  selectedshift: any;
  FilterListMethod(event: any) {
    debugger;

    this.selectedshift = event.name;
    if (this.selectedshift == "Name(A to Z)") {
      this.LoadProductByCatData.sort((a, b) => Number(a.productName > b.productName) * 2 - 1);
    }
    else if (this.selectedshift == "Price (Low to High)") {
      this.priceFilter();

    }
    else if (this.selectedshift == "Price (High to Low)") {

      this.priceHighToLowFilter();

    }

    else if (this.selectedshift == "Discount (High to Low)") {

      this.DiscountHighToLowFilter();

    }

  }

  FilterListMethodsort(event: any) {
    debugger;

    this.selectedshift = event;
    if (this.selectedshift == "Name(A to Z)") {
      this.LoadProductByCatData.sort((a, b) => Number(a.productName > b.productName) * 2 - 1);
    }
    else if (this.selectedshift == "Price (Low to High)") {
      this.priceFilter();

    }
    else if (this.selectedshift == "Price (High to Low)") {

      this.priceHighToLowFilter();

    }

    else if (this.selectedshift == "Discount (High to Low)") {

      this.DiscountHighToLowFilter();

    }

  }
  LoadProductByCatDatalength: number;
  OpenCategory(item,index) {
    debugger;
    this.CateAllData123 = item.category_DisplayName;
    this.categoryNamesShow = item.category_DisplayName;
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
      CateId: item.category_ID,
      BrandId:this.separatedStringBrand
    }
    this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {
      debugger;
      if (!data.isError) {
        this.LoadProductByCatData = data.data;
        this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
        this.LoadSubProductCatDatalength = this.LoadProductByCatData.length;
        this.LoadProductByCatData.forEach(function (element) {
          element.qty = 0;
          element.sizedtls = JSON.parse(element.sizedts);
          window.scrollTo(0, 0)
        });
        this.FilterListMethodsort(this.selectedshift);
        console.log("loaded products data on shop page:" + this.LoadProductByCatData);
        for (var i = 0; i < this.LoadProductByCatData.length; i++) {
          for (var k = 0; k < this.result3.length; k++) {
            if (this.LoadProductByCatData[i].item_Id == this.result3[k].item_Id) {
              this.LoadProductByCatData[i].qty = this.result3[k].qty;
            }
          }
        }
      }
    });

    this.LoadBrandByCatId(item.category_ID);

    this.showList(item, index);
  }

  pageChanged(event) {
    this.p = event;
      
      window.scrollTo(0, 0);
    

  }
  priceFilterbyItemId = () => {
    debugger;
    this.CategoriesWithSubCat.sort((a, b) => Number(a.category_DisplayName > b.category_DisplayName) * 2 - 1);
  }

  LoadSubProductCatDatalength: number;

  OpenSubCategorytest(item) {
    debugger;
    this.CateAllData123 = item.category_DisplayName;
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
      CateId: item.category_ID,
      BrandId: this.separatedStringBrand
    }
    this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {
      if (!data.isError) {
        this.LoadProductByCatData = data.data;
          this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
        //this.LoadProductByCatDatalength = 1.5;

        this.LoadProductByCatData.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
        });
        this.FilterListMethodsort(this.selectedshift);
        for (var i = 0; i < this.LoadProductByCatData.length; i++) {
          for (var k = 0; k < this.result3.length; k++) {
            if (this.LoadProductByCatData[i].item_Id == this.result3[k].item_Id) {
              this.LoadProductByCatData[i].qty = this.result3[k].qty;
            }
          }

        }
      }
    });

    
    //this.showList(item, index);
  }


  OpenBrand(item)
  {

    debugger;
    this.CateAllData123 = item.brand_Name;
   
    var obj =
    {
      PriceLevelId: 0,
      CustomerId: 0,
      CateId: this.separatedString,
      BrandId: item.brand_Id,//this.separatedStringBrand
      
    }
    this.apiIntergnService.getProductBrandId(item.brand_Id).subscribe((data) => {
      if (!data.isError) {
        this.LoadProductByCatData = data.data;
        this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
        this.LoadSubProductCatDatalength = this.LoadProductByCatData.length;
        this.LoadProductByCatData.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
        });
        this.FilterListMethodsort(this.selectedshift);
        for (var i = 0; i < this.LoadProductByCatData.length; i++) {
          for (var k = 0; k < this.result3.length; k++) {
            if (this.LoadProductByCatData[i].item_Id == this.result3[k].item_Id) {
              this.LoadProductByCatData[i].qty = this.result3[k].qty;
            }
          }

        }

      }
    });
  }


  OpenLooseItem(LooseItem, mainindex, subIndex, item) {
    debugger;
    this.StorageData = localStorage.getItem('Cart');
    if (this.StorageData != null) {
      this.result2 = JSON.parse(this.StorageData);
    }
    else {
      this.result2 = [];
    }
    mainindex= this.LoadProductByCatData.findIndex(x => x.ItemId == item.ItemId && x.batch_id == item.batch_id);

    //this.LoadProductByCatData.forEach(function (element) {
    //  if (element.ItemId == item.ItemId && element.batch_id == item.batch_id) {

    //  }
    //})

    this.LoadProductByCatData[mainindex].sizedtls.forEach(function (element) {
      element.BackgroundColour = "White";
      element.color = "Black";
    })

    this.LoadProductByCatData[mainindex].sizedtls[subIndex].BackgroundColour = "#e96125";
    this.LoadProductByCatData[mainindex].sizedtls[subIndex].color = "White";


    this.LoadProductByCatData[mainindex].item_SellingPrice = this.LoadProductByCatData[mainindex].sizedtls[subIndex].LItem_SellingPrice;
    this.LoadProductByCatData[mainindex].item_MRP = this.LoadProductByCatData[mainindex].sizedtls[subIndex].LItem_MRP;
    this.LoadProductByCatData[mainindex].isstockavilbl = this.LoadProductByCatData[mainindex].sizedtls[subIndex].Lisstockavilbl;
    this.LoadProductByCatData[mainindex].OfferAmt = this.LoadProductByCatData[mainindex].sizedtls[subIndex].LOfferAmt;
    this.LoadProductByCatData[mainindex].batch_id = this.LoadProductByCatData[mainindex].sizedtls[subIndex].LBatch_id;
    this.LoadProductByCatData[mainindex].weight = this.LoadProductByCatData[mainindex].sizedtls[subIndex].LWeight;
    this.LoadProductByCatData[mainindex].unit_Name = this.LoadProductByCatData[mainindex].sizedtls[subIndex].LUnit_Name;
    this.LoadProductByCatData[mainindex].ItemId = this.LoadProductByCatData[mainindex].sizedtls[subIndex].Litemid;
    this.LoadProductByCatData[mainindex].item_Id = this.LoadProductByCatData[mainindex].sizedtls[subIndex].Litemid;
    this.LoadProductByCatData[mainindex].imagePath = '/Images/' + this.LoadProductByCatData[mainindex].sizedtls[subIndex].LImage_Name;
    var indexLoadProductData = this.result2.findIndex(x => x.ItemId == LooseItem.Litemid);
    if (indexLoadProductData != -1) {
      for (var i = 0; i < this.result2.length; i++) {
        if (LooseItem.Litemid == this.result2[i].ItemId) {
          this.LoadProductByCatData[mainindex].Qty = this.result2[i].QTY;
        }

      }
    }
    else {
      this.LoadProductByCatData[mainindex].Qty = 0;
    }

  }





  GetProductByManufactureId(item) {

    debugger;
    var data = {
      "ManufactureId": item.manufacture_ID,
      "CustomerId": 0//localStorage.getItem('CustId')
    }
    this.apiIntergnService.GetProductByManufactureId(data).subscribe((data) => {
      debugger;
      if (!data.isError) {
        this.LoadProductByCatData = data.data;
        this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
        this.LoadSubProductCatDatalength = this.LoadProductByCatData.length;
        this.LoadProductByCatData.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
        });
        this.FilterListMethodsort(this.selectedshift);
        for (var i = 0; i < this.LoadProductByCatData.length; i++) {
          for (var k = 0; k < this.result3.length; k++) {
            if (this.LoadProductByCatData[i].item_Id == this.result3[k].item_Id) {
              this.LoadProductByCatData[i].qty = this.result3[k].qty;
            }
          }

        }
        this.firstLoad = false;
      }
    });
  }
  workout = null;

  sortbyItem(selected) {

    console.log(selected);
  }
  selectChangeHandler(event: any) {

    console.log(event);
  }
  SubCategoriesLength: any;
  categoryNamesShow: any;
  CategoriesWithSubCat: any;
  CategoriesWithSubCat1: any;
  CategoriesWithSubCatfind: [];
    CategoriesWithSubCatlength: number = 0;

    CategoryNavigateData: any;
    CategoryNavigate: any;
    SpecCategoryNavigate: any;
    public GetCategoriesNavigation() {
        debugger;
        this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
          if (!data.isError)

          {
            debugger;
                this.CategoryNavigateData = data.data;
                var t = this.CategoryNavigateData.find(list => list.category_ID == this.catid);
                this.CategoryNavigateData = [t];
                this.CategoryNavigate = this.CategoryNavigateData[0].subCategories;
                
                this.CategoryNavigate.forEach(function (element)
                {
                    element.background = 'white';
                  element.color = '#183f52';
                })
                this.catid = this.CategoryNavigate[0].subCate_ID;
                this.CategoryNavigate[0].background = '#183f52';
                this.CategoryNavigate[0].color = 'white';
                this.LoadProductByCat();
            }
        });
    }
    
    testDemo = [];
    index: number;
    CategoryNavigateLength: number=0;
    public GetSubCateNavigation() {
        debugger;
        this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
            if (!data.isError) {
                this.CategoryNavigateData = data.data;
                for (let i = 0; i < this.CategoryNavigateData.length; i++)
                {
                   
                    for (var j in this.CategoryNavigateData[i].subCategories)
                    {
                        if (this.CategoryNavigateData[i].subCategories[j].subCate_ID == this.catid)
                        {
                            this.index = i;
                            break;
                        }
                    }
                    
                    
                }
                this.CategoryNavigateData = [this.CategoryNavigateData[this.index]];
                this.CateAllData123 = this.CategoryNavigateData[0].category_DisplayName;
               
                this.CategoryNavigate = this.CategoryNavigateData[0].subCategories;
                this.CategoryNavigateLength = this.CategoryNavigate.length;
                this.CategoryNavigate.forEach(function (element) {
                    element.background = 'white';
                    element.color = '#183f52';
                })
                var index = this.CategoryNavigate.map(function (e) { return e.subCate_ID; }).indexOf(this.catid);
                this.CategoryNavigate[index].background = '#183f52';
                this.CategoryNavigate[index].color = 'white';
                console.log(index);
            }
        });
    }
    SubCatIndex: number;
    SepCatIndex: number;
    SpecNav: any;
    SpecName: string;
    public GetSpecificCateNavigation() {
        debugger;
        this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
            if (!data.isError) {
                this.CategoryNavigateData = data.data;
                for (let i = 0; i < this.CategoryNavigateData.length; i++) {

                    for (var j in this.CategoryNavigateData[i].subCategories)
                    {
                        for (var K in this.CategoryNavigateData[i].subCategories[j].secification)
                        {
                            if (this.CategoryNavigateData[i].subCategories[j].secification[K].subSpc_ID == this.catid)
                            {
                               this.index = i;
                                
                                break;
                            }
                        }
                    }


                }
                this.CategoryNavigateData = [this.CategoryNavigateData[this.index]];
                this.CateAllData123 = this.CategoryNavigateData[0].category_DisplayName;

                this.CategoryNavigate = this.CategoryNavigateData[0].subCategories;
                this.CategoryNavigateLength = this.CategoryNavigate.length;
                this.CategoryNavigate.forEach(function (element) {
                    element.background = 'white';
                  element.color = '#183f52';
                })
                for (let i = 0; i < this.CategoryNavigate.length; i++)
                {
                    for (let j = 0; j < this.CategoryNavigate[i].secification.length;j++)
                    {
                        if (this.CategoryNavigate[i].secification[j].subSpc_ID == this.catid)
                        {
                            this.SubCatIndex = i;
                            this.SepCatIndex = j;
                            break;
                        }
                    }
                    //if (this.CategoryNavigate[i].SubSpc_ID == this.catid)
                    //{
                    //    this.SubCatIndex = i;
                    //    break;
                    //}
                }
                this.SpecialisationNav = true;
                this.SpecificationName = this.CateAllData123;
                this.SpecCategoryNavigate = [this.CategoryNavigate[this.SubCatIndex]];
                this.SpecName = this.SpecCategoryNavigate[0].subCateName;
                this.SpecNav = this.SpecCategoryNavigate[0].secification;
                //var index = this.CategoryNavigate.map(function (e) { return e.SubCate_ID; }).indexOf(this.catid);
                //this.CategoryNavigate[index].background = '#4CAF50';
                //this.CategoryNavigate[index].color = 'white';
                console.log(this.SpecCategoryNavigate);
            }
        });
    }
  public CategoriesWithSub()
  {
    debugger;
    this.apiIntergnService.getCategoriesWithSubCat().subscribe((data) => {

      if (data != null && !data.isError)
      {
        this.CategoriesWithSubCat1 = data.data;

        this.SubCategoriesLength = data.data;


        
        if (this.CategoriesWithSubCat1.length != 0) {


          this.CategoriesWithSubCat1.forEach(function (element) {
              if (element!=null) {
                  element.subcategory = false;

                  window.scrollTo(0, 0)
              }
              
          })
            debugger;
            this.CategoriesWithSubCatlength = 0;
            if (this.catid == 0)
            {
                this.CategoriesWithSubCat = this.CategoriesWithSubCat1;
                this.CategoriesWithSubCatlength = this.CategoriesWithSubCat.length;
            }
            else
            {
                var t = this.CategoriesWithSubCat1.find(list => list.category_ID == this.catid)

                this.CategoriesWithSubCat = [t];
                this.CategoriesWithSubCatlength = this.CategoriesWithSubCat.length;
            }
         

            
          }
      
        
      }
    });
  }
  loadBrand: any;
  loadManufacture: any;
  public LoadBrand() {
   
    this.apiIntergnService.getAllBrand().subscribe((data) => {
      if (!data.isError) {
        this.loadBrand = data.data;

      }
    });
  }

  public LoadManufactureByCatid(Id) {

    debugger;
    this.apiIntergnService.GetManufactureByCatid(Id).subscribe((data) => {
      if (!data.isError) {
        this.loadManufacture=data.data;

      }
    });
    }
    MobileSubCat: any;
    LoadProductBySubCatData(Items,index)
    {
        debugger;
        console.log(Items);
        this.catid = Items.subCate_ID;
        this.CategoryNavigate.forEach(function (element) {
            element.background = 'white';
          element.color = '#183f52';
        });
        this.p = 1;
        this.MobileSubCat = Items.secification;
      this.CategoryNavigate[index].background = '#183f52';
        this.CategoryNavigate[index].color = 'white';
        this.LoadProductByCat();

    }

    LoadProductBySpecificationData(SubItems)
    {
        debugger;
        this.catid = SubItems.subSpc_ID;
        this.LoadProductByCat();
    }
    OpenSubCategoryNav(catid, CateName)
    {
        debugger;
        this.apiIntergnService.CatName = CateName;
        //this.listShow = false;
        this.router.navigate(['./shop/' + CateName + '/' + catid.subCate_ParenUnderID + catid.subCate_ID]);
        for (let myChild of this.CateAllData) {
            myChild.BackgroundColour = "white";
        }

        catid.BackgroundColour = "lightGrey";
        var x = document.getElementById("navbarText");
        x.className = 'collapse navbar-collapse';

    }
    OpenSpecificCategoryNav(catid, CateName) {
        this.apiIntergnService.CatName = CateName;
        
        this.router.navigate(['./shop/' + CateName + '/' + catid.subSpc_ParenUnderID + catid.subSpc_ID]);
        for (let myChild of this.CateAllData) {
            myChild.BackgroundColour = "white";
        }

        catid.BackgroundColour = "lightGrey";

    }
    OpenMobilSpecificCategoryNav(catid)
    {
        this.apiIntergnService.CatName = catid.subSpcName;

        this.router.navigate(['./shop/' + catid.subSpcName + '/' + catid.subSpc_ParenUnderID + catid.subSpc_ID]);
        for (let myChild of this.CateAllData) {
            myChild.BackgroundColour = "white";
        }

        catid.BackgroundColour = "lightGrey";

    }
  LoadProductByCatData: any;
 
  public LoadProductByCat() {
    debugger;
   
    var obj = {
      CatId : this.catid,
        BrandId : 0,
      PricelevID: 0,
      CustomerId: this.CustomerId
    }
    if (this.APICall == true) {
      this.APICall = false;
      this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {
        if (!data.isError) {
          debugger;
          this.firstLoad = false;
          this.APICall = true;
          this.LoadProductByCatData = data.data;
          this.p = 1;
          console.log("load product data from shop page:", this.LoadProductByCatData);
          this.LoadProductByCatDatalength = this.LoadProductByCatData.length;
          this.LoadProductByCatData.forEach(function (element) {
            element.Litemid = 0;
            element.LBatch_id = 0;
            element.ItemId = element.item_Id;
            element.qty = 0;
            window.scrollTo(0, 0);
            element.sizedtls = JSON.parse(element.sizedtls);
            element.batchDetails = JSON.parse(element.batchDetails);
            element.batchDetails.forEach(function (batch) {
              batch.Batch_qty = 0;
            })
            window.scrollTo(0, 0)
          });
          debugger;

          for (var i = 0; i < this.LoadProductByCatData.length; i++) {
            if (this.LoadProductByCatData[i].sizedtls != null) {
              this.LoadProductByCatData[i].sizedtls.forEach(function (element) {
                element.BackgroundColour = "White";
                element.color = "Black";
              })

              this.LoadProductByCatData[i].item_Id = this.LoadProductByCatData[i].sizedtls[0].Litemid;
              this.LoadProductByCatData[i].item_MRP = this.LoadProductByCatData[i].sizedtls[0].LItem_MRP;
              this.LoadProductByCatData[i].item_SellingPrice = this.LoadProductByCatData[i].sizedtls[0].LItem_SellingPrice;
              this.LoadProductByCatData[i].isstockavilbl = this.LoadProductByCatData[i].sizedtls[0].Lisstockavilbl;
              this.LoadProductByCatData[i].unit_Name = this.LoadProductByCatData[i].sizedtls[0].LUnit_Name;
              this.LoadProductByCatData[i].weight = this.LoadProductByCatData[i].sizedtls[0].LWeight;
              this.LoadProductByCatData[i].offerAmt = this.LoadProductByCatData[i].sizedtls[0].LOfferAmt;
              this.LoadProductByCatData[i].batch_id = this.LoadProductByCatData[i].sizedtls[0].LBatch_id;
              this.LoadProductByCatData[i].item_Code = this.LoadProductByCatData[i].sizedtls[0].LItem_Code;
              this.LoadProductByCatData[i].imagePath = '/Images/' + this.LoadProductByCatData[i].sizedtls[0].LImage_Name;
              for (var j = 0; j < this.LoadProductByCatData[i].sizedtls.length; j++) {
                this.LoadProductByCatData[i].sizedtls[0].BackgroundColour = "#e96125";
                this.LoadProductByCatData[i].sizedtls[0].color = "White";
              }
            }
          }

          this.FilterListMethodsort(this.selectedshift);
          for (var i = 0; i < this.LoadProductByCatData.length; i++) {
            for (var k = 0; k < this.result3.length; k++) {
              if (this.LoadProductByCatData[i].item_Id == this.result3[k].item_Id && this.LoadProductByCatData[i].batch_id == this.result3[k].batch_id  ) {
                this.LoadProductByCatData[i].qty = this.result3[k].qty;
              }
            }

          }
        }
      });
    }
   
  }
  AddToCart(ProductsInfo: ProductInfoModal) {

    let CartInfo: Cart = new Cart();
    CartInfo.AddedInCartDate = new Date();
    CartInfo.ItemId = ProductsInfo.item_Id;
    CartInfo.ProductName = ProductsInfo.productName;
    CartInfo.ProductPrice = ProductsInfo.productPrice;
    CartInfo.BatchId = ProductsInfo.batch_id;
    CartInfo.QTY = 1;
    CartInfo.IsDecreaseQty = ProductsInfo.isDecreaseQty;
    CartInfo.TotalProductPrice = (CartInfo.ProductPrice) * (CartInfo.QTY);
    var id = localStorage.getItem('CustId');
    CartInfo.UserId = Number(id);
    this.apiIntergnService.AddCart(CartInfo)
      .subscribe(response => {
        if (response) {

          //this.appComp.getTotalAmounts();

        }
        else {
          alert("Something went wrong please try again")
        }
      });

  }
  UserId: number = 0;
  result99 = [];
  OpenTopSaver(item, index, objectName)
  {
    debugger;
    this.UserId = Number(localStorage.getItem('CustId'));
    var prod = new ProductInfoModal();
    this.StorageData = localStorage.getItem('Cart');
    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));
    //if (prod.UserId == 0) {
    //  this.LoginModalOpen();
    //  return;
    //}

    this.BatchId = item.batchDetails[0].Batch_Id;
    this.UomId   = item.batchDetails[0].Unit_Id;
    if (this.LoadProductByCatData != undefined) {

      var ProductDataIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
    }
    if (this.TopOffersData != undefined) {

      var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == item.item_Id);
    }

    if (this.StorageData != null)
    {
      this.result99 = JSON.parse(this.StorageData);
    }
   
      if (objectName == 'Products')
      {
        prod.qty = this.LoadProductByCatData[ProductDataIndex].qty = 1;
        if (TopPopularIndex != null && TopPopularIndex != -1) {
          this.TopOffersData[TopPopularIndex].qty = 1;
        }
      }
      else if (objectName == 'TopPopular') {
        prod.qty = this.TopOffersData[TopPopularIndex].qty = 1;
        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.LoadProductByCatData[ProductDataIndex].qty = 1;
        }
    } if (item.imagePath == undefined) {
      item.imagePath = item.path
    }
    if (item.batchId != undefined) {
      item.batch_id = item.batchId
    }
    if (item.batchDetails != null) {
      for (var k = 0; k < item.batchDetails.length; k++) {
        if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
          item.batch_id = item.batchDetails[k].Batch_Id,
            item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
            item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
            item.uomId = item.batchDetails[k].Unit_Id
          break;
        }
      }
    }

    prod.Batch_qty = 1
    prod.itemId = item.item_Id;
    prod.item_Id = item.item_Id;
    prod.productName = item.productName;
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.item_MRP = item.item_MRP;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.offers = item.offerAmt;
    prod.imagePath = item.imagePath;
    prod.uomId = item.uomId;

    var t = this.result99.find(list => list.item_Id == prod.itemId)
      console.log(t);


      if (t) {
        console.log(this.result3);
        this.result99.forEach((element, index) => {
          element.qty = prod.qty;
        })
      }
      else {
        this.result99.push(prod);
        console.log(this.result99);
        prod.CustomerID = Number(localStorage.getItem('CustId'));
        localStorage.setItem('Cart', (JSON.stringify(this.result99)));
      }
    this.appComp.TotalItem = this.result99.length;

    if (prod.UserId > 0) {
      debugger;
      var PreCartData = prod;
      PreCartData.qty = 1;
      this.apiIntergnService.AddPreSingleCart(PreCartData)
        .subscribe(Val => {
          debugger;
          if (!Val.isError) {
            this.result99.forEach((element, _index) => {
              if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                element.qty = Val.data.qty;
              }
            });
            localStorage.setItem('Cart', (JSON.stringify(this.result99)));
          }
        });
    }
    localStorage.setItem('Cart', (JSON.stringify(this.result99)));
  }

  quantityChanged(index, type, item, objectName) {
    debugger;
    if (item.batchDetails != null || item.batchDetails != undefined) {
      this.BatchId = item.batchDetails[0].Batch_Id;
      this.UomId = item.batchDetails[0].Unit_Id;}

    if (this.router.url.includes("/shop/")) {
      this.appComp.changed = true;
      var qty = 0;
      this.StorageData = localStorage.getItem('Cart');
      if (this.StorageData != null) {
        this.result99 = JSON.parse(this.StorageData);
      }
      if (this.LoadProductByCatData != undefined) {
        var ProductDataIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
      }
      else {
        var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == item.item_Id);
      }

      var prod = new ProductInfoModal();

      this.UserId = Number(localStorage.getItem('CustId'));
      //if (this.UserId == 0) {
      //  this.LoginModalOpen();
      //  return;
      //}

      if (objectName == 'Products') {


        if (type == 'Increament') {
          this.LoadProductByCatData[ProductDataIndex].qty += 1;
        }
        else {
          this.LoadProductByCatData[ProductDataIndex].qty -= 1;
          prod.isDecreaseQty = true;
        }
        qty = this.LoadProductByCatData[ProductDataIndex].qty
        if (TopPopularIndex != null && TopPopularIndex != -1) {
          this.TopOffersData[TopPopularIndex].qty = qty;
        }



      }
      else if (objectName == 'TopPopular') {
        var prod = new ProductInfoModal();
        var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == item.item_Id);

        if (type == 'Increament') {
          this.TopOffersData[TopPopularIndex].qty += 1;
        }
        else {
          this.TopOffersData[TopPopularIndex].qty -= 1;
          prod.isDecreaseQty = true;
        }
        qty = this.TopOffersData[TopPopularIndex].qty
        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.LoadProductByCatData[ProductDataIndex].qty = qty;
        }

      }
      else if (objectName == 'Delete') {
        var prod = new ProductInfoModal();
        prod.isDecreaseQty = true;
        qty = 0
      }
      if (item.imagePath == undefined) {
        item.imagePath = item.path;
      }
      if (item.batchId != undefined) {
        item.batch_id = item.batchId
      }

     
      if (item.batchDetails != null && item.batchDetails != undefined) {
        if (item != null) {
        for (var k = 0; k < this.result99.length; k++) {
          if (this.result99[k].uomId == this.UomId && (this.result99[k].batchId || this.result99[k].batch_id) == this.BatchId) {
            if (this.result99[k].Batch_qty == undefined) {
              this.result99[k].Batch_qty = qty;
            }
            prod.Batch_qty = this.result99[k].Batch_qty;

            break;
          }
        }
          for (var k = 0; k < item.batchDetails.length; k++) {
            if (item.batchDetails[k].Batch_Id == this.BatchId && item.batchDetails[k].Batch_UOMId == this.UomId) {
              item.batch_id = item.batchDetails[k].Batch_Id,
                item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
                item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
                item.uomId = item.batchDetails[k].Unit_Id;
              prod.Batch_qty = item.qty;
              prod.Batch_qty = this.result99[k].Batch_qty;
              if (type == 'Increament') {
                if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
                prod.Batch_qty += 1;
              }
              else {
                if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
                  alert("Can't Find The Product With Selected Size In Cart");
                  return;
                }
                prod.Batch_qty -= 1;
              }

              break;
            }
          }
        }
        else {
          for (var k = 0; k < this.result99.length; k++) {
            if ((this.result99[k].batchId == item.batchId) || (this.result99[k].batch_id == item.batchId)) {
              prod.Batch_qty = this.result99[k].Batch_qty;

            }
          }
          if (type == 'Increament') {
            if (prod.Batch_qty == undefined) { prod.Batch_qty = 0 }
            prod.Batch_qty += 1;
          }
          else {
            if (prod.Batch_qty == undefined || prod.Batch_qty == 0) {
              alert("Can't Find The Product With Selected Unit In Cart");
              return;
            }
            prod.Batch_qty -= 1;
          }
        }
      }


      prod.itemId = item.item_Id;
      prod.item_Id = item.item_Id;
      prod.productName = item.productName;
      prod.productPrice = item.item_SellingPrice;
      prod.batch_id = item.batch_id;
      prod.batchId = item.batch_id;
      prod.item_MRP = item.item_MRP;
      prod.weight = item.weight;
      prod.unit = item.unit_Name;
      prod.uomId = item.uomId;
      prod.offers = item.offerAmt;
      prod.qty = qty;
      prod.imagePath = item.imagePath;



      this.result99.forEach((element, index) => {
        if (element.item_Id == prod.item_Id && (element.batchId || element.batch_id) == prod.batchId) {
          element.qty = prod.qty;
          element.Batch_qty = prod.Batch_qty

          if (prod.Batch_qty == 0) {
            const index = this.result2.findIndex(list => list.item_Id == prod.itemId && (element.batchId == prod.batchId || element.batch_id == prod.batchId));
            this.result99.splice(index, 1);

          }
        }
      });


      this.appComp.cartItems = this.result99;
      prod.CustomerID = Number(localStorage.getItem('CustId'));
      prod.UserId = Number(localStorage.getItem('CustId'));
      if (prod.UserId > 0) {
        debugger;
        var PreCartData = prod;
        PreCartData.qty = 1;
        this.apiIntergnService.AddPreSingleCart(PreCartData)
          .subscribe(Val => {
            debugger;
            if (!Val.isError) {
              this.result99.forEach((element, _index) => {
                if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                  element.qty = Val.data.qty;
                }
              });
              localStorage.setItem('Cart', (JSON.stringify(this.result99)));
            }
          });
      }
      localStorage.setItem('Cart', (JSON.stringify(this.result99)));
      this.StorageData = localStorage.getItem('Cart');

      this.appComp.TotalItem = this.result99.length;
    }
  }
  TopOffersData: any;
  public LoadTopOffers() {
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
    }
    this.apiIntergnService.getTopOffers(obj).subscribe((data) => {
      if (!data.isError) {
        this.TopOffersData = data.data;
        this.TopOffersData.forEach(function (element) {
          element.qty = 0;
          element.batchDetails = JSON.parse(element.batchDetails);
          element.batchDetails.forEach(function (batch) {
            batch.Batch_qty = 0;
          })
          window.scrollTo(0, 0)
        });
        for (var i = 0; i < this.TopOffersData.length; i++) {
          for (var k = 0; k < this.result3.length; k++) {
            if (this.TopOffersData[i].item_Id == this.result3[k].item_Id && this.TopOffersData[i].batch_id == this.result3[k].batch_id) {
              this.TopOffersData[i].qty = this.result3[k].qty;
            }
          }

        }
      }
    });
  }
  CateAllData: any;
  CateAllData123: any;
  public LoadCategory() {
    debugger;
    this.apiIntergnService.getAllCategories().subscribe((data) => {
      if (!data.isError) {
        this.CateAllData = data.data;
        //this.CateAllData123 = data.Data[0].Category_DisplayName;
        window.scrollTo(0, 0)
      }
    });
  }



  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
    window.scrollTo(0, 0)
  }

  workouts = [

    //{
    //  name: "Relevance",
    //  id: 1
    //},
    {
      name: "Price (Low to High)",
      id: 2
    },
    {
      name: "Price (High to Low)"
    },
    {
      name: "Discount (High to Low)"
    },
    {
      name: "Name(A to Z)"
    }




  ]
  imagesofsection4 = [
    {

      lable: "All Fruits"


    },
    {

      lable: "Fresh & Herbs",
      offer: "5% OFF"
    },
    {

      lable: "Seasonal Fruits"
    },
    {

      lable: "Imported Fruits",
      offer: "NEW"
    },
    {

      lable: "Citrus"
    },
    {


      lable: "Cut Fresh & Herbs"
    },
    {

      lable: "Seasonal Fruits"
    },
    {

      lable: "Fresh & Herbs"
    }




  ]


  //imagesofsection2 = [
  //  {

  //    image: "../assets/img/item/6.jpg",

  //  },
  //  {

  //    image: "../assets/img/item/7.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/8.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/1.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/2.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/3.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/4.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/5.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/6.jpg"
  //  }



  //]



  carouselOptionsForSection4 = {
    margin: 25,
    nav: true,
    dots: false,

    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      600: {
        items: 3,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  carouselOptionsForSection6 = {
    margin: 10,
    nav: false,
    dots: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
        dots: false
      },
      600: {
        items: 2,
        nav: false,
        dots: false
      },
      1000: {
        items: 2,
          nav: false,
        loop: false,
        dots: false
      },
      1500: {
        items: 2,
          nav: false,
        loop: false,
        dots: false
      }
    }
  }

  carouselOptionsForSection1 = {
    margin: 15,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
        dots: false
      },
      600: {
        items: 2,
        nav: false,
        dots: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }
}
