import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { CartModel } from '../Models/cart-model';
import { ModalDirective } from 'ngx-bootstrap';
import { debug } from 'util';
import { async } from 'rxjs/internal/scheduler/async';
declare var $: any;
@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})
export class MyOrderComponent implements OnInit
{
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  @ViewChild('MyOrderDetails', { static: false }) MyOrderDetails: ModalDirective;
  constructor(private router: Router, private apiIntergnService: ApiIntergnService)

  { }
  pnlAccount: boolean;

  OrderNumber: string; DeliveryCharges: number; CartProductPrice: number;
  OrderStatus: number; pnlDetails: boolean;
  PaymentStatus: string;
  PurchaseDate: Date;
  TotalAmount: number;
  PaymentMode: number;
  DeliveryType: string;
  Address: string;
  ProductlistData: any;
  
  ngOnInit()
  {
    
    this.CustomerAddress();
    var id = Number(localStorage.getItem('CustId'));
    console.log(id);
    this.apiIntergnService.GetMyProducts(id)
      .subscribe(data => {
        debugger;
        if (data) {
          this.MyOrdersDetails = data.data;
          console.log("my order details",data.data)

        }
      }
    );

    $('.accordian-body').on('show.bs.collapse', function () {
      $(this).closest("table")
        .find(".collapse.in")
        .not(this)
        .collapse('toggle')
    })




    $(function () {
      $(".accordian h3").click(function (e) {
        //window.scroll(0, 0);
        $("accordian h3")
          .find(".mdi mdi-chevron-down open")
          .toggleClass("open");
        $(
          $(e.target)
            .find(".mdi mdi-chevron-down")
            .toggleClass("open")
        );
        $(".accordian ul ul").slideUp();
        if (
          $(this)
            .next()
            .is(":hidden")
        ) {
          $(this)
            .next()
            .slideDown();
        }
      });
    });


  }




  MyOrdersDetails: CartModel[];
  UserId: number = 0;
  LogoutLocalStorage = [];
  StorageData: string;
  LogOut()
  {
    debugger;
    this.StorageData = localStorage.getItem('Cart');
    console.log(this.StorageData);
    this.LogoutLocalStorage = JSON.parse(this.StorageData);
    localStorage.clear();
    this.router.navigateByUrl('/Home');
    this.UserId = 0;
    location.reload();
    localStorage.setItem('Cart', (JSON.stringify(this.LogoutLocalStorage)));
    //this.ngOnInit();
  }


  AccountForm() {
    debugger;
    this.pnlAccount = false;
    this.router.navigate(['./Account']);
    window.scrollTo(0, 0)
  }


  MyOrderForm() {
    this.pnlAccount = false;
    this.router.navigate(['./MyOrder']);
    window.scrollTo(0, 0)
  }


  GetMyProducts()
  {

    debugger;
    var id = Number(localStorage.getItem('CustId'));
    console.log(id);
    this.apiIntergnService.GetMyProducts(id)
      .subscribe(data =>
      {
        debugger;
        if (data)
        {
          this.MyOrdersDetails = data.data;

        }
      }
      );

  }
  
  public ProductDetailsSummary(id: number)
  {
    debugger;
    this.router.navigate(['./OrderDetails/' + id]);
    window.scrollTo(0, 0)
  }


  CustomerInfo: any; CustomerName: string; CustomerPhoneNo: string;
  CustomerAddress()
  {
    debugger;
    var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
    this.CustomerInfo = JSON.parse(CustomerDetails);
    console.log(this.CustomerInfo);
    this.CustomerName = this.CustomerInfo.data.customer_Name;
    this.CustomerPhoneNo = this.CustomerInfo.data.customer_Mobile;

    //return this.Address;
  }


  
  showBtn = -1;
  myFunction(index, id)
  {
    debugger;
    this.showBtn = index;
    this.LoadProductDetails(id);
      //this.pnlDetails = !this.pnlDetails;
  }
  
  LoadProductDetails(id)
  {
    debugger;
     this.apiIntergnService.GetMyProductDetails(id)
      .subscribe( data =>
      {
        console.log("All cart detail Data",data.data)
        if (data)
        {
         // alert("api response"+ data.data[0]);
          this.OrderNumber = data.data[0].orderNo;
          this.OrderStatus = data.data[0].approvalStatus;
          this.PaymentStatus = data.data[0].paymentStatus;
          this.PurchaseDate = data.data[0].createdDate;
          this.CartProductPrice = data.data[0].totalItemPrice;
          this.TotalAmount = data.data[0].totalItemPrice + data.data[0].deliveryCharges;
          this.PaymentMode = data.data[0].paymentType;
          this.DeliveryType = data.data[0].deliveryType;
          this.Address = data.data[0].address;
          this.DeliveryCharges = data.data[0].deliveryCharges;
          this.ProductlistData = data.data[0].cartDTdata;
        }
      }
      );
  }


}
