export class ProductInfoModal
{
  //ItemId: number;
  //Item_Id: number;
  //Item_Name: string
  //Item_ShortName: string;
  //Item_Code: string;
  //Item_Desc: string;
  //Item_ProductType: string;
  //ImagePath: string;
  //Item_UOMID: number;
  //Item_ItemCatId: number;
  //Item_ItemSubCatId: number;
  //Item_SpecificationID: number;
  //Item_SupplierId: number;
  //Item_ManufactureDivID: number;
  //Item_ManufactureID: number;
  //Item_SalesmanID: number;
  //Item_DeliveryboyID: number;
  //Item_CostPrice: number;
  //Item_BrandID: number;
  //Item_LandingCost: number;
  //Item_SellingPrice: number;
  //Price: string;
  //Item_MRP: number;
  //Item_MinimumSelling: number
  //Item_SellingPricePerUnit: number
  //Item_purchasetaxID: number
  //Item_salestaxID: number
  //Item_APMCID: number
  //Item_EANCode: string;
  //Item_SystemCode: string;
  //Item_WeightCode: string;
  //Item_MinimumStock: number;
  //Item_MaximumStock: number;
  //Item_Allownegativestock: boolean;
  //Item_HSNNo: string;
  //Item_Extra3: string;
  //Item_Extra4: string;
  //Item_Extra5: string;
  //Item_GSTTaxID: number;
  //Item_PurchaseGSTCalculateOn: string;
  //Item_PurchaseAbatementPercent: number;
  //Item_salesGstCalculateOn: string;
  //Item_SalesAbatementPercent: number;
  //Item_WarrantyPeriod: string;
  //Item_BranchId: number;
  //Item_CompanyId: number;
  //Item_PosId: number;
  //Item_Extra1: string;
  //Item_Extra2: string;
  //Item_ExtraDate: Date;
  //Item_Status: string;
  offers: string;
  discount: string;
  //Item_IsDelete: boolean;
  //Item_Createdby: number;
  //Item_CreatedDate: Date;
  //Item_ModifiedBy: number;
  //Item_ModifiedDate: Date;
  //Identifier: string;
  //Item_AttributeSize: number;
  //Item_AttributeSize2: string;
  //Item_AttributeColor: number;
  //Item_AttributeColor2: string;
  //Item_AttributeSizeDesignNo: string;
  //Item_AttributeExtra1: string;
  //Item_AttributeExtra2: string;
  //Item_AttributeExtra3: string;
  //Item_BookType: number;
  //Item_AllCompanyAccess: string;
  //SizeDetail_name: string;
  //SizeDetail_id: number;
  //Data: any;
  //ProductPrice: number;
  CustomerID: number;
  UserId: number;
//  QTY: number;
  isChecked: boolean;
  //ProductName: string;
  //ProductPackId: number;
  //ListofSizes: any;
  //Grade: string;
  //Unit: string;
  //Weight: string;
  //Batch_id: number;
  //BatchId: number;
  //OfferAmt: string;
  //Image: any;
  //Path: any;
  //IsDecreaseQty: boolean = false;
  //Unit_Name: string;
  //UomId: number;
  //TotalProductPrice: number;



  //------------------------------------small case variables-----------------------------
  itemId: number;
  item_Id: number;
  item_Name: string
  item_ShortName: string;
  item_Code: string;
  item_Desc: string;
  item_ProductType: string;
  imagePath: string;
  item_UOMID: number;
  item_ItemCatId: number;
  item_ItemSubCatId: number;
  item_SpecificationID: number;
  item_SupplierId: number;
  item_ManufactureDivID: number;
  item_ManufactureID: number;
  item_SalesmanID: number;
  item_DeliveryboyID: number;
  item_CostPrice: number;
  item_BrandID: number;
  item_LandingCost: number;
  item_SellingPrice: number;
  irice: string;
  item_MRP: number;
  item_MinimumSelling: number
  item_SellingPricePerUnit: number
  item_purchasetaxID: number
  item_salestaxID: number
  item_APMCID: number
  item_EANCode: string;
  item_SystemCode: string;
  item_WeightCode: string;
  item_MinimumStock: number;
  item_MaximumStock: number;
  item_Allownegativestock: boolean;
  item_HSNNo: string;
  item_Extra3: string;
  item_Extra4: string;
  item_Extra5: string;
  item_GSTTaxID: number;
  item_PurchaseGSTCalculateOn: string;
  item_PurchaseAbatementPercent: number;
  item_salesGstCalculateOn: string;
  item_SalesAbatementPercent: number;
  item_WarrantyPeriod: string;
  item_BranchId: number;
  item_CompanyId: number;
  item_PosId: number;
  item_Extra1: string;
  item_Extra2: string;
  item_ExtraDate: Date;
  item_Status: string;
  item_IsDelete: boolean;
  item_Createdby: number;
  item_CreatedDate: Date;
  item_ModifiedBy: number;
  item_ModifiedDate: Date;
  identifier: string;
  item_AttributeSize: number;
  item_AttributeSize2: string;
  item_AttributeColor: number;
  item_AttributeColor2: string;
  item_AttributeSizeDesignNo: string;
  item_AttributeExtra1: string;
  item_AttributeExtra2: string;
  item_AttributeExtra3: string;
  item_BookType: number;
  item_AllCompanyAccess: string;
  sizeDetail_name: string;
  sizeDetail_id: number;
  data: any;
  productPrice: number;
  customerID: number;
  userId: number;
  qty: number;
  productName: string;
  productPackId: number;
  listofSizes: any;
  grade: string;
  unit: string;
  weight: string;
  batch_id: number;
  batchId: number;
  offerAmt: string;
  image: any;
  path: any;
  isDecreaseQty: boolean = false;
  unit_Name: string;
  uomId: number;
  totalProductPrice: number;
  SizeId: number;
  SizeNo: string;
  Batch_qty: number;
}
