export class UrlClass
{
  
 // public static BASE_API_URL: string = 'https://localhost:44367/API/';  //API URL


  public static BASE_IMG_URL: string = 'http://118.139.165.196:3636';  //Image URL


  public static BASE_API_URL: string = 'http://118.139.165.196:3032/API/'; //Morbiwala
    

}
