export class FavouriteModel {
  Favt_Id: number;
  Favt_customerId: number;
  Favt_ItemId: number;
  Favt_CreateBy: number;
  Favt_ModififedBy: number;
  Favt_CreatedDate: Date;
  Favt_ModififedDate: Date;
  Favt_IsDelete: boolean;
  Favt_BatchId: number;
  Mode: string;
}
