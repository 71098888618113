import { Component, OnInit } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  catid: any;
  constructor(private router: Router,private apiIntergnService: ApiIntergnService) { }

  ngOnInit() {
    this.LoadCategory();
  }
  CateData: any;
  public LoadCategory() {

    this.apiIntergnService.getAllCategories().subscribe((data) => {
       
      if (!data.isError) {
        this.CateData = data.data;
      }
    });
  }
  openshopbyid(item)
  {
    this.router.navigateByUrl('/shop/' + item);
  }

  imagesofsection2 = [
    {

      image: "../assets/img/item/6.jpg",
      text:"12346"

    },
    {

      image: "../assets/img/item/7.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/8.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/1.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/2.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/3.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/4.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/5.jpg",
      text: "12346"
    },
    {

      image: "../assets/img/item/6.jpg",
      text: "12346"
    }
   



  ]

   

}
