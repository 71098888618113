import { Component, OnInit } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
declare var $: any;
@Component({
  selector: 'app-my-order-details',
  templateUrl: './my-order-details.component.html',
  styleUrls: ['./my-order-details.component.css']
})
export class MyOrderDetailsComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  constructor(private router: Router, private route: ActivatedRoute,private apiIntergnService: ApiIntergnService)
  { }
 
  OrderNumber: string; DeliveryCharges: number; CartProductPrice: number;
  OrderStatus: number;
  PaymentStatus: string;
  PurchaseDate: Date;
  TotalAmount: number;
  PaymentMode: number;
  CartId: number;
  DeliveryType: string;
  Address: string;
  ProductlistData: any;
  IsOrderCancel: boolean = false;
  ngOnInit() {


    this.route.params.subscribe(params =>
    {
      var id;
      id = params['id'];
      this.CartId = id;
      this.LoadProductDetails(id);
    });

    $(function () {
      $(".accordian h3").click(function (e) {
        //window.scroll(0, 0);
        $("accordian h3")
          .find(".mdi mdi-chevron-down open")
          .toggleClass("open");
        $(
          $(e.target)
            .find(".mdi mdi-chevron-down")
            .toggleClass("open")
        );
        $(".accordian ul ul").slideUp();
        if (
          $(this)
            .next()
            .is(":hidden")
        ) {
          $(this)
            .next()
            .slideDown();
        }
      });
    });



  }
  OrderCancel()
  {
    this.apiIntergnService.EcomOrderCancelByCartId(this.CartId).subscribe(data => {
      if (!data.isError)
      {
        alert("Successfully Cancel Your Order");
        this.IsOrderCancel = false;
      }
    })
    this.LoadProductDetails(this.CartId);
    
  }
  cartItemsCal: any; TotalItem: number = 0; TotalAmountOfProduct: number = 0;
  LoadProductDetails(id)
  {
    this.apiIntergnService.GetMyProductDetails(id)
      .subscribe(data => {
        if (data) {
          console.log(data);
          this.OrderNumber = data.data[0].orderNo;
          this.OrderStatus = data.data[0].approvalStatus;
          this.PaymentStatus = data.data[0].paymentStatus;
          if (this.PaymentStatus == 'Pending')
          {
            this.IsOrderCancel = true;
          }
          this.PurchaseDate = data.data[0].createdDate;
          this.CartProductPrice = data.data[0].totalItemPrice;
          this.TotalAmount = data.data[0].totalItemPrice + data.data[0].deliveryCharges;
          this.PaymentMode = data.data[0].paymentType;
          this.DeliveryType = data.data[0].deliveryType;
          this.Address = data.data[0].address;
          this.DeliveryCharges = data.data[0].deliveryCharges;
          this.ProductlistData = JSON.parse(data.data[0].cartDTdata);
          for (let i = 0; i < this.ProductlistData.length; i++) {
            this.ProductlistData[i].ImagePath = '/Images/' + this.ProductlistData[i].ImagePath;
          }
          if (this.ProductlistData != null) {
            this.cartItemsCal = this.ProductlistData;

            this.TotalAmountOfProduct = 0;
            var t = 0;
            for (let i = 0; i < this.cartItemsCal.length; i++) {
              t += this.cartItemsCal[i].ProductPrice * this.cartItemsCal[i].QTY;

            }
            var sum = 0;
            this.TotalAmountOfProduct = t;
            this.TotalItem = 0;
            t = 0;
            if (this.cartItemsCal) {
              for (let i = 0; i < this.cartItemsCal.length; i++) {
                t += this.cartItemsCal[i].QTY;
                //t += length++;
              }
            }
            sum = this.cartItemsCal.length;
            this.TotalItem = sum;
          }
         
        }
      }
      );
  }


  
}
