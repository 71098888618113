export class Cart {
  
  CartId: number
  ApprovalStatus: string
  TotalItemPrice: number
  ModifiedDate: Date
  IsDeleted: boolean;
  TotalQTY: number
  DeliveryType: string
  PaymentType: string
  TimeSpan: string
  OrderNo: string
  CreatedDate: Date
  PaymentStatus: string
  ModifiedBy: number
  UserId: number
  DeliveryCharges: number
  ProductPackId: number;
  CartDetailsId: number;
  ProductName: string;
  ProductStatus: string;
  ProductPrice: number;
  TotalProductPrice: number;
  AddedInCartDate: Date;
  ItemId: number;
  QTY: number;
  Grade: number;
  CartDetailSize: number;
  ImgPath: string;
  Item_MRP: number;
  Weight: string;
  Unit_Name: string;
  Unit: string;
  offers: string;
  Discount: string;

  Size: number;
  SizeParentId: number;
  SizeDetailsId: number;
  BatchId: number;
  Image: any;
  IsDecreaseQty: boolean = false;
  UomId: number;
}
