import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { ProductInfoModal } from '../Models/product-info-modal';
import { Cart } from '../Models/cart';
import { AppComponent } from '../app.component'; 
import { FavouriteModel } from '../Models/favourite-model';
import { ModalDirective } from 'ngx-bootstrap';
declare var $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  items = [];
  pageOfItems: Array<any>;
  searchText: string;
  searchBrandText: string;
  listShow: boolean = false;
  searchterm: any; filtr: any; p: any;
  LoadProductByCatData: any;
  SearchPage: boolean = true;
  myOptions = {
    'placement': 'right',
    'show-delay': 500,
    'theme': 'light'

  }
  myOptions1 = {
    'placement': 'left',
    'show-delay': 500,
    'theme': 'light'

  }
  constructor(private router: Router,private route: ActivatedRoute, private apiIntergnService: ApiIntergnService, private appComp: AppComponent) { }
  result3 = [];
  Allcatid: any;
  StorageData: string;
  CustomerId: number;
    ngOnInit() {
      debugger;
      this.CustomerId = Number(localStorage.getItem('CustId'));
      this.SearchPage = this.appComp.RegisterForm;
    if (this.apiIntergnService.subsRefreshCartVar == undefined) {
      this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {
        this.appComp.changed = true;
        this.ngOnInit();
      });
    }

    if (this.apiIntergnService.subsVar == undefined) {
      debugger;
      this.apiIntergnService.invokeQtyChange.subscribe((Obj: any) => {
        debugger;
          if ((Obj != null && !this.appComp.changed) || (Obj != null && this.appComp.homePageChanged)) {
             
  
            if (this.Products != undefined) {

              var ProductDataIndex = this.Products.findIndex(x => x.item_Id == Obj.Item.item_Id);
            }
            //var TopPopularIndex = this.TopOffersData.findIndex(x => x.Item_Id == Obj.Item.ItemId);
            //if (this.LoadProductByCatData != undefined) {

            //  var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == Obj.Item.item_Id);
            //}
          let item: any = {};

          item.item_Id = Obj.Item.item_Id;
          item.productName = Obj.Item.productName;
          item.item_SellingPrice = Obj.Item.productPrice;
          item.batch_id = Obj.Item.batch_id;
          item.item_MRP = Obj.Item.item_MRP;
          item.weight = Obj.Item.weight;
          item.unit = Obj.Item.unit_Name;
            item.offerAmt = Obj.Item.offerAmt;
            item.imagePath = Obj.Item.imagePath;
            item.uomId = Obj.Item.uomId;

          if (ProductDataIndex != null && ProductDataIndex != -1) {
            this.quantityChanged(ProductDataIndex, Obj.Type, item, 'Products');
            this.appComp.homePageChanged = false;
            return;
          }
          //if (TopPopularIndex != null && TopPopularIndex != -1) {
          //  this.quantityChanged(TopPopularIndex, Obj.Type, item, 'TopOffers');
          //  return;
          //  }
            //if (TopSimilarIndex != null && TopSimilarIndex != -1) {
            //  this.quantityChanged(TopSimilarIndex, Obj.Type, item, 'TopSimilar');
            //  return;
            //}

          if (this.appComp.cartItems[Obj.Index].qty == 0) {
            this.appComp.cartItems.splice(Obj.Index, 1);
          }
          //this.quantityChanged(Obj.Index, Obj.Type, Obj.Item, Obj.ObjectName);
        }
        //else {
        //  this.appComp.changed = false;
        //}
      });
    }

      if (this.apiIntergnService.subsVarCart == undefined) {

      this.apiIntergnService.invokeCartItemDeleteEvent.subscribe((Obj: any) => {
        debugger;
        var ProductDataIndex = this.Products.findIndex(x => x.item_Id == Obj.Item.item_Id);
        //var TopPopularIndex = this.TopOffersData.findIndex(x => x.Item_Id == Obj.ItemId);
       // var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == Obj.Item.item_Id);
        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.Products[ProductDataIndex].qty = 0;
        }
        //if (TopPopularIndex != null && TopPopularIndex != -1) {
        //  this.TopOffersData[TopPopularIndex].Qty = 0;
        //}
        //if (TopSimilarIndex != null && TopSimilarIndex != -1) {
        //  this.LoadProductByCatData[TopSimilarIndex].qty = 0;
        //  return;
        //}
        this.StorageData = localStorage.getItem('Cart');
        this.result3 = JSON.parse(this.StorageData);
      });
    }
    


    this.items = Array(150).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}` }));

    
    this.LoadTopOffers();
   
    this.LoadCategory();
    this.StorageData = localStorage.getItem('Cart');
    this.result3 = JSON.parse(this.StorageData);

    this.CategoriesWithSub();

    $(".toggle").click(function (e) {
      e.preventDefault();

      var $this = $(this);

      if ($this.next().hasClass("show")) {
        $this.next().removeClass("show");
        $this.next().slideUp(350);
      } else {
        $this
          .parent()
          .parent()
          .find("li .inner")
          .removeClass("show");
        $this
          .parent()
          .parent()
          .find("li .inner")
          .slideUp(350);
        $this.next().toggleClass("show");
        $this.next().slideToggle(350);
      }
    });
   
  }


    ngAfterViewInit() {
        setTimeout(() => {
            debugger;
            this.route.params.subscribe(params => {
                debugger;
                this.searchterm = params['term'];
                this.appComp.filter = this.searchterm;
                this.SearchProducts();


            });
        }, 0);
    }

  

  OpenSubCategory()
  {
    this.listShow = !this.listShow;
  }

  item1: boolean;

  showList(item, index) {
    debugger;
    console.log(item);
    this.item1 = item.subcategory;
    this.CategoriesWithSubCat.forEach(function (element) {
      element.subcategory = false;
    })
    if (this.item1== false)
    {
      this.CategoriesWithSubCat[index].subcategory = true;
    }
    else
    {
      this.CategoriesWithSubCat[index].subcategory = false;
    }
    //this.CategoriesWithSubCat[index].subcategory = !item.subcategory;
    

  }
  AddFavourite(items) {
    debugger;
    if (this.CustomerId != 0) {
      items.isFavt = !items.isFavt;
      let favouriteModel = new FavouriteModel();
      favouriteModel.Favt_Id = 0;
      favouriteModel.Favt_customerId = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ItemId = items.item_Id;
      favouriteModel.Favt_CreateBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ModififedBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_CreatedDate = new Date();
      favouriteModel.Favt_ModififedDate = new Date();
      favouriteModel.Favt_IsDelete = false;
      favouriteModel.Favt_BatchId = items.batch_id;
      if (items.isFavt == true)
        favouriteModel.Mode = "Insert"
      if (items.isFavt == false)
        favouriteModel.Mode = "Delete"


      this.apiIntergnService.Addfavourite(favouriteModel).subscribe(Val => {
        if (!Val.isError) {

        }
      });
    }
    else {
      alert("Please Login To Add Favourite");
    }

  }
  showhideCate: boolean = true;
  showhideprice: boolean = false;
  showhidebrand: boolean = false;
  showhideimpfruits: boolean = false;
  UpDown(listType) {
    debugger;
    switch (listType) {
      case 'cate': {
        this.showhideprice = false;
        this.showhidebrand = false;
        this.showhideimpfruits = false;
        if (this.showhideCate) {
          this.showhideCate = false;
        }
        else {
          this.showhideCate = true;
          window.scrollTo(0, 0)
        }
        break;
      }
      case 'price': {
        this.showhideCate = false;
        this.showhidebrand = false;
        this.showhideimpfruits = false;
        if (this.showhideprice) {
          this.showhideprice = false;
        }
        else {
          this.showhideprice = true;
          window.scrollTo(0, 0)
        }
        break;
      }
      case 'brand': {
        this.showhideprice = false;
        this.showhideCate = false;
        this.showhideimpfruits = false;
        if (this.showhidebrand) {
          this.showhidebrand = false;
        }
        else {
          this.showhidebrand = true;
          window.scrollTo(0, 0)
        }
        break;
      }
      case 'impfruits': {
        this.showhideprice = false;
        this.showhidebrand = false;
        this.showhideCate = false;
        if (this.showhideimpfruits) {
          this.showhideimpfruits = false;
        }
        else {
          this.showhideimpfruits = true;
          window.scrollTo(0, 0)
        }
        break;
      }
    }

  }
  Products: any;
  Categories: any;
  Categories12: any;

  OpenSingleForm(ItemID) {
    this.router.navigate(['./single/' + ItemID]);
  }
  OpenCityDiv() {
  }
  Account()
  {
    this.router.navigate(['./Account']);
   
  }


  public LoadBrandByCatId(Id)
  {
    debugger;
    this.apiIntergnService.getBrandByCateId(Id).subscribe((data) => {
      if (!data.isError) {
        this.loadBrand = data.data;

      }
    });
  }

  pageChanged(event) {
    this.p = event;

    window.scrollTo(0, 0);


  }

  ViewAllCategory() {

    this.Allcatid = 0;
    this.router.navigate(['./shop/All Categories/' + this.Allcatid]);
    window.scrollTo(0, 0)


   
  }

  ViewAllCategoryForSimilarProduct()
  {
    this.Allcatid = this.categoryId;
    //this.router.navigate(['./shop/All Categories/' + this.Allcatid]);
    this.router.navigate(['./shop/' + this.CategoryName + '/' + 3 + this.categoryId]);
    window.scrollTo(0, 0)
  }

  workout = null;
  selectedshift: any;
  FilterListMethod(event: any)
  {
    this.selectedshift = event.name;
    if (this.selectedshift == "Name(A to Z)") {
      this.Products.sort((a, b) => Number(a.productName > b.productName) * 2 - 1);
    }
    else if (this.selectedshift == "Price (Low to High)")
    {
      this.priceFilter();
      
    }
    else if (this.selectedshift == "Price (High to Low)") {

      this.priceHighToLowFilter();

    }

    else if (this.selectedshift == "Discount (High to Low)") {

      this.DiscountHighToLowFilter();

    }
    
  }

  priceFilter = () => {
    debugger;
    let ordered = this.Products.sort((a, b) => (a.item_SellingPrice > b.item_SellingPrice) ? 1 : -1);
    //let lastElement = ordered.pop();
    this.Products = ordered;
   // return ordered.unshift(lastElement);
  }
  
  priceHighToLowFilter = () => {
    let ordered = this.Products.sort((a, b) => (a.item_SellingPrice < b.item_SellingPrice) ? 1 : -1);
    this.Products = ordered;
   
  }

  DiscountHighToLowFilter = () =>
  {
    let ordered = this.Products.sort((a, b) => (a.offerAmt < b.offerAmt) ? 1 : -1);
    this.Products = ordered;

  }

 // searchArray(query)
 // {
 
 //   for (var i = 0; i < query.length - 1; i++) {
 //    var char = query.charAt(i);
 //    if (this.Products[i].indexOf(char) != null) {
 //      this.Products.push(this.Products[i]);
 //   }
 //  }
 //    return this.Products;
 //}
  GotoHomePage()
  {
    debugger;
    this.router.navigate(['./Home']);
    window.scrollTo(0, 0)
  }

  workouts = [

    //{
    //  name: "Relevance",
    //  id: 1
    //},
    {
      name: "Price (Low to High)",
      id: 1
    },
    {
      name: "Price (High to Low)",
      id: 2
    },
    {
      name: "Discount (High to Low)",
       id: 3
    },
    {
      name: "Name(A to Z)",
      id: 4
    }
    

  ]
  


  carouselOptionsForSection6 = {
    margin: 15,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
        dots: false
      },
      600: {
        items: 2,
        nav: false,
        dots: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  AddToCart(ProductsInfo: ProductInfoModal) {
    debugger;
    let CartInfo: Cart = new Cart();
    CartInfo.AddedInCartDate = new Date();
    CartInfo.ItemId = ProductsInfo.item_Id;
    CartInfo.ProductName = ProductsInfo.productName;
    CartInfo.ProductPrice = ProductsInfo.productPrice;
    CartInfo.BatchId = ProductsInfo.batch_id;
    CartInfo.QTY = 1;
    CartInfo.IsDecreaseQty = ProductsInfo.isDecreaseQty;
    CartInfo.TotalProductPrice = (CartInfo.ProductPrice) * (CartInfo.QTY);
    var id = localStorage.getItem('CustId');
    CartInfo.UserId = Number(id);
    this.apiIntergnService.AddCart(CartInfo)
      .subscribe(response => {
        if (response) {

          //this.appComp.getTotalAmounts();

        }
        else {
          alert("Something went wrong please try again")
        }
      });

  }
  UserId: number = 0;
  result99 = [];

  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }
  OpenTopSaver(item, index, objectName)
  {
    debugger;
    this.UserId = Number(localStorage.getItem('CustId'));
    var prod = new ProductInfoModal();
    this.StorageData = localStorage.getItem('Cart');
    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));

    if (this.StorageData != null) {
      this.result99 = JSON.parse(this.StorageData);
    }
    //if (this.UserId == 0) {
    //  this.LoginModalOpen();
    //  return;
    //}
    //if (this.TopOffersData != undefined) {

    //  var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == item.item_Id);
    //}
    if (this.Products != undefined) {

      var ProductDataIndex = this.Products.findIndex(x => x.item_Id == item.item_Id);
    }
    //if (this.LoadProductByCatData != undefined) {

    //  var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
    //}
    
    
      if (objectName == 'Products') {
        prod.qty = this.Products[ProductDataIndex].qty = 1;
        //if (TopPopularIndex != null && TopPopularIndex != -1) {
        //  this.TopOffersData[TopPopularIndex].qty = 1;
        //}
        //if (TopSimilarIndex != null && TopSimilarIndex != -1) {
        //  this.LoadProductByCatData[TopSimilarIndex].qty = 1;
        //}
      }

    //  else if (objectName == 'TopOffers') {
    //    prod.qty = this.TopOffersData[TopPopularIndex].qty = 1;
    //    if (ProductDataIndex != null && ProductDataIndex != -1) {
    //      this.Products[ProductDataIndex].qty = 1;
    //    }
    //  }

    //  else if (objectName == 'TopSimilar') {
    //    prod.qty = this.LoadProductByCatData[TopSimilarIndex].qty = 1;
    //    if (ProductDataIndex != null && ProductDataIndex != -1) {
    //      this.Products[ProductDataIndex].qty = 1;
    //    }
    //}
    if (item.imagePath == undefined) {
      item.imagePath = item.path
    }
    if (item.batchId != undefined) {
      item.batch_id = item.batchId
    }
    if (item.batchDetails != null) {
      for (var k = 0; k < item.batchDetails.length; k++) {
        if (item.batchDetails[k].Batch_Id == item.batchDetails[0].Batch_Id && item.batchDetails[k].Batch_UOMId == item.batchDetails[0].Unit_Id) {
          item.batch_id = item.batchDetails[k].Batch_Id,
            item.item_SellingPrice = item.batchDetails[k].Batch_SellingPrice,
            item.item_MRP = item.batchDetails[k].Batch_MRPPrice,
            item.uomId = item.batchDetails[k].Unit_Id
          break;
        }
      }
    }

    prod.Batch_qty = 1
    prod.itemId = item.item_Id;
    prod.item_Id = item.item_Id;
    prod.productName = item.productName;
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.item_MRP = item.item_MRP;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.offers = item.offerAmt;
    prod.imagePath = item.imagePath;
    prod.uomId = item.uomId;

    var t = this.result99.find(list => list.item_Id == prod.itemId)
    console.log(t);


    if (t) {
      console.log(this.result3);
      this.result99.forEach((element, index) => {
        element.qty = prod.qty;
      })
    }
    else {
      this.result99.push(prod);
      console.log(this.result99);
      prod.CustomerID = Number(localStorage.getItem('CustId'));
      localStorage.setItem('Cart', (JSON.stringify(this.result99)));
    }
    this.appComp.TotalItem = this.result99.length;
    if (prod.UserId > 0) {
      debugger;
      var PreCartData = prod;
      PreCartData.qty = 1;
      this.apiIntergnService.AddPreSingleCart(PreCartData)
        .subscribe(Val => {
          debugger;
          if (!Val.isError) {
            this.result99.forEach((element, _index) => {
              if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                element.qty = Val.data.qty;
              }
            });
            localStorage.setItem('Cart', (JSON.stringify(this.result99)));
          }
        });
    }
    localStorage.setItem('Cart', (JSON.stringify(this.result99)));
    //}
    //else {
    //  this.AddToCart(prod);
    //}

    
  }
  result2 = [];
  separatedStringBrand: string="";
  onChangeBrand(brand: any, isChecked: boolean)
  {
    debugger;

    if (isChecked)
    {
      this.result2.push(brand);
      this.separatedStringBrand = Array.prototype.map.call(this.result2, function (item) { return item.brand_Id; }).join(",");
      console.log(this.separatedStringBrand);
    }
    else
    {
      const index = this.result2.findIndex(list => list.brand_Id == brand.brand_Id);//Find the index of stored id
      this.result2.splice(index, 1)
      this.separatedStringBrand = Array.prototype.map.call(this.result2, function (item) { return item.brand_Id; }).join(",");

     
    }
    this.OpenBrand(brand);
  }
  loadBrand: any
  public LoadBrand() {
    debugger;
    this.apiIntergnService.getAllBrand().subscribe((data) => {
      if (!data.isError) {
        this.loadBrand = data.data;

      }
    });
  }
  OpenBrand(item) {
    debugger;
    this.Categories12 = item.brand_Name;
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
      CateId: item.category_ID,
      BrandId: this.separatedStringBrand
    }
    this.apiIntergnService.getProductBrandId(item.brand_Id).subscribe((data) => {
      if (!data.isError) {
        this.Products = data.data;
        this.productLength = data.data.length;
        this.ItemsCount = data.data.length;
        this.Products.forEach(function (element) {
          element.qty = 0;
        });
        this.FilterListMethodsort(this.selectedshift);
        this.Products = this.appComp.CheckItemQty(this.Products, 'TopOffers');
      }
    });
  }

  CateAllData: any;
  public LoadCategory() {

    this.apiIntergnService.getAllCategories().subscribe((data) => {
      if (!data.isError) {
        this.CateAllData = data.data;

      }
    });
  }

  FilterListMethodsort(event: any) {
    debugger;

    this.selectedshift = event;
    if (this.selectedshift == "Name(A to Z)") {
      this.LoadProductByCatData.sort((a, b) => Number(a.productName > b.productName) * 2 - 1);
    }
    else if (this.selectedshift == "Price (Low to High)") {
      this.priceFilter();

    }
    else if (this.selectedshift == "Price (High to Low)") {

      this.priceHighToLowFilter();

    }

    else if (this.selectedshift == "Discount (High to Low)") {

      this.DiscountHighToLowFilter();

    }

  }

  priceFilterbyItemId = () => {
    debugger;
    this.CategoriesWithSubCat.sort((a, b) => Number(a.category_DisplayName > b.category_DisplayName) * 2 - 1);
  }
  CategoriesWithSubCat: any;
  public CategoriesWithSub() {
    debugger;
    this.apiIntergnService.getCategoriesWithSubCat().subscribe((data) => {
      
      if (data!= null && !data.isError) {
        this.CategoriesWithSubCat = data.data;

        if (this.CategoriesWithSubCat.length != 0) {

            this.CategoriesWithSubCat.forEach(function (element) {

                element.subcategory = false;

                window.scrollTo(0, 0)
            

            window.scrollTo(0, 0)
          })
          this.priceFilterbyItemId();
        }

      }
    });
  }
  


  OpenSubCategorytest(item) {
      debugger;
      this.router.navigate(['./Search/' + item.category_DisplayName]);
    this.appComp.filter = item.category_DisplayName;
    this.Categories12 = item.category_DisplayName;
    this.ItemsCount = 0;
    var obj = {
      PriceLevelId: 0,
      CustomerId: item.category_ID,
      CateId: item.category_ID
    }
    this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {
      if (!data.isError) {
        this.Products = data.data;
        this.productLength = data.data.length;
        this.ItemsCount = data.data.length;
   
        this.Products.forEach(function (element) {
          element.qty = 0;
          element.batchDetails = JSON.parse(element.batchDetails);
          element.batchDetails.forEach(function (batch) {
            batch.Batch_qty = 0;
          })
          window.scrollTo(0, 0)
        });
       // this.FilterListMethodsort(this.selectedshift);
        this.Products = this.appComp.CheckItemQty(this.Products, 'TopOffers');
      }
    });
    //this.showList(item, index);
  }

  quantityChanged(index, type, item, objectName) {
    debugger;
    if (this.router.url.includes("/Search/")) {
      this.appComp.changed = true;
      this.StorageData = localStorage.getItem('Cart');
      if (this.StorageData != null) {
        this.result3 = JSON.parse(this.StorageData);
      }
      var prod = new ProductInfoModal();


      this.UserId = Number(localStorage.getItem('CustId'));
      //if (this.UserId == 0) {
      //  this.LoginModalOpen();
      //  return;
      //}
      var qty = 0;
      if (this.TopOffersData != undefined) {

        var TopPopularIndex = this.TopOffersData.findIndex(x => x.item_Id == item.item_Id);
      }
      if (this.Products != undefined) {

        var ProductDataIndex = this.Products.findIndex(x => x.item_Id == item.item_Id);
      }
      if (this.LoadProductByCatData != undefined) {

        var TopSimilarIndex = this.LoadProductByCatData.findIndex(x => x.item_Id == item.item_Id);
      }


      if (objectName == 'Products') {


        if (type == 'Increament') {
          this.Products[ProductDataIndex].qty += 1;
        }
        else {
          this.Products[ProductDataIndex].qty -= 1;
          prod.isDecreaseQty = true;

        }
        qty = this.Products[ProductDataIndex].qty
        if (TopPopularIndex != null && TopPopularIndex != -1) {
          this.TopOffersData[TopPopularIndex].qty = qty;
        }
      }
      else if (objectName == 'TopOffers') {

        if (type == 'Increament') {
          this.TopOffersData[TopPopularIndex].qty += 1;
        }
        else {
          this.TopOffersData[TopPopularIndex].qty -= 1;
          prod.isDecreaseQty = true

        }
        qty = this.TopOffersData[TopPopularIndex].qty
        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.Products[ProductDataIndex].qty = qty;
        }
      }
      else if (objectName == 'TopSimilar') {

        if (type == 'Increament') {
          this.LoadProductByCatData[TopSimilarIndex].qty += 1;
        }
        else {
          this.LoadProductByCatData[TopSimilarIndex].qty -= 1;
          prod.isDecreaseQty = true

        }
        qty = this.LoadProductByCatData[TopSimilarIndex].qty
        if (ProductDataIndex != null && ProductDataIndex != -1)
        {
          this.Products[ProductDataIndex].qty = qty;
        }
      }
      if (item.imagePath == undefined) {
        item.imagePath = item.path;
      }
      prod.itemId = item.item_Id;
      prod.productName = item.productName;
      prod.productPrice = item.item_SellingPrice;
      prod.batch_id = item.batch_id;
      prod.batchId = item.batch_id;
      prod.qty = qty;
      prod.uomId = item.uomId;
      prod.unit = item.unit_Name;
      prod.imagePath = item.imagePath;
      prod.weight = item.weight;

      this.result3.forEach((element, index) => {
        if (element.item_Id == prod.itemId) {
          element.qty = prod.qty;
          if (prod.qty <= 0) {
            //const index = this.result3.findIndex(list => list.ItemId == prod.ItemId);//Find the index of stored id
            //this.result3.splice(index, 1)
            //this.appComp.cartItems.splice(index, 1);

            const indexcart = this.appComp.cartItems.findIndex(list => list.item_Id == prod.itemId);
            //this.appComp.cartItems.splice(indexcart, 1);
            this.result3 = this.appComp.cartItems;
          }
        }
      });

      this.appComp.cartItems = this.result3;

      prod.CustomerID = Number(localStorage.getItem('CustId'));
      prod.UserId = Number(localStorage.getItem('CustId'));
      if (prod.UserId > 0) {
        debugger;
        var PreCartData = prod;
        PreCartData.qty = 1;
        this.apiIntergnService.AddPreSingleCart(PreCartData)
          .subscribe(Val => {
            debugger;
            if (!Val.isError) {
              this.result3.forEach((element, _index) => {
                if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                  element.qty = Val.data.qty;
                }
              });
              localStorage.setItem('Cart', (JSON.stringify(this.result3)));
            }
          });
      }
      localStorage.setItem('Cart', (JSON.stringify(this.result3)));
      //this.StorageData = localStorage.getItem('Cart');
      //console.log(this.StorageData);
      //}
      //else {
      //  this.AddToCart(prod);
      //}

      this.appComp.TotalItem = this.result3.length;
    }
  }
  categoryId: number = 0;
  productLength: number = 0;
  ItemsCount: number = 0;
  CategoryName: string = "";
  public SearchProducts() {
    debugger;
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
      Search: this.searchterm,
    }
    this.apiIntergnService.GetMasterSearch(obj).subscribe((data) => {
      debugger;
      if (data == undefined) {
        this.Products = [];
        this.productLength = this.Products.length;
      }
      else {


        //console.log(this.Products = []);
        //this.productLength = this.Products.length;
        this.Categories12 = "";
        if (!data.isError)
        {
          if (data.data)
          {
            debugger;
            console.log("Master Search Data",data.data)
            this.Categories12 = this.searchterm;
            this.Products = data.data;
            this.productLength = this.Products.length;
            this.ItemsCount = this.Products.length;
            if (data.data.categoryId!=null) {
              this.Categories = "";
              this.categoryId = data.data.categoryId;
              this.CategoryName = "";
              this.OpenCategory();
            }
            
            //this.LoadBrandByCatId(this.categoryId);
            //this.Categories12 = data.Data.Categories[0].Category_DisplayName;

            this.Products.forEach(function (element) {
              element.qty = 0;
              element.batchDetails = JSON.parse(element.batchDetails);
              element.batchDetails.forEach(function (batch) {
                batch.Batch_qty = 0;
              })
              window.scrollTo(0, 0)
            });
            this.Products = this.appComp.CheckItemQty(this.Products, 'TopOffers');
          }
          else {
            alert("No data found");
          }
        }
        else {
          debugger;
          this.Products = [];
        }
      }
    });
  }


  OpenCategory() {
    debugger;
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
      CateId: this.categoryId,
      BrandId: ""
    }
    this.apiIntergnService.getProductByCatID(obj).subscribe((data) => {
      if (!data.isError) {
        this.LoadProductByCatData = data.data;
        this.LoadProductByCatData.forEach(function (element) {
          element.qty = 0;
          window.scrollTo(0, 0)
        });
        for (var i = 0; i < this.LoadProductByCatData.length; i++) {
          for (var k = 0; k < this.result2.length; k++) {
            if (this.LoadProductByCatData[i].item_Id == this.result2[k].item_Id) {
              this.LoadProductByCatData[i].qty = this.result2[k].qty;
            }
          }
        }
      }
    });

    //this.LoadBrandByCatId(item.Category_ID);

    //this.showList(item, index);
  }
  TopOffersData: any;
  public LoadTopOffers() {
    var obj = {
      PriceLevelId: 0,
      CustomerId: 0,
    }
    this.apiIntergnService.getTopOffers(obj).subscribe((data) => {
      if (!data.isError) {
        this.TopOffersData = data.data;
        console.log("Top offer data", this.TopOffersData);
        this.TopOffersData.forEach(function (element) {
          element.qty = 0;
        });
        this.TopOffersData = this.appComp.CheckItemQty(this.TopOffersData, 'TopOffers');
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  imagesofsection4 = [
    {

      lable: "All Fruits"


    },
    {

      lable: "Fresh & Herbs",
      offer: "5% OFF"
    },
    {

      lable: "Seasonal Fruits"
    },
    {

      lable: "Imported Fruits",
      offer: "NEW"
    },
    {

      lable: "Citrus"
    },
    {


      lable: "Cut Fresh & Herbs"
    },
    {

      lable: "Seasonal Fruits"
    },
    {

      lable: "Fresh & Herbs"
    }




  ]


  //imagesofsection2 = [
  //  {

  //    image: "../assets/img/item/6.jpg",

  //  },
  //  {

  //    image: "../assets/img/item/7.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/8.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/1.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/2.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/3.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/4.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/5.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/6.jpg"
  //  }
  //  //,

  //  //{

  //  //  image: "../assets/img/item/9.jpg"
  //  //},
  //  //{

  //  //  image: "../assets/img/item/12.jpg"
  //  //},
  //  //{


  //  //  image: "../assets/img/item/1.jpg"
  //  //},
  //  //{

  //  //  image: "../assets/img/item/2.jpg"
  //  //},
  //  //{

  //  //  image: "../assets/img/item/3.jpg"
  //  //},
  //  //{

  //  //  image: "../assets/img/item/4.jpg"
  //  //},
  //  //{

  //  //  image: "../assets/img/item/5.jpg"
  //  //}



  //]
  result1 = [];
  separatedString: string="";
  onChangeCategory(email: any, isChecked: boolean) {
    debugger;

    if (isChecked) {
      this.result1.push(email);
      this.separatedString = Array.prototype.map.call(this.result1, function (item) { return item.category_ID; }).join(",");
      console.log(this.separatedString);
    }
    else {
      const index = this.result1.findIndex(list => list.category_ID == email.category_ID);//Find the index of stored id
      this.result1.splice(index, 1)
      this.separatedString = Array.prototype.map.call(this.result1, function (item) { return item.category_ID; }).join(",");
    }
    //this.OpenCategory(email);
  }
  carouselOptionsForSection5 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      600: {
        items: 2,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }


  carouselOptionsForSection4 = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      600: {
        items: 3,
        nav: true,
        dots: false
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }

  //imagesofsection43 = [
  //  {

  //    image: "../assets/img/item/6.jpg",
  //    lable: "All Fruits"
  //  },
  //  {

  //    image: "../assets/img/item/7.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/8.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/9.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/12.jpg"
  //  },
  //  {


  //    image: "../assets/img/item/1.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/2.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/3.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/4.jpg"
  //  },
  //  {

  //    image: "../assets/img/item/5.jpg"
  //  }



  //]
}
