export class ValidateInfo {
  MobileNo: string;
  OTP: string;
  Password: number;
  OldPassword: number;
  UserId: number;
  Customer_ID: number;
  Customer_Pincode: number;
  Customer_State: string;
  Customer_Address1: string;
  Customer_Address2: string;
  OTP_UserId: number;
  OTPId: number;

}
