import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Router } from '@angular/router';
import { AuthService } from 'angular-6-social-login';
import { ModalDirective } from 'ngx-bootstrap';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-view-all-category',
  templateUrl: './view-all-category.component.html',
  styleUrls: ['./view-all-category.component.css']
})
export class ViewAllCategoryComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
    constructor(private formBuilder: FormBuilder, private apiIntergnService: ApiIntergnService, private router: Router, private _eref: ElementRef, private socialAuthService: AuthService) { }
    CategoryData: any;
    ngOnInit()
    {
        this.GetCategoriesNavigation();
    }
    public GetCategoriesNavigation() {
        debugger;
        this.apiIntergnService.GetCategoriesNavigation().subscribe((data) => {
            if (!data.isError) {
                this.CategoryData = data.data;

            }
        });
    }

   
    OpenCategory(catid, CateName) {
        debugger;

        this.apiIntergnService.CatName = CateName;
        //this.listShow = false;
        this.router.navigate(['./shop/' + CateName + '/' + catid.category_ParentID + catid.category_ID]);
        //for (let myChild of this.CateAllData) {
        //    myChild.BackgroundColour = "white";
        //}

        catid.BackgroundColour = "lightGrey";
    }
    OpenSubCategoryNav(catid, CateName) {
        debugger;
        this.apiIntergnService.CatName = CateName;
       // this.listShow = false;
        this.router.navigate(['./shop/' + CateName + '/' + catid.subCate_ParenUnderID + catid.subCate_ID]);
        

        catid.BackgroundColour = "lightGrey";
        var x = document.getElementById("navbarText");
        x.className = 'collapse navbar-collapse';

    }
}
