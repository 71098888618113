import { Component, OnInit, ViewChild, DoCheck, NgZone, ElementRef } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { Cart } from '../Models/cart';
import { CartModel } from '../Models/cart-model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { ModalDirective } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomershippingInfoModel } from '../Models/customershipping-info-model';
import { ProductInfoModal } from '../Models/product-info-modal';
import { WindowRefService } from 'src/app/window-ref.service';
import { MapsAPILoader } from '@agm/core';

import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';
//import { Razorpay } from 'razorpay';
import PlaceResult = google.maps.places.PlaceResult;

import { CartDTModel } from '../Models/cart-dtmodel';
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
    @ViewChild('MyOrderStatus', { static: false }) MyOrderStatus: ModalDirective;
    @ViewChild('PaymentForm', { static: false }) PaymentForm: ModalDirective; showFifthList: boolean;
    @ViewChild('ConfirmationPage', { static: false }) ConfirmationPage: ModalDirective;
    @ViewChild('Loader', { static: false }) Loader: ModalDirective;
    @ViewChild('searchel', { static: false }) public searchElement: ElementRef;
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
    @ViewChild('AddressForm', { static: false }) AddressForm: ModalDirective;
    @ViewChild('AddressDeleteModel', { static: false }) AddressDeleteModel: ModalDirective;
    @ViewChild('ConfirmationPageOfAddress', { static: false }) ConfirmationPageOfAddress: ModalDirective;
    @ViewChild('ResetFinalPassword', { static: false }) ResetFinalPassword: ModalDirective;
    CustomerInfo: any; CustomerAddres: any; OrderNo: string; Time: Date; showForthList: boolean; NoSelectedAddresError: string;
    cartItems: any[]; TotalItem: number; CustomerState: any; Address: string; PayableAmount: number;
    TotalAmount: number; cartItemsCal: any; CustomerPhoneNo: any; unamePattern = "^[a-zA-Z\\s]*$";
    showSecondList = false; CustomerLastName: string; Customer_Pincode: any; selectedAddressId: number = 0;
  CustomerName: string; CustomerEmailId: any; CustomerlandMark: any; showThirdList: boolean; TotalQty: number =0;
    public AppComponents: AppComponent; CustomershippingCustomerId: number = 0; selectedCategory: number; CustomerAddres5: string;
  title = 'My first AGM project'; NoItemFoundError: string; CartDT: any;
    DeliveryChargeplusAmount: number = 0;
    DeliveryCharge: number = 0;
    constructor(private apiIntergnService: ApiIntergnService, private formBuilder: FormBuilder, private router: Router, private appComp: AppComponent, private winRef: WindowRefService, private _ngZone: NgZone, private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone) { }
    myOptions = {
        'placement': 'right',
        'show-delay': 500,
        'theme': 'light',
        'shadow': 'true'
    }
    ngOnInit()
    {
        this.appComp.Height = 60;
        if (this.apiIntergnService.subsRefreshCartVar == undefined) {
            this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {
                this.ngOnInit();
            });
        }


        $('body').removeClass('open');

        this.addForm = this.formBuilder.group({
            CustomershippingAddress: ['', Validators.required],
            Customershipping_State: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
            Customershipping_Pincode: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.NumberPattern)]],
            Customershipping_Name: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
            Customershipping_Address1: ['', Validators.required],
            Customershipping_Address2: ['', Validators.required],
            Customershipping_FSSAINo: [''],

        },

        );

        this.addFormNumber = this.formBuilder.group({
            Customer_Mobile: ['']
        },
        );



        $('body').removeClass('open');
        this.LoadCategory();

        if (this.apiIntergnService.subsVar == undefined) {
            this.apiIntergnService.invokeCheckOutProductEvent.subscribe(() => {

                this.CheckOutItem();

            });
        }
        this.NoItemFoundError = "";
        this.NoSelectedAddresError = "";
     // this.CustomerAddress();
      //this.ShippingAddressDetails();
      //  this.CheckOutItem();
        window['angularComponentReference'] = { component: this, zone: this._ngZone, loadAngularFunction: (paymentid) => this.GetPaymentStatus(paymentid, ""), };




        this.searchControl = new FormControl();

        //this.mapsAPILoader.load().then(() => {

        //    this.geoCoder = new google.maps.Geocoder;

        //    this.setCurrentLocation();



        //    let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {});

        //    let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //    autocomplete.addListener("place_changed", () => {
        //        this.ngZone.run(() => {
        //            //get the place result

        //            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //            this.address = place.formatted_address;
        //            //verify result
        //            if (place.geometry === undefined || place.geometry === null) {
        //                return;
        //            }

        //            //set latitude, longitude and zoom
        //            this.latitude = place.geometry.location.lat();
        //            this.longitude = place.geometry.location.lng();
        //            this.zoom = 12;
        //        });
        //    });
        //});
      this.ForWordToCheckOut("Approved");

  }
    CustomerCity: any; PaymentTypeName: string; PickUpPointName: string;
    CustomerSelfPickAddres: string; addForm: FormGroup;
    addFormNumber: FormGroup;
    ProductlistData: any; oTPsubmitted = false;
    CustomerHomePickAddres: any; StorageData: string;
    NumberPattern = "^[0-9]*$";
    public searchControl: FormControl;
    TotalPayableAmount: number = 0; Mode: string; cartItemLength: number = 0;
    showFirstList: boolean = false;

    public appearance = Appearance;
    public zoom: number;
    public latitude: number;
    public longitude: number;
    public selectedAddress: PlaceResult;


    //latitude: number;
    //longitude: number;
    //zoom: number;
    address: string;
    private geoCoder;

    CheckOutItem() {



        var id = Number(localStorage.getItem('CustId'));
        this.StorageData = localStorage.getItem('Cart');
        //console.log(this.StorageData);
        this.cartItems = JSON.parse(this.StorageData);
        this.cartItemLength = this.cartItems.length;
        //console.log(this.cartItems);

        // this.apiIntergnService.CartDetails(id)
        // .subscribe(data =>
        //{
        // if (data)
        //{

        //this.cartItems = data.Data;
        if (this.cartItems) {
            this.cartItemsCal = this.cartItems;

            this.TotalAmount = 0;
            var t = 0;
            for (let i = 0; i < this.cartItemsCal.length; i++) {
                t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;
                this.TotalPayableAmount = this.TotalAmount;
            }
            this.TotalAmount = t;
            //this.TotalPayableAmount = this.TotalAmount;
            this.TotalItem = 0;
            var sum = 0;
            //t = 0;
            if (this.cartItemsCal) {
                for (let i = 0; i < this.cartItemsCal.length; i++) {
                    //t += this.cartItemsCal[i].QTY;

                }
            }
            sum = this.cartItemsCal.length;

            this.TotalItem = sum;

            ///}

            // });


            console.log(this.TotalPayableAmount);
            this.TotalAmount;

        }

        this.LoadCategory();

    }

    DeliveryType: string = 'Home Delivery';
    modelChanged(Value, type: string) {

        //this.CustomerAddres = Value;

        if (type === 'HomePick') {
            this.CustomerAddres = Value;
            this.DeliveryType = 'Home Delivery';

        }
        else if (type == 'SelfPick') {
            this.CustomerAddres = Value;
            this.DeliveryType = 'Self PickUp';

        }
    }

    watchers: any;

    GotoCheckoutProcess() {
        this.router.navigateByUrl('/Payment');
        window.scrollTo(0, 0)
    }
    private setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 12;
            });
        }
    }

    onAutocompleteSelected(result: PlaceResult) {
        console.log('onAutocompleteSelected: ', result);
    }

    onLocationSelected(location: Location) {
        console.log('onLocationSelected: ', location);
        this.latitude = location.latitude;
        this.longitude = location.longitude;
    }

    gotoHomePage() {
        this.router.navigateByUrl('/Home');
        window.scrollTo(0, 0)
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        debugger;

        this.latitude = 18.51957;
        this.longitude = 73.85535;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);


        //if ('geolocation' in navigator) {
        //  navigator.geolocation.getCurrentPosition((position) => {
        //    this.latitude = position.coords.latitude;
        //    this.longitude = position.coords.longitude;
        //    this.zoom = 8;
        //    this.getAddress(this.latitude, this.longitude);
        //  });
        //}
    }


    getAddress(latitude, longitude) {
        debugger;
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            console.log(results);
            console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                    this.addForm.controls.AreaName.setValue(this.address);
                } else {
                    window.alert('No results found');
                }
            }
            else {
                debugger;
               // window.alert('Geocoder failed due to: ' + status);
            }

        });

  }

  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }

    public quantityChanged(index, type, item, objectName) {

      debugger;

        var prod = new ProductInfoModal();

      prod.CustomerID = Number(localStorage.getItem('CustId'));
      prod.UserId = Number(localStorage.getItem('CustId'));

      //if (prod.UserId == 0) {
      //  this.LoginModalOpen();
      //  return;
      //}

        if (type == 'Increament') {
            this.cartItems[index].qty += 1;
        }
        else {
            this.cartItems[index].qty -= 1;
            prod.isDecreaseQty = true;
        }
        prod.productName = item.productName;
        prod.productPrice = item.productPrice;
        prod.batch_id = item.batch_id;
        prod.qty = this.cartItems[index].qty;
        prod.batchId = item.batch_id;
        prod.itemId = item.item_Id;

        this.cartItems.forEach((element, index) => {
            if (element.item_Id == prod.itemId) {
                element.qty = prod.qty;
                if (prod.qty == 0) {
                    prod.isDecreaseQty = true;
                    const index = this.cartItems.findIndex(list => list.item_Id == prod.itemId);//Find the index of stored id
                    this.cartItems.splice(index, 1)
                }
            }

        });

        if (prod.CustomerID > 0) {


            var PreCartData = prod;
            PreCartData.qty = 1;
            this.apiIntergnService.AddPreSingleCart(PreCartData)
                .subscribe(Val => {
                    if (!Val.isError) {

                    }
                });
        }

        this.cartItemsCal = this.cartItems;
        //this.LocalStrogedata = this.cartItemsCal;
        this.TotalAmount = 0;
        var t = 0;
        for (let i = 0; i < this.cartItemsCal.length; i++) {
            t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

        }
        var sum = 0;
        this.TotalAmount = t;
        this.TotalItem = 0;
        t = 0;
        if (this.cartItemsCal) {
            for (let i = 0; i < this.cartItemsCal.length; i++) {
                t += this.cartItemsCal[i].qty;
                //t += length++;
            }
        }
        sum = this.cartItemsCal.length;
        this.TotalItem = sum;

        var obj = {
            Index: index, Type: type, Item: item, ObjectName: objectName
        };
        // this.homePageChanged = true;
        //this.apiIntergnService.QtyChangeEvent(obj);
        this.cartItemslength = this.cartItems.length;

    }


    markerDragEnd($event: any) {
        console.log($event);
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
    }


    OpenLocationMap() {
        this.ResetFinalPassword.show();
    }

    cartItemslength: number = 0;
    clearCartItem(item, index) {


        this.cartItems.splice(index, 1)

        localStorage.setItem('Cart', (JSON.stringify(this.cartItems)));
        this.cartItemslength = this.cartItems.length;
        this.TotalItem = this.cartItems.length;
        var t = 0;
        for (let i = 0; i < this.cartItemsCal.length; i++) {
            t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

        }
        var sum = 0;
        this.TotalAmount = t;
        this.appComp.TotalItem = this.cartItemslength;
        //this.ngOnInit();

      var prod = new ProductInfoModal();
      if (item.imagePath == undefined) {
        item.imagePath = item.path;
      }
        prod.itemId = item.item_Id;
        prod.productName = item.productName;
        prod.qty = item.qty;
        prod.isDecreaseQty = true
        prod.productPrice = item.item_SellingPrice;
        prod.batch_id = item.batch_id;
        prod.batchId = item.batch_id;
        prod.weight = item.weight;
      prod.unit = item.unit_Name;
      prod.uomId = item.uomId;
      prod.imagePath = item.imagePath;

        prod.CustomerID = Number(localStorage.getItem('CustId'));
        prod.UserId = Number(localStorage.getItem('CustId'));
        if (prod.UserId > 0) {

            var PreCartData = prod;
            console.log(PreCartData);
            this.apiIntergnService.AddPreSingleCart(PreCartData)
                .subscribe(Val => {

                    if (!Val.isError) {

                    }
                });
        }

    };




    Pincode() {

        if (this.addForm.controls.Customershipping_Pincode.errors == null) {
            this.apiIntergnService.GetDataThroughPincode(this.addForm.controls.Customershipping_Pincode.value).subscribe(response => {
                if (response) {
                    if (!response.isError) {
                        this.addForm.controls.Customershipping_State.setValue(response.data.stateName);
                    }
                    else {
                        this.addForm.controls.Customershipping_State.setValue("");
                    }
                }
            });
        }
        else {
            this.addForm.controls.Customershipping_State.setValue("");
        }
        //if(this.addForm.value.Customershipping_Pincode);
    }
    AddressLength: number = 0;
    CateData: any;
    public LoadCategory() {
        var ids = localStorage.getItem('CustId');
        this.apiIntergnService.getAllCustomerAddress(ids).subscribe((data) => {
            if (!data.isError) {
                this.CateData = data.data;
                this.AddressLength = this.CateData.length;
                console.log(this.CateData);
            }
        });
    }

    UpDown(listType) {


        switch (listType) {


            case 'DeliveryAddress':
                {
                    this.showThirdList = false;


                    if (this.CustomerPhoneNo != "") {
                        if (this.showSecondList) {
                            this.showSecondList = false;
                        }
                        else {
                            this.showFirstList = false;
                            this.showSecondList = true;
                            window.scrollTo(0, 0)
                        }
                        break;
                    }
                    else {
                        this.showSecondList = false;
                        this.showFirstList = true;
                    }

                }
            //case 'Number':
            //  {
            //    this.showSecondList = false;

            //    if (this.showFirstList) {
            //      this.showFirstList = false;

            //    }
            //    else {
            //      this.showFirstList = true;
            //      window.scrollTo(0, 0)
            //    }
            //    break;
            //  }


            //case 'brand': {
            //  this.showhideprice = false;
            //  this.showhideCate = false;
            //  this.showhideimpfruits = false;
            //  if (this.showhidebrand) {
            //    this.showhidebrand = false;
            //  }
            //  else {
            //    this.showhidebrand = true;
            //    window.scrollTo(0, 0)
            //  }
            //  break;
            //}
            //case 'impfruits': {
            //  this.showhideprice = false;
            //  this.showhidebrand = false;
            //  this.showhideCate = false;
            //  if (this.showhideimpfruits) {
            //    this.showhideimpfruits = false;
            //  }
            //  else {
            //    this.showhideimpfruits = true;
            //    window.scrollTo(0, 0)
            //  }
            //  break;
            //}
        }

    }




    currentLat: number = 0;
    currentLong: number = 0;
    findMe() {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.showPosition(position);
            });
        }
        else {
            alert("Geolocation is not supported by this browser.");
        }
    }
    map: any;
    marker: any;

    showPosition(position) {

        this.currentLat = position.coords.latitude;
        this.currentLong = position.coords.longitude;

        //let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        //console.log(location);

        //let geocoder = new google.maps.Geocoder;
        //let latlng = new google.maps.LatLng(this.currentLat, this.currentLong);
        //let request = { latLng: latlng };


        //geocoder.geocode({ 'location': latlng }, (results, status) => {
        //  console.log(google.maps.GeocoderStatus);
        //  if (status == google.maps.GeocoderStatus.OK) {
        //    let result = results[0];
        //    let rsltAdrComponent = result.address_components;
        //    let resultLength = rsltAdrComponent.length;
        //    if (result != null) {

        //    } else {
        //      alert("No address available!");
        //    }
        //  }

        //})


    }
    getTotalAmounts() {

        this.GetAddToCardDetails((data) => {
            this.cartItems = data;
            this.cartItemsCal = this.cartItems;

            this.TotalAmount = 0;
            var t = 0;
            for (let i = 0; i < this.cartItemsCal.length; i++) {
                t += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

            }
            this.TotalAmount = t;
            this.TotalItem = 0;
            t = 0;
            if (this.cartItemsCal) {
                for (let i = 0; i < this.cartItemsCal.length; i++) {
                    t += this.cartItemsCal[i].qty;
                }
            }
            this.TotalItem = t;
        });

        console.log(this.TotalAmount);
        return this.TotalAmount;

    }

    GetAddToCardDetails(callback) {

        if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

            var id = Number(localStorage.getItem('CustId'));
            this.apiIntergnService.AddCartDetails(id)
                .subscribe(data => {
                    if (data) {
                        if (callback) {

                            callback(data.data);

                        }


                    }

                    this.cartItems = data.data;


                }
                );
        }
        else {
            alert("You are logout please login again to continue.");
            this.router.navigateByUrl('/Home');
            window.scrollTo(0, 0)
        }
  }
  CustomerShipping_Id: any;
  TimeSpan: any;
  TimeSpanDate: any;



  ShippingAddressDetails() {
    debugger;
    this.CustomerShipping_Id = localStorage.getItem("ShippingId");
    this.TimeSpan = localStorage.getItem("TimeSpan");
    this.TimeSpanDate = localStorage.getItem("TimeSpanDate");

  }

    CustomerAddress() {


        var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
        this.CustomerInfo = JSON.parse(CustomerDetails);
        this.CustomerName = this.CustomerInfo.data.Customer_Name;
        this.CustomerEmailId = this.CustomerInfo.data.Customer_EmailID;
        //this.CustomerAddres = this.CustomerInfo.Data.Customer_EmailID;
        this.CustomerlandMark = this.CustomerInfo.data.Customer_Landmark;
        //this.CustomerInfo.Data.Customer_Mobile = "";
        this.CustomerPhoneNo = this.CustomerInfo.data.Customer_Mobile;

        if (this.CustomerPhoneNo != "") {
            this.showFirstList = false;
            this.showSecondList = true;
        }
        else {
            this.showFirstList = true;
            this.showSecondList = false;
        }

        //return this.Address;
    }
    CustomerId: number = 0;

    PhonNumberVerification() {

        if (this.CustomerPhoneNo == "") {
            alert("Please Enter Mobile Number");
        }
        else {
            var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));

            //var CustomerDetails = (localStorage.setItem('CustomerAllDetails'));
            this.CustomerInfo = JSON.parse(CustomerDetails);
            this.CustomerId = this.CustomerInfo.data.customer_ID;
            this.addFormNumber.value.Customer_ID = this.CustomerId;
            this.addFormNumber.value.Customer_Mobile = this.CustomerPhoneNo;

            this.apiIntergnService.UpdateCustomerNumber(this.addFormNumber.value).subscribe(response => {
                if (response) {
                    if (!response.IsError) {
                        var CustomerDetails = (localStorage.getItem('CustomerAllDetails'));
                        this.CustomerInfo = JSON.parse(CustomerDetails);
                        this.CustomerInfo.data.customer_Mobile = this.CustomerPhoneNo;
                        localStorage.setItem('CustomerAllDetails', (JSON.stringify(this.CustomerInfo)));

                        this.AddressForm.hide();
                        this.ngOnInit();
                        this.addForm.reset();
                        this.oTPsubmitted = false;
                        this.showFirstList = false;
                        this.showSecondList = true;
                    }
                    else {
                        var msg = "";
                        response.Message.forEach(value => {
                            msg += value + "\n";
                        });
                        alert(msg.toUpperCase());
                        this.oTPsubmitted = false;

                    }

                }

                else {


                }

            });





        }

    }


    GetDataThroughPincode(Customer_Pincode: number) {

        this.apiIntergnService.GetDataThroughPincode(Customer_Pincode).subscribe(data => {
            console.log(data);
            var PinCodeInfo = JSON.parse(JSON.stringify(data));
            this.CustomerCity = PinCodeInfo.data.taluka
            this.CustomerState = PinCodeInfo.data.stateName


        })
    }


    OpenSecondDiv() {

        if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
            this.NoItemFoundError = "Your cart is empty";
            return
        }
        if (this.selectedAddressId != 0) {
            if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

                this.showThirdList = true;
                this.showSecondList = false;
                this.showForthList = false;
                this.showFifthList = false;
                window.scrollTo(0, 0)
            }
            else {
                alert("You are logout please login again to continue.");
                this.router.navigateByUrl('/Home');
                window.scrollTo(0, 0)
            }
        }

        else {
            this.NoSelectedAddresError = "please add address or select the address."
            //alert("please add address or select the address.");
        }





    }
    rzp1;
    //set query(value) {
    //  this._query = value;
    //  console.log('query set to :', value)
    //}

    //get query() {
    //  return this._query;
    //}



    GetPaymentStatus(PaymentID, PaymentGateway_Id)
    {
        debugger;
       
            var rsobj =
            {
                PaymentId: PaymentID,
                PaymentGatwayId: this.PaymentGatewayId,
            }
            //console.log(rsobj);
            this.Loader.config.backdrop = "static"
            this.Loader.config.keyboard = false;
            this.Loader.show();

            this.apiIntergnService.CheckPaymentStatus(rsobj).subscribe(data1 => {
                console.log(data1);
                if (data1.data.paymentGateway_PaymentStatus == "authorized" || data1.data.paymentGateway_PaymentStatus == "captured") {
                    this.ForWordToCheckOut();
                }
                else if (data1.data.paymentGateway_PaymentStatus == "created") {

                    this.ForWordToCheckOut('Pending');
                }
                else {
                    alert("Payment Failed Please Try Again.")
                }


            });
        
    }

    PaymentGatewayId: number = 0;

    //isPayOnlineUp() {
    //    debugger;

    //    if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
    //        this.NoItemFoundError = "Your cart is empty";
    //        return
            
    //    }

    //    this.PayOnlineM = "Online Payment";
    //    this.PaymentTypeName = this.PayOnlineM;
    //    var Id = localStorage.getItem('CustId');
    //    this.TotalAmount
    //    var obj =
    //    {
    //        CutomerId: Id,
    //        Amount: this.TotalAmount
    //    }

    //    this.apiIntergnService.CreateOrder(obj)
    //        .subscribe(data => {

    //            var LCustData = localStorage.getItem('CustomerAllDetails');
                
    //            var CustData = JSON.parse(LCustData);

    //            if (data != null && !data.IsError) {
                   
    //                this.PaymentGatewayId = data.Data.PaymentGateway_Id;
    //                var options = {
    //                    description: 'Pay your amount',
    //                    order_id: data.Data.PaymentGateway_OrderId,
                        
    //                    currency: 'INR',
    //                    modal: {
    //                        escape: false,
    //                        ondismiss: function () {
    //                            //function method() {
    //                            // @ts-ignore
    //                            window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction("cancel"); });
    //                            //}
    //                        }
                            
    //                    },
    //                    receipt: data.Data.PaymentGateway_ReffNo,
    //                    key: 'rzp_test_Q8cEU4dvWnK3VI',
    //                    amount: data.Data.PaymentGateway_PaisaAmount,
    //                    name: 'Mahavir Ghar Sansar Payment',
    //                    prefill: {
    //                        email: CustData.Data.Customer_EmailID,
    //                        contact: CustData.Data.Customer_Mobile,
                            
    //                    },


    //                    handler: function (response) {
                           
    //                        if (response != undefined && response != null && response.razorpay_payment_id != undefined && response.razorpay_payment_id != null) {
    //                            this.id = response.razorpay_payment_id;
    //                            this.fullName = response.razorpay_payment_id;
                               

    //                            function method() {
    //                                // @ts-ignore
    //                                window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(response.razorpay_payment_id); });
    //                            }
    //                            method();
    //                        }
    //                    },

    //                    customer: {
                            
    //                        email: CustData.Data.Customer_EmailID,
    //                        contact: CustData.Data.Customer_Mobile,
                            
    //                    },
    //                    theme: { color: '#F37254' }
    //                }

    //                let rzp = new this.winRef.nativeWindow.Razorpay(options);
    //                rzp.open();


    //            }
    //        });
    //}

    isAllDeatilsSelected(addresdetails) {
        this.CustomerAddres5 = addresdetails.Customershipping_Address1 + " " + addresdetails.Customershipping_Address2;
        this.NoSelectedAddresError = "";
    }


    data: any;
    ForWordToCheckOut(PaymentStatus = 'Approved')
    {
      debugger;
      this.StorageData = localStorage.getItem('Cart');
      this.cartItems = JSON.parse(this.StorageData);
      this.cartItemLength = this.cartItems.length;
   

        if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
            this.NoItemFoundError = "Your cart is empty";
            return
            //alert("You have no selected  item ")
        }
        if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {
            this.showThirdList = true;
            this.showSecondList = false;
            this.showForthList = false;
            this.showFifthList = true;

          //  this.ConfirmationPage.hide();
          var CartBuyData = this.cartItems;
          this.TotalQty = 0;
          this.TotalAmount = 0;

          CartBuyData.forEach((e, index) => {
            this.TotalQty  += e.qty;
            this.TotalAmount += e.productPrice * e.qty;
          });

          var id = localStorage.getItem('CustId');
          var data = {
            "Cart": {
              "CartId": 0,
              "ApprovalStatus": "",
              "TotalItemPrice": this.TotalAmount,
              "Size": "",
              "ModifiedDate": new Date,
              "IsDeleted": false,
              "TotalQTY": this.TotalQty,
              "DeliveryType": 'Scheduled Delivery',
              "PaymentType": 'Cash On Delivery',
              "TimeSpan": "", //this.TimeSpan,
              "OrderNo":"",
              "CreatedDate": new Date(),
              "PaymentStatus": PaymentStatus,
              "ModifiedBy": id ,
              "UserId": id,
              "DeliveryCharges": 0.0,
              "Address": "",
              "TimeSpanDate": "",//this.TimeSpanDate,
              "ShippingAddressId": this.CustomerShipping_Id,
              "EmployeeId": 0,
              "OrderTrackId": "",
              "WalletAmt": 0.0,
              "Promo_Id": 0,
              "OfferAmmount": 0.0,
              "PickerId": 0,
              "PickerStatus": "",
              "Branch_Id": 1,
              "TotalPriceComp": 0.0,
              "TotalPricePartial": 0.0,
              "TotalQtyComp": 0,
              "TotalQtyPartial": 0,
              "CustomerBranchId": 1,
              "POId": 0
            },
            "CartDtlslst": []
              
          }
         
          CartBuyData.forEach((CardDetailsInfo, index) => {
            CardDetailsInfo.ProductName = CardDetailsInfo.productName,
              CardDetailsInfo.CartDetailsId = 0,
              CardDetailsInfo.CartId = 0
              CardDetailsInfo.ProductStatus = "Pending"
              CardDetailsInfo.ProductPrice = CardDetailsInfo.productPrice
              CardDetailsInfo.AddedInCartDate = new Date;
              CardDetailsInfo.ModifiedDate = new Date;
              CardDetailsInfo.ModifiedBy = CardDetailsInfo.modifiedBy;
              CardDetailsInfo.CreatedDate = new Date;
              CardDetailsInfo.ItemId = CardDetailsInfo.item_Id;
              CardDetailsInfo.QTY = CardDetailsInfo.qty;
              CardDetailsInfo.Size = CardDetailsInfo.size;
              CardDetailsInfo.SizeParentId = CardDetailsInfo.sizeParentId;
              CardDetailsInfo.SizeDetailsId = CardDetailsInfo.sizeDetailsId;
              CardDetailsInfo.Image = null;
              CardDetailsInfo.BatchId = CardDetailsInfo.batch_id;
              CardDetailsInfo.Promo_ID = 0,
              CardDetailsInfo.Ischecked = true,
              CardDetailsInfo.ConversionId = 0,
              CardDetailsInfo.PickerStatus= "",
                CardDetailsInfo.TotalPrice = CardDetailsInfo.QTY * CardDetailsInfo.ProductPrice,
              CardDetailsInfo.UomId = CardDetailsInfo.uomId
            CardDetailsInfo.OfferAmmount = 0;
            CardDetailsInfo.DeliveryCharges =0;
            CardDetailsInfo.DeliveryType = this.DeliveryType;
            CardDetailsInfo.EmployeeId=0;
            CardDetailsInfo.PaymentStatus = PaymentStatus;
            CardDetailsInfo.PaymentType = this.PaymentTypeName;
            CardDetailsInfo.Promo_ID =0;
            CardDetailsInfo.ShippingAddressId = this.selectedAddressId;
            CardDetailsInfo.TimeSpanDate = new Date;
            }
            );

          data.CartDtlslst = CartBuyData;

          var dataDT = JSON.stringify(data);
          console.log(dataDT);
            this.apiIntergnService.AddCartBuyDetails(data)
              .subscribe(data => {
                debugger;
                  if (data.isError != true) {

                        this.showThirdList = false;
                        this.showForthList = true;

                    this.PickUpPointName = this.DeliveryType;
                    this.Address = this.CustomerAddres5;
                        this.Time = new Date();
                    this.OrderNo = data.data.orderNo;
                    this.PayableAmount = this.TotalAmount + this.DeliveryCharge;
                        this.ProductDetailsSummary(data.data.cartId)
                        //this.MyOrderStatus.show();
                        this.cartItems = null;
                        this.TotalItem = null;
                        this.TotalAmount = null;
                        //this.ngOnInit();
                        localStorage.removeItem('Cart');

                        this.appComp.ClearTotalAmountAndCount();
                        this.appComp.ngOnInit();
                        //this.router.navigate(['./Home']);
                        //this.appComp.ngOnInit();
                        //window.scrollTo(0, 0)
                        this.PaymentGatewayId = 0;

                    }
                    else {

                        alert("Something went wrong please try again!!")
                        this.showThirdList = true;
                        this.showForthList = false;
                        window.scrollTo(0, 0)
                    }

                });
            if (this.Loader.isShown) {
                this.Loader.hide();
            }
        }
        else {
            alert("You are logout please login again to continue.");
            this.router.navigateByUrl('/Home');

        }

    }


    BackToHome() {
        this.router.navigate(['./Home']);
        window.scrollTo(0, 0)
        this.appComp.ngOnInit();

    }
    public ProductDetailsSummary(id: number) {


        this.apiIntergnService.GetMyProductDetails(id)
            .subscribe(data => {
                if (data) {

                    this.ProductlistData = data.data.orderDetails;

                    //for (var i = 0; i < this.ProductlistData.length; i++)
                    //{
                    //  if (this.ProductlistData[i].Image) {
                    //    this.ProductlistData[i].Image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
                    //      + this.ProductlistData[i].Image);
                    //  }
                    //  else {
                    //    this.ProductlistData[i].Image = "../../assets/images/img7_4.jpg";
                    //  }
                    //}

                }
            }
            );

    }


    getSumOfData(): number {

        this.cartItemsCal = this.cartItems;
        let TotalAmount = 0;
        for (let i = 0; i < this.cartItemsCal.length; i++) {
            TotalAmount += this.cartItemsCal[i].productPrice * this.cartItemsCal[i].qty;

        }
        // localStorage.setItem('CustDetails', TotalAmount.toString());
        //this.TotalCartValue = TotalAmount;
        this.TotalItem = 0;
        var t;
        t = 0;
        if (this.cartItemsCal) {
            for (let i = 0; i < this.cartItemsCal.length; i++) {
                t += this.cartItemsCal[i].qty;
            }
        }

        this.TotalItem = t;
        this.TotalAmount = TotalAmount;
        return TotalAmount;

    }

    public PaymentPageModal() {

        //this.getTotalAmounts();
        this.getSumOfData();
        this.PaymentForm.show();

  }

  isPayOnlineUp() {}
    //PayOnlineM: any;
  PayOnlineM: any;// = "Cash On Delivery";
    public CardOnDelivery(paymentType) {
        debugger;
        this.PayOnlineM = paymentType;
        if (this.cartItems == null || this.cartItems == [] || this.cartItems.length <= 0 || this.cartItemLength == 0) {
            this.NoItemFoundError = "Your cart is empty";
            return;
            
        }
        if (this.PayOnlineM == "Cash On Delivery") {
            
            if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

                this.ConfirmationPage.show();
                this.PaymentTypeName = this.PayOnlineM;
            }
            else {
                alert("You are logout please login again to continue.");
                this.router.navigateByUrl('/Home');
            }
        }
        else if (this.PayOnlineM == "UPI Payment")
        {
          if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

            this.ConfirmationPage.show();
            this.PaymentTypeName = this.PayOnlineM;
          }
          else
          {
            alert("You are logout please login again to continue.");
            this.router.navigateByUrl('/Home');
          }
        }
        else if (this.PayOnlineM == "Card On Delivery") {
          if (localStorage.getItem('CustomerAllDetails') != null && localStorage.getItem('CustId') != null) {

            this.ConfirmationPage.show();
            this.PaymentTypeName = this.PayOnlineM;
          }
          else {
            alert("You are logout please login again to continue.");
            this.router.navigateByUrl('/Home');
          }
        }
    }

    //addresswork


    BackToAccount() {
        this.AddressForm.show();
        this.ResetFinalPassword.hide();
    }

    BackToAccountpage() {
        this.ResetFinalPassword.hide();
    }
    SaveGoogleMap() {
        this.ResetFinalPassword.hide();
    }


    get o() { return this.addForm.controls; }
    SaveAddtress()
    {
        debugger;

        this.oTPsubmitted = true;
            var ids = localStorage.getItem('CustId');
            this.addForm.value.Mode = this.Mode;
            this.addForm.value.Customershipping_Id = this.CustomershippingCustomerId
            this.addForm.value.Customershipping_CustomerId = ids;
            this.addForm.value.Customershipping_Createdby = ids;
            this.addForm.value.Customershipping_Status = 'Active';
            var s = JSON.stringify(this.addForm.value);
            this.apiIntergnService.ChangeAddress(this.addForm.value).subscribe(response => {
                if (response) {
                    if (!response.isError) {
                        this.AddressForm.hide();
                        // this.ResetFinalPassword.show();
                        this.ngOnInit();
                        // alert("Address Changed Successfully!!")
                        this.addForm.reset();
                        this.oTPsubmitted = false;
                    }
                    else {
                        var msg = "";
                        response.message.forEach(value => {
                            msg += value + "\n";
                        });
                        alert(msg.toUpperCase());
                        this.oTPsubmitted = false;

                    }




                    //this.ResetFinalPassword.hide();
                }

                else {
                    //alert("Something went wrong please try again");

                }

            });
        

    }
    OpenAddressForm() {
        this.addForm.reset();
        this.AddressForm.show();
        this.oTPsubmitted = false;
        this.Mode = "Insert";
    }


    EditAddressForm(Item) {


        this.Mode = "Update";
        this.oTPsubmitted = false;
        this.CustomershippingCustomerId = Item.customershipping_Id;
        let custShipplinginfo: CustomershippingInfoModel = new CustomershippingInfoModel();
        custShipplinginfo.Customershipping_Id = Item.customershipping_Id;
      custShipplinginfo.Customershipping_CustomerId = Item.customershipping_CustomerId;
      var data = {
        "CustomerId": custShipplinginfo.Customershipping_CustomerId,
        "ShippingId": custShipplinginfo.Customershipping_Id
      }
        this.apiIntergnService.getCustomerShippingAddressById(data).subscribe(data => {
            if (data) {
                this.addForm.patchValue(data.data);
                this.AddressForm.show();
            }
            else {

            }
        })

    }


    DeleteAddress() {
        this.ConfirmationPageOfAddress.hide();
        var ids = localStorage.getItem('CustId');
        this.addForm.value.Mode = this.Mode;
        this.addForm.value.Customershipping_Id = this.CustomershippingCustomerId
        this.addForm.value.Customershipping_CustomerId = ids;


        this.apiIntergnService.ChangeAddress(this.addForm.value).subscribe(data => {
            if (data) {

                if (!data.isError) {
                    //this.AddressDeleteModel.show();

                    //alert("Address deleted successfully");
                    this.ngOnInit();
                }
                else {
                    var msg = "";
                    data.Message.forEach(value => {
                        msg += value + "\n";
                    });
                    alert(msg.toUpperCase());


                }




            }
        });
    }


    DeleteConfirmationForm(Item) {
        this.ConfirmationPageOfAddress.show();
        this.Mode = "Delete";
        this.CustomershippingCustomerId = Item.customershipping_Id;
    }

    GotoMap() {

        this.ResetFinalPassword.show();
    }


    public keyDownFunction(event) {

        if (event.keyCode == 40) {
            event.preventDefault()
            const inputs =
                Array.prototype.slice.call(document.querySelectorAll("input,select"))
            const index =
                (inputs.indexOf(document.activeElement) + 1) % inputs.length
            const input = inputs[index]
            input.focus()
            input.select()
        }
        else if (event.keyCode == 38) {
            event.preventDefault()
            const inputs =
                Array.prototype.slice.call(document.querySelectorAll("input,select"))
            const index =
                (inputs.indexOf(document.activeElement) - 1) % inputs.length
            const input = inputs[index]
            input.focus()
            input.select()
        }
        else if (event.keyCode == 13) {
            event.preventDefault()
            const inputs =
                Array.prototype.slice.call(document.querySelectorAll("input,select"))
            const index =
                (inputs.indexOf(document.activeElement) + 1) % inputs.length
            const input = inputs[index]
            input.focus()
            input.select()
        }

    }
}
