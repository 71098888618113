import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'angular-6-social-login';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute, private socialAuthService: AuthService, private apiIntergnService: ApiIntergnService, private appComp: AppComponent) { }

    ngOnInit()
    {
        this.appComp.OpenAboutUsPageforCategory();
    }

}
