import { Component, OnInit } from '@angular/core';
import { ApiIntergnService } from '../AppServices/api-intergn.service';
import { FavouriteModel } from '../Models/favourite-model';
import { AppComponent } from '../app.component';
import { ProductInfoModal } from '../Models/product-info-modal';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  @ViewChild('LoginForm', { static: false }) LoginForm: ModalDirective;
  workout = null;
  CustomerId: number;
  FavProductData: any;
  p: number = 1;
  WishListPage: boolean = true;
  myOptions = {
    'placement': 'right',
    'show-delay': 500,
    'theme': 'light'

  }
  myOptions1 = {
    'placement': 'left',
    'show-delay': 500,
    'theme': 'light'

  }
  constructor(private router: Router,private apiIntergnService: ApiIntergnService, private appComp: AppComponent) { }

  ngOnInit()
  {
    this.CustomerId = Number(localStorage.getItem('CustId'));
    this.appComp.filter = "";
    this.WishListPage = this.appComp.RegisterForm;
    if (this.apiIntergnService.subsRefreshCartVar == undefined) {
      this.apiIntergnService.invokeRefreshEnvet.subscribe((Obj: any) => {

        this.appComp.changed = true;
        this.ngOnInit();

      });
    }
    if (this.apiIntergnService.subsVar == undefined) {

      this.apiIntergnService.invokeQtyChange.subscribe((Obj: any) => {
        if ((Obj != null && !this.appComp.changed) || (Obj != null && this.appComp.homePageChanged)) {

          debugger;
          this.appComp.homePageChanged = false;
          if (this.FavProductData != undefined) {
            var ProductDataIndex = this.FavProductData.findIndex(x => x.item_Id == Obj.Item.item_Id);
          }
          //var TopPopularIndex = this.TopOffersData.findIndex(x => x.Item_Id == Obj.Item.ItemId);
          let item: any = {};

          item.item_Id = Obj.Item.item_Id;
          item.productName = Obj.Item.productName;
          item.item_SellingPrice = Obj.Item.productPrice;
          item.batch_id = Obj.Item.batch_id;
          item.batchId = Obj.Item.batch_id;
          item.item_MRP = Obj.Item.item_MRP;
          item.weight = Obj.Item.weight;
          item.unit_Name = Obj.Item.unit_Name;
          item.offerAmt = Obj.Item.offerAmt;
          item.imagePath = Obj.Item.imagePath;
          item.uomId = Obj.Item.uomId;


          if (ProductDataIndex != null && ProductDataIndex != -1) {
            this.quantityChanged(ProductDataIndex, Obj.Type, item, 'Products');
            return;
          }
          if (ProductDataIndex == -1 && Obj.Item.qty == 0) {
            this.result3 = JSON.parse(localStorage.getItem('Cart'));
            this.result3.forEach((element, index) => {
              if (element.item_Id == item.item_Id && (element.batchId || element.batch_id) == item.batch_id) {
                Obj.Item.qty = element.qty;//+ 1;
              }
            });
            if (Obj.Item.qty = 0)
              this.quantityChanged(0, Obj.Type, item, 'Delete');
            
            return;
          }

          if (this.appComp.cartItems[Obj.Index].qty == 0) {
            this.appComp.cartItems.splice(Obj.Index, 1);
          }
          //this.quantityChanged(Obj.Index, Obj.Type, Obj.Item, Obj.ObjectName);
        }
        else {
          this.appComp.changed = false;
        }
      });
    }

    if (this.apiIntergnService.subsVarCart == undefined) {
      this.apiIntergnService.invokeCartItemDeleteEvent.subscribe((Obj: any) => {
        debugger;
        if (this.FavProductData != undefined) {
          var ProductDataIndex = this.FavProductData.findIndex(x => x.item_Id == Obj.item_Id);


        }


        if (ProductDataIndex != null && ProductDataIndex != -1) {
          this.FavProductData[ProductDataIndex].qty = 0;
        }
        
        this.StorageData = localStorage.getItem('Cart');

        this.result3 = JSON.parse(this.StorageData);
      });
    }
    this.StorageData = localStorage.getItem('Cart');

    this.result3 = JSON.parse(this.StorageData);

    this.GetAllFavtProducts();
  }
  StorageData: string;
  result99 = [];
  pageChanged(event) {
    this.p = event;

    window.scrollTo(0, 0);


  }

  LoginModalOpen() {
    // this.LoginForm.hide();
    alert("Please Login To Continue With Brahmand Mandai");
  }
  quantityChanged(index, type, item, objectName) {
    debugger;
    
      this.appComp.changed = true;
      var qty = 0;
      this.StorageData = localStorage.getItem('Cart');
      if (this.StorageData != null)
      {
        this.result99 = JSON.parse(this.StorageData);
    }
    if (this.FavProductData != undefined) {

      var ProductDataIndex = this.FavProductData.findIndex(x => x.item_Id == item.item_Id);
    }
      
      var prod = new ProductInfoModal();
    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));

    //if (prod.UserId == 0) {
    //  this.LoginModalOpen();
    //  return;
    //}
      //this.UserId = Number(localStorage.getItem('CustId'));
      if (objectName == 'Products') {


        if (type == 'Increament') {
          this.FavProductData[ProductDataIndex].qty += 1;
        }
        else {
          this.FavProductData[ProductDataIndex].qty -= 1;
          prod.isDecreaseQty = true;
        }
        qty = this.FavProductData[ProductDataIndex].qty
        



      }
      
      prod.itemId = item.item_Id;
      prod.productName = item.productName;

      prod.productPrice = item.item_SellingPrice;
      prod.batch_id = item.batch_id;
      prod.batchId = item.batch_id;
      prod.weight = item.weight;
      prod.unit = item.unit_Name;
    prod.qty = qty;
    prod.uomId = item.uomId;
    prod.imagePath = item.imagePath;

      this.result99.forEach((element, _index) => {
        if (element.item_Id == prod.itemId) {
          element.qty = prod.qty;
          if (prod.qty <= 0) {
            const index = this.result99.findIndex(list => list.item_Id == prod.itemId);//Find the index of stored id
            //debugger;
            this.result99.splice(index, 1)
            //const indexcart = this.appComp.cartItems.findIndex(list => list.ItemId == prod.ItemId);
            //this.appComp.cartItems.splice(indexcart, 1);
            //this.result99 = this.appComp.cartItems;
          }
        }

      });
      this.appComp.cartItems = this.result99;

      if (prod.UserId > 0) {
        debugger;
        var PreCartData = prod;
        PreCartData.qty = 1;
        console.log(PreCartData);
        this.apiIntergnService.AddPreSingleCart(PreCartData)
          .subscribe(Val => {
            debugger;
            if (!Val.isError) {
              this.result99.forEach((element, _index) => {
                if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                  element.qty = Val.data.qty;
                }
              });
              localStorage.setItem('Cart', (JSON.stringify(this.result99)));
            }
          });
      }
    localStorage.setItem('Cart', (JSON.stringify(this.result99)));
      this.StorageData = localStorage.getItem('Cart');

      this.appComp.TotalItem = this.result99.length;
    
  }
  AddFavourite(items) {
    debugger;
    if (this.CustomerId != 0) {
      items.isFavt = !items.isFavt;
      let favouriteModel = new FavouriteModel();
      favouriteModel.Favt_Id = 0;
      favouriteModel.Favt_customerId = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ItemId = items.item_Id;
      favouriteModel.Favt_CreateBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_ModififedBy = Number(localStorage.getItem('CustId'));
      favouriteModel.Favt_CreatedDate = new Date();
      favouriteModel.Favt_ModififedDate = new Date();
      favouriteModel.Favt_IsDelete = false;
      favouriteModel.Favt_BatchId = parseInt(items.batch_id);
      if (items.isFavt == true)
        favouriteModel.Mode = "Insert"
      if (items.isFavt == false)
        favouriteModel.Mode = "Delete"


      this.apiIntergnService.Addfavourite(favouriteModel).subscribe(Val => {
        debugger;
        if (!Val.isError) {
          this.ngOnInit();
        }
      });
    }
    else {
      alert("Please Login To Add Favourite");
    }

  }
  favouritedatalength: number;
  result3 = [];
  public GetAllFavtProducts() {
    debugger;
    var obj = {
      PriceLevelId: 0,
      CustomerId: this.CustomerId,
      CateId: 0,
    }
    this.apiIntergnService.GetAllFavtProducts(obj).subscribe((data) => {
      if (!data.isError) {
        this.FavProductData = data.data;
        
        this.favouritedatalength = this.FavProductData.length;
        this.FavProductData.forEach(function (element) {
          element.qty = 0;
          element.batchDetails = JSON.parse(element.batchDetails);
          element.batchDetails.forEach(function (batch) {
            batch.Batch_qty = 0;
          })
          window.scrollTo(0, 0)
        });
        
        for (var i = 0; i < this.FavProductData.length; i++) {
          for (var k = 0; k < this.result3.length; k++) {
            if (this.FavProductData[i].item_Id == this.result3[k].item_Id) {
              this.FavProductData[i].qty = this.result3[k].qty;
            }
          }

        }
      }
    });
    
  }
  workouts = [

    //{
    //  name: "Relevance",
    //  id: 1
    //},
    {
      name: "Price (Low to High)",
      id: 2
    },
    {
      name: "Price (High to Low)"
    },
    {
      name: "Discount (High to Low)"
    },
    {
      name: "Name(A to Z)"
    }




  ]
  OpenSingleForm(ItemID) {
    this.router.navigate(['./single/' + ItemID]);
  }
  OpenTopSaver(item, index, objectName) {
    debugger;
    this.CustomerId = Number(localStorage.getItem('CustId'));
    var prod = new ProductInfoModal();
    this.StorageData = localStorage.getItem('Cart');
    //if (this.CustomerId == 0) {
    //  this.LoginModalOpen();
    //  return;
    //}
    if (this.FavProductData != undefined) {

      var ProductDataIndex = this.FavProductData.findIndex(x => x.item_Id == item.item_Id);
    }
    //var TopPopularIndex = this.TopOffersData.findIndex(x => x.Item_Id == item.Item_Id);

    if (this.StorageData != null) {
      this.result99 = JSON.parse(this.StorageData);
    }
    if (ProductDataIndex != null && ProductDataIndex != -1) {
      this.FavProductData[ProductDataIndex].qty = 1;
    }

    if (item.imagePath == undefined) {
      item.imagePath = item.path;
    }

    prod.itemId = item.item_Id;
    prod.productName = item.productName;
    prod.productPrice = item.item_SellingPrice;
    prod.batch_id = item.batch_id;
    prod.batchId = item.batch_id;
    prod.imagePath = item.imagePath;
    prod.weight = item.weight;
    prod.unit = item.unit_Name;
    prod.uomId = item.uomId;

    var t = this.result99.find(list => list.item_Id == prod.itemId)
    console.log(t);


    if (t) {
      console.log(this.result3);
      this.result99.forEach((element, index) => {
        element.qty = prod.qty;
      })
    }
    else {
      this.result99.push(prod);
      console.log(this.result99);
      prod.CustomerID = Number(localStorage.getItem('CustId'));
      localStorage.setItem('Cart', (JSON.stringify(this.result99)));
    }
    this.appComp.TotalItem = this.result99.length;
    prod.CustomerID = Number(localStorage.getItem('CustId'));
    prod.UserId = Number(localStorage.getItem('CustId'));
    if (prod.UserId > 0) {
      debugger;
      var PreCartData = prod;
      PreCartData.qty = 1;
      this.apiIntergnService.AddPreSingleCart(PreCartData)
        .subscribe(Val => {
          debugger;
          if (!Val.isError) {
            this.result99.forEach((element, _index) => {
              if (element.item_Id == prod.itemId && element.batch_id == prod.batch_id) {
                element.qty = Val.data.qty;
              }
            });
            localStorage.setItem('Cart', (JSON.stringify(this.result99)));
          }
        });
    }
    
  }
  selectedshift: any;
  FilterListMethod(event: any) {
    debugger;

    this.selectedshift = event.name;
    if (this.selectedshift == "Name(A to Z)")
    {
      this.FavProductData.sort((a, b) => Number(a.productName > b.productName) * 2 - 1);
    }
    else if (this.selectedshift == "Price (Low to High)") {
      this.priceFilter();

    }
    else if (this.selectedshift == "Price (High to Low)") {

      this.priceHighToLowFilter();

    }

    else if (this.selectedshift == "Discount (High to Low)") {

      this.DiscountHighToLowFilter();

    }

  }
  priceFilter = () => {
    debugger;
    let ordered = this.FavProductData.sort((a, b) => (a.item_SellingPrice > b.item_SellingPrice) ? 1 : -1);

    this.FavProductData = ordered;
    this.favouritedatalength == this.FavProductData.length;

  }

  priceHighToLowFilter = () => {
    debugger;
    let ordered = this.FavProductData.sort((a, b) => (a.item_SellingPrice < b.item_SellingPrice) ? 1 : -1);
    this.FavProductData = ordered;
    this.favouritedatalength == this.FavProductData.length;
  }

  DiscountHighToLowFilter = () => {
    debugger;
    let ordered = this.FavProductData.sort((a, b) => (a.offerAmt < b.offerAmt) ? 1 : -1);
    this.FavProductData = ordered;
    this.favouritedatalength == this.FavProductData.length;
  }
}
