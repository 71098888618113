import { Injectable, EventEmitter } from '@angular/core';
import { UrlClass } from '../Models/url-class';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Cart } from '../Models/cart';
import { Customer } from '../Models/customer';
import { CartModel } from '../Models/cart-model';
import { ValidateInfo } from '../Models/validate-info';
import { CustomershippingInfoModel } from '../Models/customershipping-info-model';
import { ProductInfoModal } from '../Models/product-info-modal';
@Injectable({
  providedIn: 'root'
})
export class ApiIntergnService {
  CatName: string;
  topcategoryname: any;
  invokeCheckOutProductEvent = new EventEmitter();
  invokeLoaderEnvet = new EventEmitter();
  invokeRefreshEnvet = new EventEmitter();

  subsVar: Subscription;
  loaderVar: Subscription;
  LoaderEnvet(obj) {
    this.invokeLoaderEnvet.emit();
  }

  subsRefreshCartVar: Subscription;
  RefreshCartEnvet() {
    this.invokeRefreshEnvet.emit();
  }

  CheckoutProductEvent() {
    this.invokeCheckOutProductEvent.emit();
  }


  baseApiUrl = UrlClass.BASE_API_URL;
  ImgApiUrl = UrlClass.BASE_IMG_URL;
  constructor(private http: HttpClient) { }

 
  //------ ErrorHadler------

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.info(error); // log to console instead
      return of(result as T);
    };
  }

  invokeQtyChange = new EventEmitter();
  invokeCartItemDeleteEvent = new EventEmitter();
  subsVarCart: Subscription;

  QtyChangeEvent(obj) {
    this.invokeQtyChange.emit(obj);
    //return obj;
  }
  CartItemDeleteEvent(obj) {
    this.invokeCartItemDeleteEvent.emit(obj);
    //return obj;
  }
  //----------------GetAll Category-------------------------------------

    getAllCategories() //-----------------new
    {
      debugger;
      return this.http.get<any>(this.baseApiUrl + 'Product/GetAllCategory').pipe(
        catchError(this.handleError<any>('GetCategories'))
      );
    }

  GetCategoriesNavigation() { //-----------------new
        debugger;
    return this.http.get<any>(this.baseApiUrl + 'Product/GetAllCategory').pipe(
            catchError(this.handleError<any>('GetCategoriesNavigation'))
        );
    }


  getAllCustomerAddress(obj) //-----------------new
  {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'EcomCart/GetAllAddressByCustId/' + obj).pipe(
      catchError(this.handleError<any>('getAllCustomerAddress'))
    );

  }

  CreateOrder(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/CreateOrder', obj).pipe(
      catchError(this.handleError<any>('CreateOrder'))
    );

  }

  CheckPaymentStatus(obj) { //-----------------new
    debugger;

    return this.http.post<any>(this.baseApiUrl + 'Cart/CheckPaymentStatus', obj).pipe(
      catchError(this.handleError<any>('CheckPaymentStatus'))
    );

  }

  getCategoriesWithSubCat() { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'ProductPack/GetSubCategories').pipe(
      catchError(this.handleError<any>('GetSubCategories'))
    );
  }
  getSubCategories(Id) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'ProductPack/GetSubCategoryInfo' + Id).pipe(
      catchError(this.handleError<any>('GetSubCategoryInfo'))
    );
  }

  getAllBrand() { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'ProductPack/GetAllBrand').pipe(
      catchError(this.handleError<any>('GetAllBrand'))
    );
  }

  getBrandByCateId(Id) { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'ProductPack/GetBrandsByCatid/'+Id).pipe(
      catchError(this.handleError<any>('GetBrands'))
    );
  }

  GetManufactureByCatid(Id) { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Product/GetManufactureByCatid/' + Id).pipe(
      catchError(this.handleError<any>('GetManufactureByCatid'))
    );
  }

  GetManufacture() { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Product/GetManufacture').pipe(
      catchError(this.handleError<any>('GetManufactureByCatid'))
    );
  }

  GetManufactureMore() { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Product/GetManufactureMore').pipe(
      catchError(this.handleError<any>('GetManufactureMore'))
    );
  }
  getProductBrandId(Obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetProductsByBrandId' , Obj).pipe(
      catchError(this.handleError<any>('GetBrandProducts'))
    );
  } 
  GetProductByManufactureId(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetProductsByManufactureId', obj).pipe(
      catchError(this.handleError<any>('GetProductByManufactureId'))
    );
  }

  getTopServers(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetBestSavers',obj).pipe(
      catchError(this.handleError<any>('GetTopSavers'))
    );
  }
  Addfavourite(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/AddUpdateFavorite', obj).pipe(
      catchError(this.handleError<any>('Addfavourite'))
    );
  }

  getSimilarProduct(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetSimilarProducts', obj).pipe(
      catchError(this.handleError<any>('GetTopSimilar'))
    );
  }


  public DeleteCartItems(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'Product/ClearPreCartItem', obj).pipe(
      catchError(this.handleError<any>('DeleteCartItems'))
    );
  }


  GETMostPopularProducts(obj) {  //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetMostPopularProducts', obj).pipe(
      catchError(this.handleError<any>('GetTopPopularProduct'))
    );
  }

  getTopOffers(obj) {  //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetBestOffers', obj).pipe(
      catchError(this.handleError<any>('GetTopOffer'))
    );
  }

  //getProductByCatID(obj) { //-----------------new
  //  debugger;
  //  return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetProductsByCategoryId', obj).pipe(
  //    catchError(this.handleError<any>('GetProductsByCategory'))
  //  );
  //}


  //-------apni chakki api
  getProductByCatID(obj) {
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/GetProductWithLooseDetailsByCategoryId', obj).pipe(
      catchError(this.handleError<any>('GetProductsByCategory'))
    );
  }

  GetAllFavtProducts(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/GetfavoriateItem', obj).pipe(
      catchError(this.handleError<any>('GetAllFavtProducts'))
    );
  }
  GetMasterSearch(obj) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'ProductPack/PageI_GetProductsByCategoryId', obj).pipe(
      catchError(this.handleError<any>('GetProductsByCategory'))
    );
  }

  GetAllImagesByItem(Id) { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'LookUP/GetImagesByItemId/' + Id).pipe(
      catchError(this.handleError<any>('GetImagesByItem'))
    );
  }

  EcomOrderCancelByCartId(id) { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'EcomCart/OrderCancel/' + id).pipe(
      catchError(this.handleError<any>('EcomOrderCancelByCartId'))
    );
  }


  getProductByID(obj) {  //-----------------newdebugger;
    debugger;

    return this.http.post<any>(this.baseApiUrl + 'ProductPack/ItemDTByItemId', obj).pipe(
      catchError(this.handleError<any>('EcomGetProductsById'))
    );
  }

  AddCart(CartInfo: Cart) { //----------------------------pending
    debugger;
    return this.http.post<Cart>(this.baseApiUrl + 'EcomCart/AddPreCart', CartInfo).pipe(
      catchError(this.handleError<any>('AddCart'))
    );
  }

  AddPreSingleCart(data) {  //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/AddPreCart', data).pipe(
      catchError(this.handleError<any>('AddCart'))
    );
  }

  AddPreSingleCartmultiple(Data: any) {  //-----------------new
        debugger;
      return this.http.post<any>(this.baseApiUrl + 'EcomCart/AddPreCart', Data).pipe(
            catchError(this.handleError<any>('AddPreCart'))
        );
    } 
  AddPreCart(Data: any) {  //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/AddPreCart', Data).pipe(
      
        catchError(this.handleError<any>('AddPreCarts'))
    );
  }

  public CartDetails(UserId) {  //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'EcomCart/GetPrecartAllItem/' + UserId).pipe(
      catchError(this.handleError<any>('CartDetails'))
    );
  }


  public Login(data)  //-----------------new
  {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'Lookup/GetMobCustomerLogin', data).pipe(
      catchError(this.handleError<any>('Login'))
    );
  }


  public Registration(data)  //-----------------new
  {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'Account/Addcustomer', data).pipe(
      catchError(this.handleError<any>('Registration'))
    );
  }


    
  public AddCartDetails(ID) //-----------------new 
  {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Cart/GetPrecartAllItem/' + ID).pipe(
      catchError(this.handleError<any>('AddCartDetails'))
    );
  }


  public GetDataThroughPincode(Pincode) { //-----------------new
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'EcomCart/GetPincodeInfo/' + Pincode).pipe(
      catchError(this.handleError<any>('GetDataThroughPincode'))
    );
  }

  public AddCartBuyDetails(Data: any)  //-----------------new
  { //-----------------new
    debugger;
    return this.http.post<CartModel>(this.baseApiUrl + 'EcomCart/AddToCart', Data).pipe(
      catchError(this.handleError<any>('AddCartBuyDetails'))
    );
  }


  public GetMyProductDetails(CardId: number)  //-----------------new
  {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Cart/GetMyProductDetails/' + CardId).pipe(
      catchError(this.handleError<any>('GetMyProductDetails'))
    );
  }

  getCustomerShippingAddressById(data) {  //-----------------new
    debugger;
   
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/GetAddressByCustIdAndShipId/' , data).pipe(
      catchError(this.handleError<any>('getCustomerShippingAddressById'))
    );
  }
  public GetMyProducts(UserId: number)  //-----------------new
  {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Cart/GetCartDataByCustId/' + UserId).pipe(
      catchError(this.handleError<any>('GetMyProducts'))
    );
  }

  public ChangePassword(data) {//-----------------pending
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'Account/ChangePWD', data).pipe(
      catchError(this.handleError<any>(' ChangePassword'))
    );
  }


  public ChangeAddress(data: any) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'EcomCart/AddUpdateDeleteShippingAddress', data).pipe(
      catchError(this.handleError<any>(' ChangeAddress'))
    );
  }

  public UpdateCustomerNumber(data: any)//-------pending
  {
    debugger;
    return this.http.post<ValidateInfo>(this.baseApiUrl + 'Account/UpdateMobile', data).pipe(
      catchError(this.handleError<any>(' UpdateCustomerNumber'))
    );
  }

  public ResetPassword(CustomerMobileNo: number) { //-----------------new
    debugger;
    return this.http.get<Cart>(this.baseApiUrl + 'Masters/GenerateOTP/' + CustomerMobileNo).pipe(
      catchError(this.handleError<any>('ResetPassword'))
    );
  }



  public ValidateOTP(data) { //-----------------new
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'Masters/ValidateOTP', data).pipe(
      catchError(this.handleError<any>('ValidateOTP'))
    );
  }
}
