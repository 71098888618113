import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor() { }
  lat: any;
  lng: any;
  ngOnInit() {

    this.lat = 19.0760;
    this.lng = 72.8777;
    
  }
  
  


}
